import { Component, OnInit, Compiler } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { timer } from 'rxjs';
import { filter } from 'rxjs/operators';

declare var gtag;

@Component({
  selector: 'mdb-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css']
})

export class AppComponent implements OnInit {
  values: string[] = ['Tag 1', 'Tag 2', 'Tag 4'];
  specialPage: boolean;
  private specialPages: any[] = [
    '/pages/login',
    '/pages/register',
    '/pages/lock',
    '/pages/pricing',
    '/pages/single-post',
    '/pages/post-listing'
  ];

  private currentUrl = '';
  responce: any;
  subscribe: any;
  subscribevalue: boolean
  subscribea: any;
  routingSubscribe: any;
  isFunctionTrue: boolean;
  endtime: any;
  newendtime: number;
  expires_in: any;

  constructor(
    private router: Router,
    private location: Location
  ) {
    this.router.events.subscribe((route: any) => {
      this.currentUrl = route.url;
      this.specialPage = this.specialPages.indexOf(this.currentUrl) !== -1;
    });
    const navEndEvents$ = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      );
    navEndEvents$.subscribe((event: NavigationEnd) => {
      gtag('config', 'UA-144270525-1', {
        'page_path': event.urlAfterRedirects
      });
    });
  }

  ngOnInit(): void {
    this.expires_in = localStorage.getItem("expires_in");
    if (this.expires_in != null) {
      this.expires_in = this.expires_in * 1000 - 10000;
      this.subscribe = timer(2000, this.expires_in).subscribe(val => {
        let expires_in = localStorage.getItem("expires_in");
        if (expires_in != null) {
          // this.servicestoken()
        } else {
          this.subscribe.unsubscribe();
        }
      });
    }
  }
  goBack(): void {
    this.location.back();
  }
}
