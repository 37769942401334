import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MarathonService } from '../../services/marathon.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-day-plan-small',
  templateUrl: './day-plan-small.component.html',
  styleUrls: ['./day-plan-small.component.scss']
})
export class DayPlanSmallComponent implements OnInit {
  @Input() data;
  @Input() discription;
  @Input() language;
  @Input() index;
  public slideImage = 0;
  isShowDiscription: boolean;
  isShowComment: boolean;
  getComment: any;
  public enableViewMore = true;
  comment_reply_message: any;
  comment_reply_message_of_reply: any;
  show: boolean;
  isError: boolean;
  user_marathondayerror: any;
  comment_message: any;
  getCommentchild: any;
  commentData: {};
  rowData: any;
  role: string;
  FullName: string;
  splitFullName: string[];
  NameInitial: any;
  showConfirmBox: boolean;
  currentRowData: any;
  currentStatus: any;
  replyData: any;
  childData: any;
  CurrentRowIndex: any;
  commenttype: any;
  currentArray: any;
  parentIndex: any;
  name_From: string;
  cmt_r: string;
  days: number;
  minutes: number;
  hours: number;
  distance: number;
  countDownDate: number;
  splitString: any;
  now: number;
  lavels: any;
  newComment = [];
  activeFaq: any;
  listOfQuestions: any;
  isMainCommentAcordian: boolean;
  extension: string;
  isComment: string;
  currentUrlofvideo: any;
  autoGeneratedVideosId:any;
  listOfQuestionsAns: any[] =[];
  moreShowDataQAArray: any[] =[];
  constructor(private authenticationService: AuthenticationService, private sanitizer: DomSanitizer, private _MarathonService: MarathonService, private activeRoute: ActivatedRoute, ) { }

  ngOnInit() {
    this.autoGeneratedVideosId = Math.random().toString(16).slice(2);
    this.isShowDiscription = this.discription
    this.extension = localStorage.getItem('extension')
    this.isComment = localStorage.getItem('isComment')
    this.activeRoute.snapshot.params
    this.commentData = {
      pageSize: 5,
      pageIndex: 0
    }
    this.role = localStorage.getItem("role");
    this.data;
    if (this.data)
      this.getCommentList()
  }

  showExerciseDescription() {
    this.isShowDiscription = !this.isShowDiscription
  }
  scrollHandler(e) {
    var a = this.newComment.length + 100;
    for (let index = this.newComment.length; index <= a; index++) {
      if (this.getComment[index] == null) {
        break;
      } else {
        this.newComment.push(this.getComment[index]);
      }
    }
  }
  setIndex(index, array) {
    array[index]['currentActiveQuestion'] = !array[index]['currentActiveQuestion']
  }
  closeAcordian() {
    this.listOfQuestions = []
  }

  suggestion(event, comment, name) {
    if (event.keyCode == 32 || event.code == "") {
      this.listOfQuestions = [];
      this._MarathonService.getSuggestion(comment).then(response => {
        this.activeFaq = name;
        this.listOfQuestions = response.json();
        this.isMainCommentAcordian = true;
        for (let i = 0; i < this.listOfQuestions.length; i++) {
          this.listOfQuestions[i]['currentActiveQuestion'] = false;
        }
      }, error => {
        this.show = false;
        this.isError = true;
        this.user_marathondayerror = error.text();
      })

    } else if (event.keyCode == 8) {
      this.activeFaq = null;
      this.listOfQuestions = [];
    }
    else {
      this.activeFaq = null;
      this.listOfQuestions = [];
    }
  }

  showComments() {
    this.isShowComment = !this.isShowComment
    if (this.getComment.length <= 100) {
      this.newComment = []
      for (let index = 0; index < this.getComment.length; index++) {
        this.newComment.push(this.getComment[index])
      }
    } else if (this.getComment.length > 100) {
      this.newComment = []
      for (let index = 0; index < 100; index++) {
        if (this.getComment[index] == null) {
          break;
        } else {
          this.newComment.push(this.getComment[index])


        }
      }
    }
  }
  checkUrl(str) {
    if (str != null && str != undefined && str != '') {
      if (str.changingThisBreaksApplicationSecurity != undefined) {
        var string = str.changingThisBreaksApplicationSecurity;
        var n = string.includes("vimeo");
        return n
      } else {
        var string = str;
        var n = string.includes("vimeo");
        return n
      }
    }
  }
  stopVideo() {
    let listaFrames = document.getElementsByTagName("video");
    for (var index = 0; index < listaFrames.length; index++) {
      listaFrames[index].pause();
    }
  }
  //get name initials
  getNameInitials(name) {
    if (name) {
      this.FullName = name;
      this.splitFullName = this.FullName.split(" ");
      var stringArray = new Array();
      if (this.splitFullName.length > 1) {
        for (var i = 0; i <= 1; i++) {
          stringArray.push(this.splitFullName[i]);
        }
        this.NameInitial = stringArray[0].substring(0, 1) + stringArray[1].substring(0, 1)
      } else {
        this.NameInitial = this.FullName.substring(0, 1)
      }
      return this.NameInitial.toUpperCase();
    }
  }

  /**get all comments list done by users */
  getCommentList() {
    debugger
    this._MarathonService.getMarathoncomment(this.data.id, this.activeRoute.snapshot.params.marathonID, this.extension).then(response => {
      this.getComment = response.json()['items'];
      this.newComment = response.json()['items'];
      let oneDayTimeStamp = 86400;
      for (let k = 0; k < this.getComment.length; k++) {
        this.getComment[k]['cmttime'] = new Date(this.getComment[k]['createdDate']).getTime();
        this.getComment[k]['checkTime'] = this.getComment[k]['cmttime'] + 86400
        if (oneDayTimeStamp > (Math.floor(new Date().getTime() / 1000) - Math.floor(this.getComment[k]['cmttime'] / 1000))) {
          this.getComment[k]['greaterThenDay'] = true
        } else {
          this.getComment[k]['greaterThenDay'] = false
        }
      }
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status) {
        this.show = false;
        this.isError = true;
        this.user_marathondayerror = error.text();
      }
    });
  }

  addclass(id) {
    document.getElementById(id).classList.remove("vjs-user-inactive")
    document.getElementById(id).classList.add("vjs-user-active")
  }

  /**show reply of comments */
  onReply(array, index) {
    this.comment_reply_message = null;
    this.name_From = '@' + array[0].userModel.fullName
    for (let i = 0; i < array.length; i++) {
      if (i == index) {
        array[index]['showReply'] = true
      } else {
        array[i]['showReply'] = false
      }
    }
  }

  hideComment(id, index) {
    this.newComment[index]['pageIndex'] = undefined
    for (let j = 0; j < this.newComment.length; j++) {
      if (this.newComment[j].id == id) {
        if (!this.newComment[j]['replyArray']) {
          this.newComment[j]['replyArray'] = [];
          this.newComment[j]['startIndex'] = 0;
          this.newComment[j]['length'] = 5
        }
        this.newComment[j].replyArray = [];
        this.newComment[j]['startIndex'] = 0;
        this.newComment[j]['length'] = 5
      }
    }
  }

  onHidePopup() {
    this.showConfirmBox = false
    this.currentRowData = null
    this.currentStatus = null
    this.commenttype = null
    this.CurrentRowIndex = null
    this.currentArray = null
    this.parentIndex = null
  }

  removeCommentOnList(items, status, levels, CurrentRowIndex, commenttype, currentArray, parentIndex) {
    this.show = true;
    this.rowData = items;
    let data = {
      commentId: this.rowData.id,
      status: status
    }
    this._MarathonService.changeMessageStatus(data).then(response => {
      this.show = false;
      this.showConfirmBox = false
      this.isError = false;
      if (levels == 'parent') {
        this.getCommentList()
      } else {
        this.getComment[parentIndex].childCommentCount = this.getComment[parentIndex].childCommentCount - 1
        currentArray.splice(CurrentRowIndex, 1)
      }
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status) {
        this.show = false;
        this.isError = true;
        this.user_marathondayerror = error.text();
      }
    });
  }

  // update message status
  updateMessageStatus(items, status, event, childIndex, levels, array, parentIndex) {
    if (event == 'reject') {
      this.showConfirmBox = true;
      this.currentRowData = items;
      this.currentStatus = status;
      this.lavels = levels
      this.currentArray = array
      this.CurrentRowIndex = childIndex
      this.parentIndex = parentIndex
    } else {
      this.show = true;
      this.rowData = items;
      let data = {
        commentId: this.rowData.id,
        status: status
      }
      this._MarathonService.changeMessageStatus(data).then(response => {
        this.show = false;
        this.isError = false;
        this.getCommentList();
      }, error => {
        let status = this.authenticationService.errormessag(error);
        if (status) {
          this.show = false;
          this.isError = true;
          this.user_marathondayerror = error.text();
        }
      });
    }
  }

  // get child comment
  getChildComment(id, index, message) {
    message['show'] = true;
    if (this.newComment[index]['pageIndex'] === undefined) {
      this.newComment[index]['pageIndex'] = 0;
      this.commentData['marathonId'] = this.activeRoute.snapshot.params.marathonID;
      this.commentData['exerciseId'] = this.data.id,
        this.commentData['commentId'] = id,
        this.commentData['pageIndex'] = this.newComment[index]['pageIndex']
      this._MarathonService.getChildCmnt(this.commentData, this.extension).then(response => {
        message['show'] = false
        this.isError = false;
        this.getCommentchild = response.json()['items'];
        let oneDayTimeStamp = 86400
        for (let k = 0; k < this.getCommentchild.length; k++) {
          this.getCommentchild[k]['cmttime'] = new Date(this.getCommentchild[k]['createdDate']).getTime();
          this.getCommentchild[k]['checkTime'] = this.getCommentchild[k]['cmttime'] + 86400
          if (oneDayTimeStamp > (Math.floor(new Date().getTime() / 1000) - Math.floor(this.getCommentchild[k]['cmttime'] / 1000))) {
            this.getCommentchild[k]['greaterThenDay'] = true
          } else {
            this.getCommentchild[k]['greaterThenDay'] = false
          }
        }
        for (let j = 0; j < this.newComment.length; j++) {
          if (this.newComment[j].id == id) {
            if (!this.newComment[j]['replyArray'] && !this.newComment[j]['startIndex']) {
              this.newComment[j]['replyArray'] = [];
              this.newComment[j]['startIndex'] = 0;
              this.newComment[j]['length'] = 5
            }
            for (this.newComment[j]['startIndex']; this.newComment[j]['startIndex'] < this.newComment[j]['length']; this.newComment[j]['startIndex']++) {
              if (this.getCommentchild[this.newComment[j]['startIndex']])
                this.newComment[j]['replyArray'].push(this.getCommentchild[this.newComment[j]['startIndex']]);
            }
            this.newComment[j]['length'] = this.newComment[j]['length'] + 5
          }
        }
      }, error => {
        let status = this.authenticationService.errormessag(error);
        if (status) {
          this.show = false;
          this.isError = true;
          this.user_marathondayerror = error.text();
        }
      });
    }
    else {
      for (let j = 0; j < this.newComment.length; j++) {
        if (this.newComment[j].id == id) {
          if (!this.newComment[j]['replyArray']) {
            this.newComment[j]['replyArray'] = [];
          }
          for (this.newComment[j]['startIndex']; this.newComment[j]['startIndex'] < this.newComment[j]['length']; this.newComment[j]['startIndex']++) {
            if (this.getCommentchild[this.newComment[j]['startIndex']])
              this.newComment[j]['replyArray'].push(this.getCommentchild[this.newComment[j]['startIndex']]);
          }
          this.newComment[j]['length'] = (this.newComment[j].childCommentCount - this.newComment[j]['replyArray'].length) > 5 ? this.newComment[j]['length'] + 5 : (this.newComment[j]['startIndex'] + this.newComment[j].childCommentCount - this.newComment[j]['replyArray'].length)
        }
      }
      message['show'] = false
    }
  }

  /**show reply on comments reply */
  onReplyOfReply(array, parentIndex, childIndex) {
    this.name_From = null;
    this.name_From = '@' + array[0].userModel.fullName
    this.comment_reply_message_of_reply = null
    for (let i = 0; i < array.length; i++) {
      if (childIndex == i) {
        array[childIndex]['showReply'] = true
      } else {
        array[i]['showReply'] = false
      }
    }
  }
  timer(time) {
    // Update the count down every 1 second
    this.splitString = time
    this.countDownDate = new Date(this.splitString).getTime();
    // Get todays date and time
    this.now = new Date().getTime();
    // Find the distance between now and the count down date
    this.distance = this.now - this.countDownDate;
    // Time calculations for days, hours, minutes and seconds
    this.days = Math.floor(this.distance / (1000 * 60 * 60 * 24));
    this.minutes = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60));
    this.hours = Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    if (this.days) {
      return this.days + 'd'
    } else if (this.hours) {
      return this.hours + 'h'
    } else {
      return this.minutes + 'm'
    }
  }

  onReplyCommentReply(item, parentIndex, childIndex, array) {
    if (this.comment_reply_message_of_reply) {
      this.show = true;
      this.cmt_r = null
      let style = `<label style="color: #003569;">${this.name_From} </label>`
      this.cmt_r = style + ' ' + this.comment_reply_message_of_reply;
      let data = {
        exerciseId: item.exerciseId,
        marathonId: item.marathonId,
        commentId: item.id,
        comment: this.cmt_r,
        parentCommentId: array[childIndex].id
      }
      this._MarathonService.commentMessage(data).then(response => {
        this.newComment[parentIndex].replyArray[childIndex]['showReply'] = false;
        this.show = false;
        this.replyData = response.json()
        array.push(this.replyData)
        this.isError = false;
        this.comment_reply_message_of_reply = null
      }, error => {
        let status = this.authenticationService.errormessag(error);
        if (status) {
          this.show = false;
          this.isError = true;
          this.user_marathondayerror = error.text();
        }
      });
    }
  }

  commitComment() {
    this.show = true;
    let data = {
      comment: this.comment_message,
      exerciseId: this.data.id,
      marathonId: this.activeRoute.snapshot.params.marathonID
    }
    /**get comments list in exercise detil */
    this._MarathonService.commentMessage(data).then(response => {
      this.show = false;
      this.isError = false;
      this.comment_message = null;
      this.getCommentList()
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status) {
        this.show = false;
        this.isError = true;
        this.user_marathondayerror = error.text();
      }
    });
  }

  /** show comments on reply*/
  onReplyComment(item, index) {
    if (this.comment_reply_message) {
      this.show = true;
      this.newComment[index]['showReply'] = false;
      let style = `<label style="color: #003569;">${this.name_From} </label>`
      this.cmt_r = style + ' ' + this.comment_reply_message;
      let data = {
        exerciseId: item.exerciseId,
        marathonId: item.marathonId,
        commentId: item.id,
        comment: this.cmt_r,
        parentCommentId: item.id
      }
      this._MarathonService.commentMessage(data).then(response => {
        this.show = false;
        this.childData = response.json()
        ++item.childCommentCount
        if (!this.newComment[index]['replyArray'])
          this.newComment[index]['replyArray'] = []
        this.newComment[index]['replyArray'].push(this.childData)
        this.isError = false;
        this.comment_reply_message = null
      }, error => {
        let status = this.authenticationService.errormessag(error);
        if (status) {
          this.show = false;
          this.isError = true;
          this.user_marathondayerror = error.text();
        }
      });
    }
  }
  isCommentUseful(item, status) {
    this.show = true;
    let data = {
      exerciseFAQId: item.quesionId,
      like: status,
    }
    this._MarathonService.getFaqLike(data).then(response => {
      item['like'] = !item['like'];
      this.show = false;
    }, error => {
      this.show = false;
    })
  }
  likeOnComment(value, currentValue, commentId) {
    let data = {
      commentId: currentValue.id,
      like: value
    }
    this._MarathonService.getCommentLike(data).then(response => {
      currentValue['like'] = !currentValue['like'];
    }, error => {
    })
  }
  suggestionQA(){
    this._MarathonService.suggestionQA(this.data.exerciseName + ' ' +this.data.marathonExerciseName + ' '+this.comment_message).then(response => {
  this.listOfQuestionsAns = response.json()['value'];
  for (let index = 0; index < this.listOfQuestionsAns.length; index++) {
    this.listOfQuestionsAns[index]['question'] = this.listOfQuestionsAns[index]['question'].replace(new RegExp("&nbsp;", "g"), "").replace(new RegExp('Froala Editor', 'g'), '').replace(new RegExp('Powered by', 'g'), '').replace(new RegExp("&quot;", "g"), "").replace(new RegExp("&gt;", "g"), "").replace(new RegExp("&amp;", "g"), "").replace(new RegExp("&quot;", "g"), "");;
          this.listOfQuestionsAns[index]['answer'] = this.listOfQuestionsAns[index]['answer'].replace(new RegExp("&nbsp;", "g"), "").replace(new RegExp('Froala Editor', 'g'), '').replace(new RegExp('Powered by', 'g'), '').replace(new RegExp("&quot;", "g"), "").replace(new RegExp("&gt;", "g"), "").replace(new RegExp("&amp;", "g"), "").replace(new RegExp("&quot;", "g"), "");;
  }

}, error => {
  this.show = false;
  this.isError = true;
  this.user_marathondayerror = error.text();
})
}
moreShowDataQA(id){
  if(this.moreShowDataQAArray.indexOf(id) == -1){
  this.moreShowDataQAArray.push(id)
  }else{
    this.moreShowDataQAArray.splice(this.moreShowDataQAArray.indexOf(id),1)
  }
}
}
