import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { MarathonService } from '../../services/marathon.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { PlatformLocation } from "@angular/common";
@Component({
  selector: 'app-user-exercise-detail',
  templateUrl: './user-exercise-detail.component.html',
  styleUrls: ['./user-exercise-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserExerciseDetailComponent implements OnInit {
  @Input() exeData;
  public show;
  public isError;
  user_marathondayerror;
  day_exercise_Detail: any;
  showContaint: boolean;
  getComment: any;
  public slideImage = 0;
  public comment_message
  showReply: any;
  public replyArray = [];
  public comment_reply_message;
  sortedItems: any;
  public comment_reply_message_of_reply;
  title: string;
  day: string;
  day_id: string;
  today: number = Date.now();
  isShowComment = true;
  isShowDiscription = true;
  video_Url: any
  rowData: any;
  role: string;
  public enableViewMore = true
  commentData: {};
  childArray = [];
  getCommentchild: any;
  conTenttype: string;
  newchild: any;
  extension: string;
  constructor(private platformLocation: PlatformLocation, private _MarathonService: MarathonService, private router: Router, private activeRoute: ActivatedRoute, private sanitizer: DomSanitizer,) {
    platformLocation.onPopState(() => {
      this.router.navigateByUrl("/user_marathon_day_detail/" + this.activeRoute.snapshot.params.marathonID + '/' + this.activeRoute.snapshot.params.id);
      history.forward();
    })
  }

  ngOnInit() {
    this.extension = localStorage.getItem('extension')
    this.exeData
    this.show = true;
    this.commentData = {
      pageSize: 5,
      pageIndex: 0
    }
    /**get data according to get marathon day */
    this.day = localStorage.getItem("day");
    this.title = localStorage.getItem("marathon_name");
    this.role = localStorage.getItem("role");
    this.day_id = localStorage.getItem("day_id");
    this._MarathonService.getMarathonDayById(this.activeRoute.snapshot.params.id).then(response => {
      this.show = false;
      this.isError = false;
      this.day_exercise_Detail = response.json()
      for (let m = 0; m < this.day_exercise_Detail.exerciseContents.length; m++) {
        if (this.day_exercise_Detail.exerciseContents[m].contentPath.split("/")[2] == "youtu.be") {
          this.day_exercise_Detail.exerciseContents[m].contentPath = this.sanitizer.bypassSecurityTrustResourceUrl(this.day_exercise_Detail.exerciseContents[m].contentPath.replace("youtu.be", "www.youtube.com/embed/"))
        } else if (this.day_exercise_Detail.exerciseContents[m].contentPath.split("/")[2] == "www.youtube.com" || this.day_exercise_Detail.exerciseContents[m].contentPath.split("/")[2] == "player.vimeo.com") {
          this.day_exercise_Detail.exerciseContents[m].contentPath = this.sanitizer.bypassSecurityTrustResourceUrl(this.day_exercise_Detail.exerciseContents[m].contentPath.replace("watch?v=", "embed/"))
        }
      }
    }, error => {
      this.show = false;
      this.isError = true;
      this.user_marathondayerror = error.text();
    });
    this.getCommentList()
  }
  ifValidImage(filePath) {

    if (filePath.toLowerCase().includes('.png') || filePath.toLowerCase().includes('.jpg') || filePath.toLowerCase().includes('.jpeg') || filePath.toLowerCase().includes('.gif'))
      return true;
    else
      return false;
  }
  /**check video url is safe or valid!! */
  videoSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url.replace("watch?v=", "embed/"))
  }
  /**get all comments list done by users */
  getCommentList() {
    debugger
    this.show = true;
    this._MarathonService.getMarathoncomment(this.activeRoute.snapshot.params.id, this.activeRoute.snapshot.params.marathonID, this.extension).then(response => {
      this.getComment = response.json()['items'];
      this.isError = false;
      this.show = false;
    }, error => {
      this.show = false;
      this.isError = true;
      this.user_marathondayerror = error.text();
    });
  }
  // get child comment
  getChildComment(id, index) {
    if (this.getComment[index]['pageIndex'] === undefined) {
      this.getComment[index]['pageIndex'] = 0;
    } else {
      this.getComment[index]['pageIndex'] = this.getComment[index]['pageIndex'] + 1
      this.commentData['pageIndex'] = this.getComment[index]['pageIndex']
    }
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    this.show = true;
    this.commentData['marathonId'] = this.activeRoute.snapshot.params.marathonID;
    this.commentData['exerciseId'] = this.activeRoute.snapshot.params.id,
      this.commentData['commentId'] = id

    this._MarathonService.getChildCmnt(this.commentData, this.extension).then(response => {
      this.show = false;
      this.isError = false;
      this.getCommentchild = response.json()['items'];
      for (let j = 0; j < this.getComment.length; j++) {
        if (this.getComment[j].id == id) {
          if (!this.getComment[j]['replyArray']) {
            this.getComment[j]['replyArray'] = [];
          }
          for (let l = 0; l < this.getCommentchild.length; l++) {
            this.getComment[j]['replyArray'].push(this.getCommentchild[l]);
          }
        }
      }
    }, error => {
      this.show = false;
      this.isError = true;
      this.user_marathondayerror = error.text();
    });
  }

  hideComment(id, index) {
    this.getComment[index]['pageIndex'] = undefined
    for (let j = 0; j < this.getComment.length; j++) {
      if (this.getComment[j].id == id) {
        if (!this.getComment[j]['replyArray']) {
          this.getComment[j]['replyArray'] = [];
        }
        this.getComment[j].replyArray = []
      }
    }
  }
  minTwoDigits(n) {
    return (n < 10 ? '0' : '') + n;
  }

  /**show reply of comments */
  onReply(array, index) {
    this.comment_reply_message = null
    for (let i = 0; i < array.length; i++) {
      if (i == index) {
        array[index]['showReply'] = true
      } else {
        array[i]['showReply'] = false
      }
    }
  }

  /**show reply on comments reply */
  onReplyOfReply(array, parentIndex, childIndex) {
    this.comment_reply_message_of_reply = null
    for (let i = 0; i < array.length; i++) {
      if (childIndex == i) {
        array[childIndex]['showReply'] = true
      } else {
        array[i]['showReply'] = false
      }
    }
  }

  /**go back on click back button in user_marathon day exercise */
  onBack() {
    this.router.navigateByUrl('/user_marathon_day_exercise/' + this.activeRoute.snapshot.params.marathonID + '/' + this.day_id);
  }

  /** show comments on reply*/
  onReplyComment(item, index) {
    this.show = true;
    this.getComment[index]['showReply'] = false;
    let data = {
      exerciseId: item.exerciseId,
      marathonId: item.marathonId,
      commentId: item.id,
      comment: this.comment_reply_message
    }
    this._MarathonService.commentMessage(data).then(response => {
      this.show = false;
      this.isError = false;
      this.comment_reply_message = null
      this.getCommentList()
    }, error => {
      this.show = false;
      this.isError = true;
      this.user_marathondayerror = error.text();
    });
  }
  showExerciseDescription() {
    this.isShowDiscription = !this.isShowDiscription
  }
  showComments() {
    this.isShowComment = !this.isShowComment
  }
  onReplyCommentReply(item, parentIndex, childIndex) {
    if (this.comment_reply_message_of_reply) {
      this.show = true;
      this.getComment[parentIndex].replyArray[childIndex]['showReply'] = true;
      let data = {
        exerciseId: item.exerciseId,
        marathonId: item.marathonId,
        commentId: item.id,
        comment: this.comment_reply_message_of_reply
      }
      this._MarathonService.commentMessage(data).then(response => {
        this.show = false;
        this.isError = false;
        this.comment_reply_message_of_reply = null;
        this.getCommentList()
      }, error => {
        this.show = false;
        this.isError = true;
        this.user_marathondayerror = error.text();
      });
    }
  }

  /**show exercise detail */
  showExerciseDetail() {
    this.showContaint = !this.showContaint;
  }

  // update message status
  updateMessageStatus(items, status) {
    this.show = true;
    this.rowData = items;
    let data = {
      commentId: this.rowData.id,
      status: status
    }
    this._MarathonService.changeMessageStatus(data).then(response => {
      this.show = false;
      this.isError = false;
      this.getCommentList();
    }, error => {
      this.show = false;
      this.isError = true;
      this.user_marathondayerror = error.text();
    });
  }

  commitComment() {
    if (this.comment_message) {
      this.show = true;
      let data = {
        comment: this.comment_message,
        exerciseId: this.activeRoute.snapshot.params.id,
        marathonId: this.activeRoute.snapshot.params.marathonID
      }
      /**get comments list in exercise detil */
      this._MarathonService.commentMessage(data).then(response => {
        this.show = false;
        this.isError = false;
        this.comment_message = null;
        this.getCommentList()
      }, error => {
        this.show = false;
        this.isError = true;
        this.user_marathondayerror = error.text();
      });
    }
  }
}