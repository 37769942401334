import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'replaces'
})
export class ReplacesPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value: any, args?: any): any {
    
    if (value == undefined) {
      return null
    }
    // return value
    value = value.replace(new RegExp("Froala Editor", "g"), "");
    value = value.replace(new RegExp("Powered by ", "g"), "");
   return this.sanitized.bypassSecurityTrustHtml(value)
  }

}
