import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { MarathonService } from '../../services/marathon.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { PlatformLocation } from '@angular/common';
import { Location } from '@angular/common';
import { AuthenticationService } from 'app/services/authentication.service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-marathon-plan',
  templateUrl: './marathon-plan.component.html',
  styleUrls: ['./marathon-plan.component.scss']
})
export class MarathonPlanComponent implements OnInit {

  public show = false;
  public iserror;
  languageType: any;
  showdropdowemenu: boolean;
  langCode: string
  showandHideDayPlan: boolean;
  getMarathonPlansDetails: any;
  activeIds = [];
  marathonTitle = '';
  constructor(private language: LanguageService, private authenticationService: AuthenticationService, private platformLocation: PlatformLocation, private _MarathonService: MarathonService, private activeRoute: ActivatedRoute, private location: Location, private sanitizer: DomSanitizer, private router: Router) {
  }

  ngOnInit() {
    this.marathonTitle = localStorage.getItem('Marathontitle')
    this.showandHideDayPlan = true;
    var data = localStorage.getItem('language');
    if (data != null) {
      this.langCode = data;
      this.language.language(data).then((response) => {
        this.languageType = response
      });
    } else {
      this.langCode = 'Rus';
      this.language.language('Rus').then((response) => {
        this.languageType = response
      });
    }
    this.show = true;
    this._MarathonService.getMarathonPlans(this.activeRoute.snapshot.params.marathonId).then(response => {
      this.show = false;
      this.getMarathonPlansDetails = response.json();
    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        // this.error = status.errormessag;
      }
    })
  }
  changeText(event) {
    if (event) {
      return this.languageType[event.split('.')[0]][event.split('.')[1]]
    } else {
      return '';
    }
  }
  showdropdowe() {
    this.showdropdowemenu = !this.showdropdowemenu;
  }

  english() {
    localStorage.setItem('language', 'Eng');
    this.langCode = 'Eng';
    this.language.language('Eng').then((response) => {
      this.languageType = response
    });
  }

  russian() {
    localStorage.setItem('language', 'Rus');
    this.langCode = 'Rus';
    this.language.language('Rus').then((response) => {
      this.languageType = response
    });
  }
  italian() {
    localStorage.setItem('language', 'Ita');
    this.langCode = 'Ita'
    this.language.language('Ita').then((response) => {
      this.languageType = response
    });
  }

  moveUp() {
    window.scrollBy(0, -40000);
  }

  getTrustedUrl(event) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(event);
  }

  logout() {
    localStorage.clear()
    this.router.navigateByUrl('/register/userlogin');
  }

  changePassword() {
    localStorage.setItem('url', this.router.url)
    this.router.navigateByUrl('/changepassword');
  }
  notifications() {
    localStorage.setItem('url', this.router.url)
    this.router.navigateByUrl('/notifications');
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    this.showdropdowemenu = false;
  }

  contest() {
    this.router.navigateByUrl('/contest');
  }

  contestvote() {
    this.router.navigateByUrl('/contest_vote');
  }

  myOrder() {
    this.router.navigateByUrl('/orderlist');
  }

  payment(item) {
    const modeldata = {
      marathonId: this.activeRoute.snapshot.params.marathonId,
      wpOrderId: null,
      isFromWordpress: false,
      planId: item.id
    }
    this.show = true;
    this._MarathonService.createOrderForTransaction(modeldata).then(response => {
      this.show = false;
      if (item.isSold) {
        return;
      }
      localStorage.setItem('cost', item.cost);
      if (this.activeRoute.snapshot.params.isGift === '1') {
        this.router.navigateByUrl('/payment/1/' + item.id);
      } else {
        this.router.navigateByUrl('/payment/' + item.id);
      }
    })
  }

}

