import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MarathonService } from '../../services/marathon.service';
// import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserHeaderComponent implements OnInit {
  @Input() myData;
  isContest: string
  @Output()
  uploaded = new EventEmitter<string>();
  public userNotification = [];
  path: string;
  description = { description: '', marathonId: '', marathonTitle: '', isContestParticipated: '' };
  getComment: any;
  showdropdowemenu: boolean;
  languageType: any;
  constructor(private router: Router, private language: LanguageService,  private activeRoute: ActivatedRoute, private _MarathonService: MarathonService) { }

  ngOnInit() {
    this.language.language("Rus").then((response) => {
      this.languageType = response
    });
    this.isContest = localStorage.getItem('isContest')
    let currentPAth = this.activeRoute.snapshot.routeConfig.path.split("/")
    this.path = currentPAth[0]
  }

  uploadComplete() {
    this.uploaded.emit();
  }
  scrollDown() {
    window.scrollBy(0, 40000);
  }

  showdropdowe() {
    this.showdropdowemenu = !this.showdropdowemenu;
  }
  logout() {
    localStorage.clear();
    this.router.navigateByUrl('/register/userlogin');
  }
  notifications() {
    localStorage.setItem('url', this.router.url)
    this.router.navigateByUrl('/notifications');
  }

  changePassword() {
    localStorage.setItem("url", this.router.url)
    this.router.navigateByUrl('/changepassword');
  }


  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    this.showdropdowemenu = false;
  }

  contest() {
    this.router.navigateByUrl('/contest');
  }

  contestvote() {
    this.router.navigateByUrl('/contest_vote');
  }
  myOrder() {
    this.router.navigateByUrl('/orderlist');
  }
  checkContestShowAndHindden(){
    if(localStorage.getItem('productType') === 'Marathon'){
      if (localStorage.getItem('accessContest') == 'true') {
        return 'Contest';
      } else {
        return '';
      }
    }else{
      return 'Photo diary';
    }
  }
}
