// import { state } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MarathonService } from '../../services/marathon.service';
import { environment } from '../../environment';
import { Md5 } from 'ts-md5/dist/md5';
import { AuthenticationService } from '../../services/authentication.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LanguageService } from '../../services/language.service'
@Component({
  selector: 'app-cloud-payment-status',
  templateUrl: '../robo-payment-status/robo-payment-status.component.html',
  styleUrls: ['../robo-payment-status/robo-payment-status.component.scss']
})
export class CloudPaymentComponent implements OnInit {
  show: boolean;
  apiError: any;
  successmessages: boolean;
  failmessages: boolean;
  data: any;
  orderDetail: any;
  Error: boolean;
  sigValue: string | Int32Array;
  client: any;
  status: any;
  checkMarathonPurchace: any;
  IsGift: boolean;
  deviceInfo: any;
  isShowPopup: boolean;
  marathonid: string;
  langCode: string;
  languageType: any;
  marathonData: any;

  constructor(private authenticationService: AuthenticationService, private language: LanguageService, private deviceService: DeviceDetectorService, public router: Router, public _MarathonService: MarathonService, public activeRoute: ActivatedRoute, private md5: Md5) { }

  ngOnInit() {
    var data = localStorage.getItem("language")
    if (data != null) {
      this.langCode = data;
      this.language.language(data).then((response) => {
        this.languageType = response
      });
    } else {
      this.langCode = "Rus";
      this.language.language("Rus").then((response) => {
        this.languageType = response
      });
    }
    debugger
    let a = JSON.parse(this.activeRoute.snapshot.params.json)
    if( JSON.parse(this.activeRoute.snapshot.params.json).amount === 1){
      this._MarathonService.addNewCard(this.activeRoute.snapshot.params.invoiceId).then(response => {
        this.router.navigateByUrl('/payment');
      }, error => {
        let status = this.authenticationService.errormessag(error);
        if (status.showError) {
          this.show = false;
          this.Error = true;
          this.router.navigateByUrl('/payment');
          this.apiError = status.errormessag;
        }
      });
    }

  }
  checkForMarathonPurchased() {
    // this.deviceInfo = this.deviceService.getDeviceInfo();
    // this.md5 = new Md5();
    this.show = true;
    this.marathonid = localStorage.getItem('marthonidorder')
    this._MarathonService.CheckForMarathonPurchased(this.marathonid).then(response => {
      this.data = response.json();
      localStorage.setItem('subscribeToCourseCloudFirstTime','Yes');
      this.orderDetail = this.data.orderNumber;
      this.IsGift = this.data.isGift;
      this.marathonid = localStorage.getItem('marthonidorder')
      this.checkMarathonPurchace = response.json();
      if (this.checkMarathonPurchace.productType === "Course") {
        this.subscribeWithCloud();
      } else {
        this.getcloudPaymentWeb();
      }
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.Error = true;
        this.apiError = status.errormessag;
      }
    });

    // if (this.activeRoute.snapshot.params.result == 'success') {
    //   this.successmessages = true;
    //   this.show = true;
    // } else if (this.activeRoute.snapshot.params.result == 'fail') {
    //   this.failmessages = true;
    //   this.router.navigateByUrl('/errorstatus');
    // }
  }
  english() {
    localStorage.setItem("language", "Eng")
    this.langCode = "Eng"
    this.language.language("Eng").then((response) => {
      this.languageType = response
    });
  }

  russian() {
    localStorage.setItem("language", "Rus")
    this.langCode = "Rus"
    this.language.language("Rus").then((response) => {
      this.languageType = response
    });
  }
  italian() {
    localStorage.setItem("language", "Ita")
    this.langCode = "Ita"
    this.language.language("Ita").then((response) => {
      this.languageType = response
    });
  }
  subscribeWithCloud() {
    this.show = true;
    // this.sigValue = this.md5.appendAsciiStr(`${environment.robokassaMrchLogin}:${this.orderDetail}:${environment.robokasaTestsMrchPass2}`).end();
    this._MarathonService.subscribeWithCloud(this.activeRoute.snapshot.params.invoiceId).then(res => {
      let responseData = res['_body']
      this.show = true;
      // if (responseData == 'success' || responseData || responseData == 'true') {
        this.show = true;
        this.isShowPopup = false;
        this._MarathonService.GetMarathons().then(response => {
          this.show = false
          this.isShowPopup = true
          this.Error = false
          let GetMarathons = response.json();
          this.marathonid = GetMarathons[0].id
          if (!this.IsGift) {
            this._MarathonService.getUserMarathonDetail(GetMarathons[0].id).then(response => {
              this.show = false
              this.isShowPopup = true
              this.Error = false
              this.marathonData = response.json()
              if (this.marathonData.marathonDays != null) {
                var indexOfDay = this.marathonData.marathonDays.findIndex(i => i.isActive);
                this.router.navigateByUrl('/user_marathon_day_exercise/' + GetMarathons[0].id + '/' + this.marathonData.marathonDays[indexOfDay].id);
              } else {
                this.router.navigateByUrl('/marathon_exercise/' + GetMarathons[0].id);
              }
              if (this.marathonData.greatExtensionDays != null) {
                indexOfDay = this.marathonData.greatExtensionDays.findIndex(i => i.isActive);
                this.router.navigateByUrl('/user_marathon_day_exercise/' + GetMarathons[0].id + '/' + this.marathonData.marathonDays[indexOfDay].id);
              }
            }, error => {
              let status = this.authenticationService.errormessag(error);
              if (status.showError) {
                this.isShowPopup = false
                this.show = false
                this.Error = true
                this.apiError = status.errormessag;
              }
            })
          } else {
            this.router.navigateByUrl('/gift');
          }
        }, error => {
          let status = this.authenticationService.errormessag(error);
          if (status.showError) {
            this.isShowPopup = false
            this.show = false
            this.Error = true
            this.apiError = status.errormessag;
          }
        });
      // } else {
      //   this.show = false;
      //   this.router.navigateByUrl('/errorstatus');
      // }
    }, error => {
      this.router.navigateByUrl('/errorstatus');
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.isShowPopup = false
        this.show = false;
        this.Error = true;
        this.apiError = status.errormessag;
      }
    });
  }
  getcloudPaymentWeb() {
    this.show = true;
    // this.sigValue = this.md5.appendAsciiStr(`${environment.robokassaMrchLogin}:${this.orderDetail}:${environment.robokasaTestsMrchPass2}`).end();
    this._MarathonService.cloudPaymentWeb(this.activeRoute.snapshot.params.invoiceId, this.activeRoute.snapshot.params.json).then(res => {
      let responseData = res['_body']
      this.show = true;
      if (responseData == 'success') {
        this.show = true;
        this.isShowPopup = false;
        this._MarathonService.GetMarathons().then(response => {
          this.show = false
          this.isShowPopup = true
          this.Error = false
          let GetMarathons = response.json();
          this.marathonid = GetMarathons[0].id
          if (!this.IsGift) {
            this._MarathonService.getUserMarathonDetail(GetMarathons[0].id).then(response => {
              this.show = false
              this.isShowPopup = true
              this.Error = false
              this.marathonData = response.json()
              if (this.marathonData.marathonDays != null) {
                var indexOfDay = this.marathonData.marathonDays.findIndex(i => i.isActive);
                this.router.navigateByUrl('/user_marathon_day_exercise/' + GetMarathons[0].id + '/' + this.marathonData.marathonDays[indexOfDay].id);
              } else {
                this.router.navigateByUrl('/marathon_exercise/' + GetMarathons[0].id);
              }
              if (this.marathonData.greatExtensionDays != null) {
                indexOfDay = this.marathonData.greatExtensionDays.findIndex(i => i.isActive);
                this.router.navigateByUrl('/user_marathon_day_exercise/' + GetMarathons[0].id + '/' + this.marathonData.marathonDays[indexOfDay].id);
              }
            }, error => {
              let status = this.authenticationService.errormessag(error);
              if (status.showError) {
                this.isShowPopup = false
                this.show = false
                this.Error = true
                this.apiError = status.errormessag;
              }
            })
          } else {
            this.router.navigateByUrl('/gift');
          }
        }, error => {
          let status = this.authenticationService.errormessag(error);
          if (status.showError) {
            this.isShowPopup = false
            this.show = false
            this.Error = true
            this.apiError = status.errormessag;
          }
        });
      } else {
        this.show = false;
        this.router.navigateByUrl('/errorstatus');
      }
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.isShowPopup = false
        this.show = false;
        this.Error = true;
        this.apiError = status.errormessag;
      }
    });
  }
  websiteRoute() {
    if (!this.IsGift) {
      this.router.navigateByUrl('/marathon_exercise/' + this.marathonid);
    } else {
      this.router.navigateByUrl('/gift');
    }
  }

  goToWebsite() {
    if (this.deviceInfo.os == 'Android') {
      this.isShowPopup = true;
      document.getElementById('showModel').click()
    } else {
      if (!this.isShowPopup) {
        if (!this.IsGift) {
          this.router.navigateByUrl('/marathon_exercise/' + this.marathonid);
        } else {
          this.router.navigateByUrl('/gift');
        }
      }
    }
  }
}
