import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MarathonService } from '../../services/marathon.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { AuthenticationService } from '../../services/authentication.service';
import { LanguageService } from '../../services/language.service';
import {environment as devEnvironment } from '../../environment';

@Component({
  selector: 'app-marathon-profile',
  templateUrl: './marathon-profile.component.html',
  styleUrls: ['./marathon-profile.component.scss']
})
export class MarathonProfileComponent implements OnInit {
  GetMarathons: any;
  iserror: boolean;
  error: any;
  show: boolean;
  languageType: any;
  langCode: string;
  showdetails: any;
  responce: any;
  cleartime: any;
  token_Detail: any;
  expires_in: any;
  refresh_token: any;
  isSafari: boolean;

  constructor(private authenticationService: AuthenticationService, private language: LanguageService, private router: Router, private _MarathonService: MarathonService, private EncrDecr: EncrDecrService,) { }

  ngOnInit() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') > -1) {
      } else {
        this.isSafari = true
      }
    }
    var data = localStorage.getItem('language')
    if (data != null) {
      this.langCode = data;
      this.language.language(data).then((response) => { 
        this.languageType = response;
        this.getCountry();
      });
    } else {
      this.langCode = 'Rus';
      this.language.language('Rus').then((response) => {
        this.languageType = response;
        this.getCountry();
      });
    }
    localStorage.removeItem('access_token');
    localStorage.removeItem('MarathonName');
    localStorage.removeItem('roleAdmin');
    localStorage.removeItem('role');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expires_in');
    this.show = true;
    this._MarathonService.GetMarathons().then(response => {
      this.iserror = false;
      this.GetMarathons = response.json();
      if (this.GetMarathons.length === 0) {
        this.router.navigateByUrl('/register/userlogin');
      }
      localStorage.setItem('MarathonName', this.GetMarathons[0].title)
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        this.error = status.errormessag;
      }
    });
    
    var OneSignal = window['OneSignal'] || [];
    console.log("Init OneSignal");
    OneSignal.push(["init", {
      appId: devEnvironment.oneSignalAppId,
      autoRegister: false,
      allowLocalhostAsSecureOrigin: true,
      notifyButton: {
        enable: false
      }
    }]);
    console.log('OneSignal Initialized');
    OneSignal.push(function () {
      console.log('Register For Push');
      OneSignal.push(["registerForPushNotifications"])
    });

    OneSignal.push(function () {
      // Occurs when the user's subscription changes to a new value.
      OneSignal.on('subscriptionChange', function (isSubscribed) {
        console.log("The user's subscription state is now:", isSubscribed);
      });
      OneSignal.getUserId().then(function (userId) {
        localStorage.setItem('userId', userId);
    });
    });
    
    this.isUserLogin()
  }
  getCountry(){
    this.show = true;
  this.language.getCountry().then((response:any) => { 
    // this.languageType = response;
    // console.log(response.json());
    this.show = false   ;
    let data = response.json();
    if(data.location.country.code == 'US'){
      localStorage.setItem('language', 'Eng')
      this.english()
    }
  });
}
  isUserLogin() {
    var token = localStorage.getItem('access_token')
    if (token) {
      this.loginUser()
    } else {
      this.show = false;
    }
  }

  loginUser() {
    var email = localStorage.getItem('rememberemail');
    var password = localStorage.getItem('rememberPassword');
    if (password != null && email != null) {
      var decrypted1 = this.EncrDecr.get('123456$#@$^@1ERF', email);
      var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', password);
      var EncrDecrPassword = decrypted;
      var EncrDecrEmail = decrypted1;
    }
    let user = {
      username: EncrDecrEmail,
      password: EncrDecrPassword,
      grant_type: 'password',
      requestFrom:"web"
    };
    this.show = true;
    this.authenticationService.login(user)
      .subscribe(Response => {
        this.token_Detail = Response.json()
        this.refresh_token = this.token_Detail.refresh_token;
        localStorage.setItem('access_token', this.token_Detail.access_token);
        localStorage.setItem('access_token', this.token_Detail.access_token);
        localStorage.setItem('role', this.token_Detail.role);
        localStorage.setItem('user_name', this.token_Detail.username);
        localStorage.setItem('user_email', this.token_Detail.email);
        localStorage.setItem('expires_in', this.token_Detail.expires_in);
        localStorage.setItem('refresh_token', this.token_Detail.refresh_token);
        if (this.token_Detail.role === 'User') {
          this.show = true;
          this._MarathonService.getActiveOrder().then(response => {
            let checkMarathonPurchace = response.json();
            localStorage.setItem('Marathontitle', checkMarathonPurchace.title);
            localStorage.setItem('startDate', checkMarathonPurchace.startDate);
            localStorage.setItem('cost', checkMarathonPurchace.cost);
            localStorage.setItem('marathonid', checkMarathonPurchace.id);
            localStorage.setItem('orderStatus', checkMarathonPurchace.orderStatus);
            if (checkMarathonPurchace.orderStatus === 'Approved') {
              if (checkMarathonPurchace.isGift) {
                localStorage.setItem('orderStatus', 'Initiated');
                this.router.navigateByUrl('/orderlist');
              } else {
                // this.show = true;
                // this._MarathonService.getUserMarathonDetail(checkMarathonPurchace.id).then(response => {
                var data = checkMarathonPurchace.userMarthonModel;
                localStorage.setItem('isContest', data['contest'])
                localStorage.setItem('productType', data.productType)
                localStorage.setItem('isComment', data['comment'])
                if (data.marathonDays == null) {
                  this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);
                } else {
                  for (let day = 0; day < data.marathonDays.length; day++) {
                    if (data.marathonDays[day].isActive === true) {
                      var newid = data.marathonDays[day].id;
                      this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + newid);
                    }
                    else {
                      this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);
                    }
                  }
                }
                // }, error => {
                //   let status = this.authenticationService.errormessag(error);
                //   if (status.showError) {
                //     this.show = false;
                //     this.iserror = true;
                //     if (error.text() == "Marathon is over") {
                //       this.error = "марафон завершен"
                //     } else {
                //       this.error = status.errormessag;
                //     }
                //   }
                // });
              }
            }
            else if (checkMarathonPurchace.orderStatus === 'Declined') {
              localStorage.setItem('marthonidorder', checkMarathonPurchace.id);
              this.router.navigateByUrl('/errorstatus');
            }
            else if (checkMarathonPurchace.orderStatus === 'Initiated') {
              localStorage.setItem('marthonidorder', checkMarathonPurchace.id);
              this.router.navigateByUrl('/orderlist');
            }
            else if (checkMarathonPurchace.orderStatus === 'Pending') {
              localStorage.setItem('marthonidorder', checkMarathonPurchace.id);
              this.router.navigateByUrl('/errorstatus');
            }
          }, error => {
            this.show = false;
            this.iserror = true;
            localStorage.removeItem('access_token')
            this.error = error.text();
          });

        }
        else {
          this.router.navigateByUrl('/dashboards/viewprofile');
        }
        this.expires_in = localStorage.getItem('expires_in');
        this.expires_in = this.token_Detail.expires_in * 1000 - 10000;
        this.cleartime = setInterval(() => {
          this.servicestoken();
        }, this.expires_in);
      }, error => {
        this.show = false;
        this.iserror = true;
        this.error = error.text();
      })
  }
  servicestoken() {
    var expires_in = localStorage.getItem('expires_in');
    if (expires_in != null) {
      this.show = true;
      this.authenticationService.RefreshtokenManage().subscribe(Response => {
        this.show = false;
        this.responce = Response.json();
        localStorage.setItem('refresh_token', this.responce.refresh_token);
        localStorage.setItem('expires_in', this.responce.expires_in);
        localStorage.setItem('access_token', this.responce.access_token);
      }, error => {
        this.show = false;
        if (error.status === 405) {

          let role = localStorage.getItem('role');
          if (role === 'SuperAdmin') {
            localStorage.clear();
            this.router.navigateByUrl('/');
          } else {
            localStorage.clear();
            this.router.navigateByUrl('/error');
          }
        }
      });
    } else {
      clearTimeout(this.cleartime)
    }
  }

  details(data) {
    this.showdetails = data;
  }

  english() {
    localStorage.setItem('language', 'Eng')
    this.langCode = 'Eng';
    this.language.language('Eng').then((response) => {
      this.languageType = response
    });
  }

  russian() {
    localStorage.setItem('language', 'Rus')
    this.langCode = 'Rus';
    this.language.language('Rus').then((response) => {
      this.languageType = response
    });
  }
  italian() {
    localStorage.setItem('language', 'Ita')
    this.langCode = 'Ita';
   this.language.language('Ita').then((response) => {
      this.languageType = response
    });
  }
  login() {
    this.router.navigateByUrl('/register/userlogin');
  }
  payMarathon(id) {
    this.router.navigateByUrl('/register/userlogin/' + id + '/0');
  }

  register() {
    this.router.navigateByUrl('/register/userregister');
  }

  daySpelling(day) {
    if (this.langCode === 'Rus') {
      if (day === 1 || day === 21 || day === 31 || day === 41 || day === 51 || day === 61 || day === 71 || day === 81 || day === 91) {
        return 'день';
      } else if ((day > 1 && day < 5)
        || (day > 21 && day < 25)
        || (day > 31 && day < 35)
        || (day > 41 && day < 45)
        || (day > 51 && day < 55)
        || (day > 61 && day < 65)
        || (day > 71 && day < 75)
        || (day > 81 && day < 85)
        || (day > 91 && day < 95)) {
        return 'дня';
      } else {
        return 'дней';
      }
    } else if (this.langCode === 'Eng') {
      if (day === 1) {
        return 'day';
      } else {
        return 'days';
      }
    } else {
      if (day === 1) {
        return 'giorno';
      } else {
        return 'giorni';
      }
    }
  }
}
