import { Authorizeheader } from './services/_helper/authorizeheader';
import { AuthenticationService } from './services/authentication.service';
import { LanguageService } from './services/language.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes.service';
import { ViewsModule } from './user_views/views.module';
import { LoginComponent } from './login/login.component';
import { HttpModule } from '@angular/http';
import { DndModule } from 'ng2-dnd';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { EncrDecrService } from './services/encr-decr.service';
import { PayPalPaymentComponent } from './pay-pal-payment/pay-pal-payment.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { Md5 } from 'ts-md5/dist/md5';
import { RoboPaymentStatusComponent } from './pay-pal-payment/robo-payment-status/robo-payment-status.component';
import { SafeHtmlnewPipe } from './pipe/safe-htmlnew.pipe';
import { ReplaceloginPipe } from './pipe/replacelogin.pipe';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CloudPaymentComponent } from './pay-pal-payment/cloud-payment/cloud-payment.component';
import {MatDialogModule} from '@angular/material/dialog';
import { LoginErrorComponent } from './login-error/login-error.component';
import { DownloadAppPopupComponentModule } from './download-app-popup/download-app-popup-component.module';
import { SocialLoginModule, SocialAuthServiceConfig,  GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import {environment} from './environment';
import { AppleLoginProvider } from '../apple.provider';
import { NgxStripeModule } from 'ngx-stripe';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PayPalPaymentComponent,
    ForgotpasswordComponent,
    RoboPaymentStatusComponent,
    CloudPaymentComponent,
    SafeHtmlnewPipe,
    ReplaceloginPipe,
    LoginErrorComponent
  ],
  imports: [
    NgxPayPalModule,
    ImageCropperModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutes,
    SocialLoginModule,
    RouterModule,
    ViewsModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    DeviceDetectorModule.forRoot(),
    DndModule.forRoot(),
    MatDialogModule,
    DownloadAppPopupComponentModule,
    NgxStripeModule.forRoot(environment.StripePublishableKey),
  ],
  entryComponents: [
    LoginErrorComponent
  ],
  exports: [
    DndModule
  ],
  providers: [AuthenticationService,
    EncrDecrService, Authorizeheader, DatePipe, Md5, LanguageService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleOAuthClientId)
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookAppId)
          },
          {
            id: AppleLoginProvider.PROVIDER_ID,
            provider: new AppleLoginProvider(environment.appleId, 'name email', environment.REDIRECT_API_URL),
          }
        ]
      } as SocialAuthServiceConfig,
    }    
  ],

  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],

})
export class AppModule { }
