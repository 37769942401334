import { Injectable } from '@angular/core';
import language from '../../assets/language.json';
import { Http } from '@angular/http';
import { environment } from '../environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  languageData = []
  constructor(private http: Http) {
  }
  getJSON() {
  }

  getTranslations() {
    return new Promise((resolve) => {
     resolve(this.http.get(environment.baseUrl + 'GeneralSetting/GetTranslations').toPromise())
    })
  }
  getCountry() {
    return new Promise((resolve) => {
     resolve(this.http.get('https://api.ipregistry.co/?key=tryout').toPromise())
    })
  }

  languageHeader() {
    let data
    data = localStorage.getItem('language');
    if ('Rus' === data) {
      return 'ru'
    } else if ('Eng' === data) {

      return 'en'
    } else if ('Ita' === data) {
      return 'it'
    } else {
      return 'ru'
    }
  }
  language(data) {
    debugger
    return new Promise((resolve) => {
      if (this.languageData.length === 0) {
        this.getTranslations().then(response => {
          this.languageData = JSON.parse(response['_body']);

          if ('Rus' === data) {
            resolve(this.languageData[0]);
          } else if ('Eng' === data) {

            resolve(this.languageData[1]);
          } else if ('Ita' === data) {
            resolve(this.languageData[2]);
          } else {
            resolve(this.languageData[0]);
          }
        }, error => {
          this.languageData = language
        })
      } else {
        if ('Rus' === data) {
          resolve(this.languageData[0]);
        } else if ('Eng' === data) {

          resolve(this.languageData[1]);
        } else if ('Ita' === data) {
          resolve(this.languageData[2]);
        } else {
          resolve(this.languageData[0]);
        }
      }
    })
  }
}
