import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'exerciseSearch'
})
export class ExerciseSearchPipe implements PipeTransform {

  transform(items: any[], args:String): any {
    if(!args) {
      return items;
    }   
    return items.filter(item => item.exerciseName.toLowerCase().indexOf(args.toLowerCase()) !== -1 || item.exerciseDescription.toLowerCase().indexOf(args.toLowerCase()) !== -1);
   
}
  }


