import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { MarathonService } from '../../services/marathon.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { PlatformLocation } from '@angular/common';
import { Location } from '@angular/common';
import { AuthenticationService } from 'app/services/authentication.service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-user-daily-exercise',
  templateUrl: './user-daily-exercise.component.html',
  styleUrls: ['./user-daily-exercise.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserDailyExerciseComponent implements OnInit {
  public show;
  public iserror;
  daysGreatExtension = [];
  finalMessageDataGreatExtension = [];
  weeksGreatExtension = [];
  public slideImage = 0;
  public exercise_daily_error;
  day_exercise_Data: any;
  pagehead: boolean
  day: string;
  daynew: any[] = [];
  today: number = Date.now();
  title: string;
  day_of_plan = true;
  public weeks = [];
  public days = [];
  public revsarray = [];
  daysTest = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
  startDate: string;
  local: boolean;
  server: boolean;
  checkModel: true;
  picIndex = 1;
  marathonDayPlan: boolean;
  showdayPlan: boolean;
  dayplanTab: boolean;
  public userNotification = [];
  exerciseOpen: boolean;
  notificationCount: any;
  windowclose: any;
  responce: any;
  finalMessageData = [];
  split: any;
  newId: any;
  datashoe: any[];
  checkfinalmessages: boolean;
  isActive: boolean;
  Subtitle: any;
  title1: any;
  languageType: any;
  showdropdowemenu: boolean;
  getComment: any;
  uploaded: any;
  langCode: string
  extension: string;
  marathonId: string;
  isContest: string;
  isCourse: string
  isGreatExtension: string;
  showfinalmessgas: boolean;
  showtime: boolean;
  sampledataGreatExtension: any;
  firstDayIdGreatExtension: any;
  daysOfWeekGreatExtension = [];
  dayGreatExtension = [];
  distance: number;
  daysnew: number;
  hours: number;
  minutes: number;
  seconds: number;
  countown: boolean;
  now: number;
  countDownDate: number;
  splitString: any;
  MarathonName: any;
  sampledata: any;
  firstDayId: any;
  daysOfWeek: any[] = [];
  error: any;
  paymet: any;
  finalDescription: any;
  description: any;
  day_exercise_Data_All: any;
  finalMessageDate: any;
  currentBlog: any;
  click: boolean;
  showandHideDayPlan: boolean;
  onexerciseStatus: any[] = [];
  showDescription: boolean;
  getSubscriptionDetails: any
  constructor(private language: LanguageService, private authenticationService: AuthenticationService, private platformLocation: PlatformLocation, private _MarathonService: MarathonService, private activeRoute: ActivatedRoute, private location: Location, private sanitizer: DomSanitizer, private router: Router) {
    // platformLocation.onPopState(() => {
    //   this.router.navigateByUrl('/user_marathon_day_exercise/' + this.activeRoute.snapshot.params.marathonID + '/' + this.activeRoute.snapshot.params.id);
    //   history.forward();
    // })
  }
  onHidePopup() {
    this.windowclose = false
  }

  ngOnInit() {
    this.showandHideDayPlan = true;
    this.isCourse = localStorage.getItem('isCourse');
    this.extension = localStorage.getItem('extension');
    this.isContest = localStorage.getItem('isContest');
    this.marathonId = localStorage.getItem('marathonid');

    var data = localStorage.getItem('language');
    if (data != null) {
      this.langCode = data;
      this.language.language(data).then((response) => {
        this.languageType = response
      });
    } else {
      this.langCode = 'Rus';
      this.language.language('Rus').then((response) => {
        this.languageType = response
      });
    }
    this.windowclose = localStorage.getItem('windowclose');
    this.show = true;
    /**acess exrcise according to user day */
    this.day = localStorage.getItem('day_no');
    this.title = localStorage.getItem('Marathontitle');
    this.startDate = localStorage.getItem('startDate');
    localStorage.setItem('day_id', this.activeRoute.snapshot.params.id);
    this.getCurrentDayData(this.activeRoute.snapshot.params.id);
    if (location.origin.includes('localhost:4200') || location.origin.includes('localhost:5000')) {
      this.local = true;
      this.server = false;
    } else if (location.origin.includes('157.230.240.176')) {
      this.server = true;
      this.local = false;
    }
   
  }
  ChangecontestParticpentStatus(event, descriptionValue) {
    this.show = true;
    let data = {
      marathonId: this.marathonId,
      isContestParticipated: event
    }
    this._MarathonService.particpentStatus(data).then(response => {
      this.show = false;
      descriptionValue.isContestParticipated = event;
    }, error => {
      this.show = false;
    })
  }
  english() {
    localStorage.setItem('language', 'Eng');
    this.langCode = 'Eng';
    this.language.language('Eng').then((response) => {
      this.languageType = response
    });
  }

  russian() {
    localStorage.setItem('language', 'Rus');
    this.langCode = 'Rus';
    this.language.language('Rus').then((response) => {
      this.languageType = response
    });
  }
  italian() {
    localStorage.setItem('language', 'Ita');
    this.langCode = 'Ita'
    this.language.language('Ita').then((response) => {
      this.languageType = response
    });
  }

  ifValidImage(filePath) {
    if (filePath.toLowerCase().includes('.png') || filePath.toLowerCase().includes('.jpg') || filePath.toLowerCase().includes('.jpeg') || filePath.toLowerCase().includes('.gif'))
      return true;
    else
      return false;
  }
  imageSlider(event) {
    if (event === 'next') {
      ++this.slideImage;
    } else {
      --this.slideImage;
    }
    this.slideImage = this.slideImage;
  }


  dayPlanDetail(array, childIndex, parentIndex, _notification) {
    this.exerciseOpen = false;
    this.userNotification = [];
    _notification = null;
    for (let j = 0; j < array.length; j++) {
      for (let k = 0; k < array[j].exercises.length; k++) {
        if (childIndex === k && parentIndex === j) {
          array[parentIndex].exercises[k]['marathonDayPlan'] = !array[parentIndex].exercises[k]['marathonDayPlan']
          array[j].exercises[k].notificationCount = null
          this.notificationRead(array[parentIndex].exercises[k].id)
        } else {
          array[j].exercises[k]['marathonDayPlan'] = false
        }
      }
    }
  }

  notificationRead(id) {
    let data = {
      marathonId: this.activeRoute.snapshot.params.marathonID,
      dayId: this.activeRoute.snapshot.params.id,
      exerciseId: id
    }
  }
  moveUp() {
    window.scrollBy(0, -40000);
  }
  goToDayDetailByClick(id, event) {
    this.showandHideDayPlan = true;
    if (this.weeksGreatExtension.length > 0) {
      this.currentBlog = event;
    }
    this.pagehead = false;
    this.click = true;
    this.router.navigateByUrl('/user_marathon_day_exercise/' + this.activeRoute.snapshot.params.marathonID + '/' + id);
    this.getCurrentDayData(id);
  }

  changeExerciseStatus(event, index) {
    let data = {};
    data['marathonExerciseId'] = event.marathonExerciseId;
    data['dayId'] = this.activeRoute.snapshot.params.id;
    data['status'] = !event.isDone;
    // this.show = true;
    this.onexerciseStatus.push(event.marathonExerciseId + index);
    if (this.day_exercise_Data_All.productType === 'Course') {
      this._MarathonService.setUserCourseExerciseStatus(data).then(response => {
        if (this.onexerciseStatus.indexOf(event.marathonExerciseId + index) !== -1) {
          this.onexerciseStatus.splice(this.onexerciseStatus.indexOf(event.marathonExerciseId + index), 1)
        }
        this.show = false;
        event.isDone = !event.isDone;
      }, error => {
        if (this.onexerciseStatus.indexOf(event.marathonExerciseId + index) !== -1) {
          this.onexerciseStatus.splice(this.onexerciseStatus.indexOf(event.marathonExerciseId + index), 1)
        }
        // this.onexerciseStatus = '';
        let status = this.authenticationService.errormessag(error);
        this.show = false;
        if (status.showError) {
          this.show = false;
          this.iserror = true;
          this.exercise_daily_error = status.errormessag;
        }
      })
    }    if (this.day_exercise_Data_All.productType === 'GXCourse') {
      this._MarathonService.setUserGXCourseExerciseStatus(data).then(response => {
        if (this.onexerciseStatus.indexOf(event.marathonExerciseId + index) !== -1) {
          this.onexerciseStatus.splice(this.onexerciseStatus.indexOf(event.marathonExerciseId + index), 1)
        }
        this.show = false;
        event.isDone = !event.isDone;
      }, error => {
        if (this.onexerciseStatus.indexOf(event.marathonExerciseId + index) !== -1) {
          this.onexerciseStatus.splice(this.onexerciseStatus.indexOf(event.marathonExerciseId + index), 1)
        }
        // this.onexerciseStatus = '';
        let status = this.authenticationService.errormessag(error);
        this.show = false;
        if (status.showError) {
          this.show = false;
          this.iserror = true;
          this.exercise_daily_error = status.errormessag;
        }
      })
    } else {
      this._MarathonService.exerciseStatus(data).then(response => {
        if (this.onexerciseStatus.indexOf(event.marathonExerciseId + index) !== -1) {
          this.onexerciseStatus.splice(this.onexerciseStatus.indexOf(event.marathonExerciseId + index), 1)
        }
        this.show = false;
        event.isDone = !event.isDone;
      }, error => {
        if (this.onexerciseStatus.indexOf(event.marathonExerciseId + index) !== -1) {
          this.onexerciseStatus.splice(this.onexerciseStatus.indexOf(event.marathonExerciseId + index), 1)
        }
        // this.onexerciseStatus = '';
        let status = this.authenticationService.errormessag(error);
        this.show = false;
        if (status.showError) {
          this.show = false;
          this.iserror = true;
          this.exercise_daily_error = status.errormessag;
        }
      })
    }
  }

  getTrustedUrl(event) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(event);
  }

  goToPayment() {
    localStorage.setItem('extension', this.day_exercise_Data.isExtension);
    localStorage.setItem('Marathontitle', this.day_exercise_Data.title);
    localStorage.setItem('marthonidorder', this.day_exercise_Data.greatExtensionId);
    localStorage.setItem('cost', this.day_exercise_Data.cost);
    this.router.navigateByUrl('/payment');
  }
  getCurrentDayData(id) {
    this.checkfinalmessages = false
    this.showDescription = false;
    this.pagehead = false
    this.show = true
    this.weeks = [];
    this.days = [];
    this.weeksGreatExtension = [];
    this.daysGreatExtension = [];
    this._MarathonService.getUserDayExercise(id, this.activeRoute.snapshot.params.marathonID).then(response => {
      this.iserror = false;
      this.show = false; response.json();
      this.day_exercise_Data_All = response.json();
      if ((this.day_exercise_Data_All.productType === 'GreatExtension' || this.day_exercise_Data_All.productType === 'Course') && this.day_exercise_Data_All.oldGreatExtensions) {
        this.day_exercise_Data_All.oldGreatExtensions.reverse();
      }
      if (this.day_exercise_Data_All.productType === 'Course') {
      
        this._MarathonService.GetSubscriptionDetails(this.marathonId).then(response => {
          if (response['_body'] !== '') {
            this.getSubscriptionDetails = response.json();
          }
    
        }, error => {
          const status = this.authenticationService.errormessag(error);
          if (status.showError) {
            this.show = false;
            this.iserror = true;
            this.error = status.errormessag;
          }
        })
      }
      let res = response.json();
      this.day_exercise_Data = res['marathonDay'];
      if ((this.day_exercise_Data.productType === 'GreatExtension') && (this.day_exercise_Data.description === null || this.day_exercise_Data.description === '')) {
        this.showDescription = true;
      }

      if (this.day_exercise_Data_All.leftDays <= 3
        && this.day_exercise_Data_All.productType === 'GreatExtension') {
        if (this.day_exercise_Data_All.marathonDay.day === 28) {
          this.day_exercise_Data.greatExtensionDays.push({ leftDays: 2, isEmpty: true })
        }
      }
      if (this.day_exercise_Data.marathonFinalMessages != null) {
        for (let index = 0; index < this.day_exercise_Data.marathonDays.length; index++) {
          var a = this.day_exercise_Data.marathonDays.length - 1;
          if (this.day_exercise_Data.marathonDays[a].isActive === true) {
            this.showtime = false;
            this.showfinalmessgas = false;
            break
          } else {
            this.showfinalmessgas = false;
            this.showtime = true;
            if (a === index) {
              if (this.day_exercise_Data.marathonDays[a].isActive === false) {
                var b = this.day_exercise_Data.marathonFinalMessages.length - 1;
                if (b === 0) {
                  this.showfinalmessgas = true;
                  if (!this.click && a !== index) {
                    setTimeout(() => {
                      document.getElementById('finamessageid2').click();
                    }, 100);
                  }

                } else if (b === 1) {
                  this.showfinalmessgas = true;
                  if (!this.click && a !== index) {
                    setTimeout(() => {
                      document.getElementById('finamessageid1').click();
                    }, 100);
                  }
                } else if (b === 2) {
                  this.showfinalmessgas = true;
                  if (!this.click && a !== index) {
                    setTimeout(() => {
                      document.getElementById('finamessageid0').click();
                    }, 100);
                  }
                }
              }
            }
          }
        }
      }
      this.finalMessageData = [];
      if (
        (this.day_exercise_Data_All['productType'] === 'Marathon')
        && this.day_exercise_Data['marathonFinalMessages'] != null) {
        this.finalMessageData = [];
        for (let i = 0; i < this.day_exercise_Data['marathonFinalMessages'].length; i++) {
          if (!this.day_exercise_Data.isExtension) {
            this.finalMessageData.push(this.day_exercise_Data['marathonFinalMessages'][i]);
          } else {
            if (this.day_exercise_Data.leftDays != null && this.day_exercise_Data.leftDays <= 3) {
              if (i !== 1) {
                this.finalMessageData.push(this.day_exercise_Data['marathonFinalMessages'][i]);
              }
            } else {
              this.finalMessageData.push(this.day_exercise_Data['marathonFinalMessages'][i]);
            }
          }
        }
      }
      if (this.day_exercise_Data_All['productType'] === 'GreatExtension'
        && this.day_exercise_Data['marathonFinalMessages'] != null) {
        this.finalMessageData = [];
        for (let i = 0; i < this.day_exercise_Data['marathonFinalMessages'].length; i++) {
          if (this.day_exercise_Data.leftDays != null) {
            if (i !== 1) {
              this.finalMessageData.push(this.day_exercise_Data['marathonFinalMessages'][i]);
            }
          } else {
            this.finalMessageData.push(this.day_exercise_Data['marathonFinalMessages'][i]);
          }
        }
      }

      if (this.day_exercise_Data_All['productType'] === 'Extension'
        && this.day_exercise_Data['marathonFinalMessages'] != null) {
        this.finalMessageData = [];
        for (let i = 0; i < this.day_exercise_Data['marathonFinalMessages'].length; i++) {
          if (this.day_exercise_Data.leftDays != null && this.day_exercise_Data['marathonFinalMessages'].length == 3) {
            if (i !== 1) {
              this.finalMessageData.push(this.day_exercise_Data['marathonFinalMessages'][i]);
            }
          } else {
            this.finalMessageData.push(this.day_exercise_Data['marathonFinalMessages'][i]);
          }
        }
      }
      if (this.day_exercise_Data.marathonDays != null && this.day_exercise_Data.marathonDays.length !== 0) {
        let startColCount = 2;
        let colPerRow = 3;
        for (let i = 0; i < this.day_exercise_Data.marathonDays.length; i++) {
          this.days.push(this.day_exercise_Data.marathonDays[i]);
          if ((i + 1) % 7 === 0) {
            this.weeks.push(this.days);
            this.days = [];
          }
        }
        if (this.days.length) {
          this.weeks.push(this.days);
        }
        this.weeks.reverse()
        for (let j = 0; j < this.weeks.length; j++) {
          this.weeks[j].reverse();
        }

        let lastWeekdaysCount = this.weeks[0].length;
        let lastWeektotalColCount = lastWeekdaysCount + startColCount;
        let lastWeektotalRow = Math.ceil((lastWeektotalColCount / colPerRow));
        let lastWeekemptyCol = (lastWeektotalRow * colPerRow) - lastWeektotalColCount;
        for (let k = 0; k < lastWeekemptyCol; k++) {
          // if (this.day_exercise_Data_All.leftDays > 3 && (this.day_exercise_Data.productType === 'Marathon' || this.day_exercise_Data.productType === 'Course')) {
          this.weeks[0].unshift({ isEmpty: true });
          // }
        }
        this.split = this.day_exercise_Data.startDate;
        let fcolPerRow = 3;
        let fstartColCount = 3;
        if (this.finalMessageData != null) {
          let flastWeekdaysCount = this.finalMessageData.length;
          let flastWeektotalColCount = flastWeekdaysCount + fstartColCount;
          let flastWeektotalRow = Math.ceil((flastWeektotalColCount / fcolPerRow));
          let flastWeekemptyCol = (flastWeektotalRow * fcolPerRow) - flastWeektotalColCount;
          for (let k = 0; k < flastWeekemptyCol; k++) {
            // if (this.day_exercise_Data.leftDays > 3 && (this.day_exercise_Data.productType === 'Marathon' || this.day_exercise_Data.productType === 'Course')) {
            if (this.day_exercise_Data_All['productType'] != 'GreatExtension' && this.day_exercise_Data_All['productType'] != 'Extension') {
              this.finalMessageData.unshift({ isEmpty: true });
            }
            // }
          }
          this.finalMessageData.sort((a, b) => {
            return <any>(b.order) - <any>(a.order);
          });
        }
        let startColCountGreatExtension = 2;
        let colPerRowGreatExtension = 3;
        // for (let i = 0; i < this.day_exercise_Data.greatExtensionDays.length; i++) {
        //   this.daysGreatExtension.push(this.day_exercise_Data.greatExtensionDays[i]);
        // change condition for SSC 
        if (this.day_exercise_Data_All['productType'] === 'GreatExtension') {
          for (let i = 0; i < this.day_exercise_Data.greatExtensionDays.length; i++) {
            this.daysGreatExtension.push(this.day_exercise_Data.greatExtensionDays[i]);
            if ((i + 1) % 7 === 0) {
              this.weeksGreatExtension.push(this.daysGreatExtension);
              this.daysGreatExtension = [];
            }
          }
        } else  if (this.day_exercise_Data_All['productType'] === 'Course'){
          // shiv ne change krai hai Course k lia
          if( this.day_exercise_Data.greatExtensionDays){
          for (let i = 0; i < this.day_exercise_Data.greatExtensionDays.length; i++) {
            this.daysGreatExtension.push(this.day_exercise_Data.greatExtensionDays[i]);
            if ((i + 1) % 7 === 0) {
              this.weeksGreatExtension.push(this.daysGreatExtension);
              this.daysGreatExtension = [];
            }
          }
        }
        }        else {
          if( this.day_exercise_Data_All.greatExtensionDays){
          for (let i = 0; i < this.day_exercise_Data_All.greatExtensionDays.length; i++) {
            this.daysGreatExtension.push(this.day_exercise_Data_All.greatExtensionDays[i]);
            if ((i + 1) % 7 === 0) {
              this.weeksGreatExtension.push(this.daysGreatExtension);
              this.daysGreatExtension = [];
            }
          }
        }
        }
        if (this.daysGreatExtension.length) {
          this.weeksGreatExtension.push(this.daysGreatExtension);
        }
        this.weeksGreatExtension.reverse();
        for (let j = 0; j < this.weeksGreatExtension.length; j++) {
          this.weeksGreatExtension[j].reverse();
        }
        if (this.weeksGreatExtension.length > 0) {
          let lastWeekdaysCountGreatExtension = this.weeksGreatExtension[0].length;
          let lastWeektotalColCountGreatExtension = lastWeekdaysCountGreatExtension + startColCountGreatExtension;
          let lastWeektotalRowGreatExtension = Math.ceil((lastWeektotalColCountGreatExtension / colPerRowGreatExtension));
          let lastWeekemptyColGreatExtension = (lastWeektotalRowGreatExtension * colPerRowGreatExtension) - lastWeektotalColCountGreatExtension;
          // if (this.day_exercise_Data_All.leftDays <= 3 && lastWeekemptyColGreatExtension === 1 && this.day_exercise_Data_All.productType === 'GreatExtension') {
          //   lastWeekemptyColGreatExtension = 0;
          // } else if (this.day_exercise_Data_All.leftDays <= 3 && lastWeekemptyColGreatExtension === 0 && this.day_exercise_Data_All.productType === 'GreatExtension') {
          //   lastWeekemptyColGreatExtension = 2;
          // } else if (this.day_exercise_Data_All.leftDays <= 3 && lastWeekemptyColGreatExtension === 2 && this.day_exercise_Data_All.productType === 'GreatExtension') {
          //   lastWeekemptyColGreatExtension = 1;
          // }

          for (let k = 0; k < lastWeekemptyColGreatExtension; k++) {
            this.weeksGreatExtension[0].unshift({ isEmpty: true });
          }
          this.split = this.day_exercise_Data.startDate;
        }
      }
      if (!this.day_exercise_Data.isMarathonStart) {
        setInterval(() => {
          this.timer();
        }, 1000);
      } else {
        this.showtime = true;
      }
      this.sampledata = this.day_exercise_Data.marathonDays;
      this.sampledataGreatExtension = this.day_exercise_Data.greatExtensionDays;
      if (this.day_exercise_Data.marathonDays != null || this.day_exercise_Data.greatExtensionDays != null) {
        let startColCount = 2;
        let colPerRow = 3;
        localStorage.setItem('marathon_name', this.day_exercise_Data.title);
        this.firstDayId = this.sampledata[this.sampledata.length - 1].id;
        for (let i = 0; i < this.sampledata.length; i++) {
          this.daysOfWeek.push(this.sampledata[i]);
          if ((i + 1) % 7 === 0) {
            this.daynew.push(this.daysOfWeek);
            this.daysOfWeek = [];
          }
        }

        if (this.daysOfWeek.length) {
          this.daynew.push(this.daysOfWeek);
        }
        this.daynew.reverse()
        for (let j = 0; j < this.daynew.length; j++) {
          this.daynew[j].reverse()
        }
        let lastWeekdaysCount = this.daynew[0].length;
        let lastWeektotalColCount = lastWeekdaysCount + startColCount;
        let lastWeektotalRow = Math.ceil((lastWeektotalColCount / colPerRow));
        let lastWeekemptyCol = (lastWeektotalRow * colPerRow) - lastWeektotalColCount;
        for (let k = 0; k < lastWeekemptyCol; k++) {
          this.daynew[0].unshift({ isEmpty: true })
        }
        this.split = this.day_exercise_Data.startDate;
        let fcolPerRow = 3;
        let fstartColCount = 3;

        if (this.finalMessageData != null) {
          let flastWeekdaysCount = this.finalMessageData.length;
          let flastWeektotalColCount = flastWeekdaysCount + fstartColCount;
          let flastWeektotalRow = Math.ceil((flastWeektotalColCount / fcolPerRow));
          let flastWeekemptyCol = (flastWeektotalRow * fcolPerRow) - flastWeektotalColCount;
          for (let k = 0; k < flastWeekemptyCol; k++) {
            if (this.day_exercise_Data.leftDays > 3 && this.day_exercise_Data_All['productType'] != 'GreatExtension') {
              this.finalMessageData.unshift({ isEmpty: true });
            }
          }
        }

        if (this.finalMessageData != null) {
          this.finalMessageData.sort((a, b) => {
            return <any>(b.order) - <any>(a.order);
          });
        }
       
        if (this.day_exercise_Data.greatExtensionDays != null && this.day_exercise_Data.greatExtensionDays.length !== 0) {
          let startColCountGreatExtension = 2;
          let colPerRowGreatExtension = 3;
          localStorage.setItem('marathon_name', this.day_exercise_Data.title);
          this.firstDayIdGreatExtension = this.sampledataGreatExtension[this.sampledataGreatExtension.length - 1].id;
          for (let i = 0; i < this.sampledataGreatExtension.length; i++) {
            this.daysOfWeekGreatExtension.push(this.sampledataGreatExtension[i]);
            if ((i + 1) % 7 === 0) {
              this.dayGreatExtension.push(this.daysOfWeekGreatExtension);
              this.daysOfWeekGreatExtension = [];
            }
          }
          if (this.daysOfWeekGreatExtension.length) {
            this.dayGreatExtension.push(this.daysOfWeekGreatExtension);
          }

          this.dayGreatExtension.reverse()
          for (let j = 0; j < this.dayGreatExtension.length; j++) {
            this.dayGreatExtension[j].reverse();
          }
          let lastWeekdaysCountGreatExtension = this.dayGreatExtension[0].length;
          let lastWeektotalColCountGreatExtension = lastWeekdaysCountGreatExtension + startColCountGreatExtension;
          let lastWeektotalRowGreatExtension = Math.ceil((lastWeektotalColCountGreatExtension / colPerRowGreatExtension));
          let lastWeekemptyColGreatExtension = (lastWeektotalRowGreatExtension * colPerRowGreatExtension) - lastWeektotalColCountGreatExtension;
          for (let k = 0; k < lastWeekemptyColGreatExtension; k++) {
            this.dayGreatExtension[0].unshift({ isEmpty: true })
          }
          this.split = this.day_exercise_Data.startDate;
        }
      }
      if (this.day_exercise_Data.greatExtensionDays != null && this.day_exercise_Data.greatExtensionDays.length != 0 && this.day_exercise_Data.productType === 'Course') {
        for (let indexr = 0; indexr < this.day_exercise_Data.greatExtensionDays.length; indexr++) {
          if (this.activeRoute.snapshot.params.id === this.day_exercise_Data.greatExtensionDays[indexr].id) {
            if ((this.day_exercise_Data.description === null || this.day_exercise_Data.description === '')) {
              this.showDescription = true;
            }
          }

        }
      }
      this.getVideoUrl()
      setTimeout(() => {
      if(this.checkGXbutton() &&  this.finalMessageData != null && this.finalMessageData.length == 3){
        this.finalMessageData.splice(1, 1);
      }
    }, 1000);
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        this.exercise_daily_error = status.errormessag;
      }
    });
  }
  checkGXbutton(){
    let geAceess = localStorage.getItem('geAceess');
    if(parseInt(geAceess) > 0 && this.day_exercise_Data_All.productType == 'Marathon' ){
      return true;
    }else{
      return false
    }
  }
  checkExtendedExtensionStartDate(){
    let NFDLD = new Date(this.day_exercise_Data_All.extendedExtensionStartDate);
    let newFDLD = (NFDLD.setDate(NFDLD.getDate())) / 1000;
    let NDLD = new Date();
    let newNDLD = (NDLD.setDate(NDLD.getDate())) / 1000;
    if (newFDLD <= newNDLD) {
      return true;
    }else{
      return  false;
    }
  }
  timer() {
    // Update the count down every 1 second
    this.splitString = this.day_exercise_Data.startDate;
    this.countDownDate = new Date(this.splitString).getTime();
    this.MarathonName = this.day_exercise_Data.title;
    // Get todays date and time
    this.now = new Date().getTime();
    // Find the distance between now and the count down date
    this.distance = this.countDownDate - this.now;
    // Time calculations for days, hours, minutes and seconds
    this.daysnew = Math.floor(this.distance / (1000 * 60 * 60 * 24));
    this.hours = Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.minutes = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60));
    this.seconds = Math.floor((this.distance % (1000 * 60)) / 1000);
    this.countown = true
  }
  purchaseMarathon(id, cost, item) {
    localStorage.setItem('extension', item.isExtension);
    localStorage.setItem('Marathontitle', item.title);
    localStorage.setItem('marthonidorder', id);
    localStorage.setItem('cost', cost)
    this.router.navigateByUrl('/payment');
  }

  /**get marathon exercise detail by day */
  getMarathonExercise(id, day) {
    this.router.navigateByUrl('/user_marathon_day_exercise/' + this.activeRoute.snapshot.params.id + '/' + id);
  }

  goToUserLandingPage() {
    this.router.navigateByUrl('/marathon_exercise/' + this.activeRoute.snapshot.params.marathonID);
  }

  finalMessageshow(array, index) {
    this.showandHideDayPlan = false
    this.title1 = array[index].title;
    this.Subtitle = array[index].subTitle;
    this.pagehead = true;
    this.checkfinalmessages = true;
    this.showDescription = false;
    this.isActive = true;
    this.datashoe = this.finalMessageData[index].finalMessage;
    for (let i = 0; i < array.length; i++) {
      if (i === index) {
        this.finalMessageDate = array[index]['showDate'].split('T')
        array[index]['IsActive'] = true;
      } else {
        array[i]['IsActive'] = false;
      }
    }
  }

  splitDate(str) {
    let date = str.split('T')
    return date[0]
  }
  minTwoDigits(n) {
    return (n < 10 ? '0' : '') + n;
  }
  checkUrl(str) {
    if (str != null && str !== undefined && str !== '') {
      if (str.changingThisBreaksApplicationSecurity !== undefined) {
        var string = str.changingThisBreaksApplicationSecurity;
        var n = string.includes('vimeo');
        return n
      } else {
        var string = str;
        var n = string.includes('vimeo');
        return n
      }
    }
  }

  goToDayDetail(item, array, index) {
    this.exerciseOpen = true
    // this.showandHideDayPlan = true;
    for (let i = 0; i < array.length; i++) {
      if (i === index) {
        array[i]['showdayPlan'] = true;
      }
      else {
        array[i]['showdayPlan'] = false;
      }
    }
    this.showdayPlan = true;
    localStorage.setItem('day', this.day_exercise_Data.day);
    localStorage.setItem('day_id', this.activeRoute.snapshot.params.id);
  }


  getVideoUrl() {
    for (let k = 0; k < this.day_exercise_Data.exercises.length; k++) {
      for (let l = 0; l < this.day_exercise_Data.exercises[k].exerciseContents.length; l++) {
        this.day_exercise_Data.exercises[k].exerciseContents[l]['video_url'] = this.videoSafeUrl(this.day_exercise_Data.exercises[k].exerciseContents[l].contentPath);
      }
    }
  }

  /**check video url validate or not!! */
  videoSafeUrl(url) {
    if (url !== undefined) {
      if (url.split('/')[2] === 'youtu.be') {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url.replace('youtu.be', 'www.youtube.com/embed/'));
      } else if (url.split('/')[2] === 'www.youtube.com' || url.split('/')[2] === 'player.vimeo.com') {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url.replace('watch?v=', 'embed/'));
      }
    }
  }
  showDayOfPlan() {
    this.day_of_plan = !this.day_of_plan;
  }
  startdatechange(date) {
    if (date !== null && date !== undefined && this.languageType !== undefined) {
      if (this.languageType.language.language === 'Eng') {
        var datearray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      } else if (this.languageType.language.language === 'Ita') {
        var datearray = ['gennaio', 'febbraio', 'marcia', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'];
      } else {
        var datearray = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
      }
      if (date.indexOf('-') === -1 && date !== undefined) {
        let newdate = date.split('/');
        return datearray[newdate[0] - 1];
      } else {
        let newdate = date.split('-');
        return datearray[newdate[1] - 1];
      }
    }
  }

  dayOfWeekInRussian(dayNumber: any) {
    dayNumber;
    if (dayNumber !== undefined && this.languageType !== undefined) {
      if (this.languageType.language.language === 'Eng') {
        var d = new Date(dayNumber);
        var weekday = new Array(7);
        weekday[0] = 'Sunday';
        weekday[1] = 'Monday';
        weekday[2] = 'Tuesday';
        weekday[3] = 'Wednesday';
        weekday[4] = 'Thursday';
        weekday[5] = 'Friday';
        weekday[6] = 'Saturday';

        return weekday[d.getDay()];
      } else if (this.languageType.language.language === 'Ita') {
        var d = new Date(dayNumber);
        var weekday = new Array(7);
        weekday[0] = 'domenica';
        weekday[1] = 'lunedi';
        weekday[2] = 'martedì';
        weekday[3] = 'medio';
        weekday[4] = 'giovedi';
        weekday[5] = 'venerdì';
        weekday[6] = 'sabato';

        return weekday[d.getDay()];
      } else {
        var d = new Date(dayNumber);
        var weekday = new Array(7);
        weekday[0] = 'воскресенье';
        weekday[1] = 'понедельник';
        weekday[2] = 'вторник';
        weekday[3] = 'среда';
        weekday[4] = 'четверг';
        weekday[5] = 'пятница';
        weekday[6] = 'суббота';

        return weekday[d.getDay()];
      }
    }
  }

  uploadComplete() {
    this.uploaded.emit();
  }
  scrollDown() {
    window.scrollBy(0, 40000);
  }

  showdropdowe() {
    this.showdropdowemenu = !this.showdropdowemenu;
  }

  logout() {
    localStorage.clear()
    this.router.navigateByUrl('/register/userlogin');
  }

  changePassword() {
    localStorage.setItem('url', this.router.url)
    this.router.navigateByUrl('/changepassword');
  }
  notifications() {
    localStorage.setItem('url', this.router.url)
    this.router.navigateByUrl('/notifications');
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    this.showdropdowemenu = false;
  }

  contest() {
    this.router.navigateByUrl('/contest');
  }

  contestvote() {
    this.router.navigateByUrl('/contest_vote');
  }

  myOrder() {
    this.router.navigateByUrl('/orderlist');
  }

  russianMonths(month) {
    if (month !== undefined  && this.languageType !== undefined) {
      if (month.indexOf('-') === -1) {
        if (this.languageType.language.language === 'Eng') {
          var monthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        } else if (this.languageType.language.language === 'Ita') {
          var monthArray = ['gennaio', 'febbraio', 'marcia', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'];
        }
        else {
          var monthArray = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        }
        let monthOrder = month.split('/')[0];
        return monthArray[monthOrder - 1];
      } else {
        if (this.languageType.language.language === 'Eng') {
          var monthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        }
        else if (this.languageType.language.language === 'Ita') {
          var monthArray = ['gennaio', 'febbraio', 'marcia', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'];
        }
        else {
          var monthArray = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        }
        let monthOrder = month.split('-')[1];
        return monthArray[monthOrder - 1];
      }
    }
  }
  getPaymentDetails(id) {
    this._MarathonService.PaymentDetail(id).then(response => {
      this.paymet = response.json();
      if (this.paymet.description != null) {
        this.description = this.paymet.description.replace(new RegExp('@greatExtensionButton', 'g'), `<div class="text-center"><button id=\"greatextension\" class="btn btn-modal btn-pink" (click)="purchaseMarathonForPayment(paymet.greatExtensionCost)">
            оплатить ${this.paymet.greatExtensionCost}
          </button></div>`);
        this.finalDescription = this.description.replace(new RegExp('@simpleExtensionButton', 'g'), ` <div class="text-center"><button id=\"simpleExtension\" class="btn btn-modal btn-pink" (click)="purchaseMarathon(paymet.simpleExtensionCost)">
            оплатить ${this.paymet.simpleExtensionCost}
          </button></div>`);
        setTimeout(() => {
          this.great();
        }, 1000);
        setTimeout(() => {
          this.simple();
        }, 1000);
      } else {
        this.finalDescription = null
      }
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        this.error = status.errormessag;
      }
    })
  }
  changeYouHaveCompletedXPracticeCourse(name){
    return this.languageType.marathonStartPage.youHaveCompletedXPracticeCourse.replace(new RegExp('{{practice}}', 'g'), name);
      }
  startGreatExtension() {
    this.show = true;
    this._MarathonService.startGreatExtension(this.activeRoute.snapshot.params.marathonID).then(response => {
      this.show = false;
      let data = response.json();
      this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + this.activeRoute.snapshot.params.id);
      setTimeout(() => {
        this.ngOnInit();  
      }, 100);
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        this.error = status.errormessag;
      }
    })
  }
  great() {
    document.getElementById('greatextension').onclick = () => {
      //do something
      localStorage.setItem('cost', this.paymet.greatExtensionCost);
      localStorage.setItem('marthonidorder', this.paymet.greatExtensionId);
      this.router.navigateByUrl('/payment');
    }
  }

  simple() {
    document.getElementById('simpleExtension').onclick = () => {
      //do something
      localStorage.setItem('marthonidorder', this.paymet.simpleExtensionId);
      localStorage.setItem('cost', this.paymet.simpleExtensionCost);
      this.router.navigateByUrl('/payment');
    }
  }
  checkContestShowAndHindden() {
    if(localStorage.getItem('productType') === 'Marathon'){
      if (localStorage.getItem('accessContest') == 'true') {
        return 'Contest';
      } else {
        return 'Photo diary';
      }
    }else{
      return 'Photo diary';
    }
  }
  addDays(date, days) {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
  }
  changeUnsubscribeSuccessPageDetail(item) {
    if (!this.getSubscriptionDetails) {
      return
    }
    const date = new Date(this.getSubscriptionDetails.startDateIso);

    let newDate = this.addDays(date, 3);
    var dd = newDate.getDate();
    var mm = newDate.getMonth() + 1;
    var y = newDate.getFullYear();

    var courseAccessTillDate = mm + '.' + dd + '.' + y;
    item = item.replace(new RegExp('{{date}}', 'g'), courseAccessTillDate)
    item = item.replace(new RegExp('{{title}}', 'g'), localStorage.getItem('Marathontitle'))
    return item
  }

  subscribeToCourseCloudFirstTime(item) {
    if (item == 'open') {
      return localStorage.getItem('subscribeToCourseCloudFirstTime') == 'Yes' && localStorage.getItem('productTypeCheckForMarathonPurchased') == 'Course' ? true : false;
    } else {
      localStorage.setItem('subscribeToCourseCloudFirstTime', 'No');
    }
  }
}