import { Component, OnInit, ElementRef, ViewEncapsulation, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MarathonService } from '../../services/marathon.service';
import { AuthenticationService } from '../../services/authentication.service';
// import * as $ from 'jquery';

@Component({
  selector: 'app-day-plan',
  templateUrl: './day-plan.component.html',
  styleUrls: ['./day-plan.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DayPlanComponent implements OnInit {
  @Input() data;
  @Input() language;
  @Input() discription
  @Input() index
  public slideImage = 0;
  isShowDiscription: boolean;
  isShowComment: boolean;
  getComment: any;
  public enableViewMore = true
  comment_reply_message: any;
  comment_reply_message_of_reply: any;
  show: boolean;
  isError: boolean;
  user_marathondayerror: any;
  comment_message: any;
  getCommentchild: any;
  commentData: {};
  rowData: any;
  role: string;
  FullName: string;
  splitFullName: string[];
  NameInitial: any;
  showConfirmBox: boolean;
  currentRowData: any;
  currentStatus: any;
  replyData: any;
  childData: any;
  CurrentRowIndex: any;
  commenttype: any;
  currentArray: any;
  bindedVariable: any
  parentIndex: any;
  userName: string;
  userId: string;
  toggleButtonActiveIndex = 0;
  customEmojis = [
    {
      name: 'Octocat',
      shortNames: ['octocat'],
      text: '',
      emoticons: [],
      keywords: ['github'],
      imageUrl: 'https://assets-cdn.github.com/images/icons/emoji/octocat.png?v7'
    },
    {
      name: 'Test Flag',
      shortNames: ['test'],
      text: '',
      emoticons: [],
      keywords: ['test', 'flag'],
      spriteUrl: 'https://unpkg.com/emoji-datasource-twitter@4.0.4/img/twitter/sheets-256/64.png',
      sheet_x: 1,
      sheet_y: 1,
      size: 64,
      sheetColumns: 52,
      sheetRows: 52,
    },
  ]
  senderUser_name: string;
  name: any;
  name_From: string;
  cmt_r: string;
  length = 5;
  lavels: any;
  splitString: any;
  countDownDate: number;
  now: number;
  distance: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  countown: boolean;
  newComment = [];
  filteredComment=[]; 
  listOfQuestions: any;
  listOfAnswer: any;
  isanswer: boolean;
  isMainCommentAcordian: boolean;
  currentActiveIndex: any;
  isComment: string
  activeFaq: any;
  extension: string;
  isLike = false; isOpenAnswerModel: boolean;
  // currentUrlofvideo: any;
  // player: amp.Player;
  autoGeneratedVideosId:any;
  listOfQuestionsAns: any[] =[];
  moreShowDataQAArray: any[] =[];
  constructor(private elRef: ElementRef, private authenticationService: AuthenticationService, private sanitizer: DomSanitizer, private _MarathonService: MarathonService, private activeRoute: ActivatedRoute, ) { }

  ngOnInit() {
    this.autoGeneratedVideosId = Math.random().toString(16).slice(2);
    console.log("data",this.data.exerciseName + '' +this.data.marathonExerciseName);
    
    // this.currentUrlofvideo = "http://miyabiacademy-usct.streaming.media.azure.net/838bfe80-b2e6-48e4-a699-f84581cc876c/v reale vrashenie golovoy.ism/manifest";
    this.extension = localStorage.getItem('extension');
    this.isComment = localStorage.getItem('isComment');
    this.isShowDiscription = this.discription;
    this.activeRoute.snapshot.params;
    this.commentData = {
      pageSize: 5,
      pageIndex: 0
    }
    this.role = localStorage.getItem("role");
    if (this.data)
      this.getCommentList();
    
     // Getting User Id to filter out comment
     this._MarathonService.GetUserProfileDetail()
     .toPromise().then(response => {
       this.userId = response.json().id;
     })  
  }

  likeOnComment(value, currentValue, commentId) {
    let data = {
      commentId: currentValue.id,
      like: value
    }
    this._MarathonService.getCommentLike(data).then(response => {
      currentValue['like'] = !currentValue['like'];
    }, error => {
    })
  }

  scrollHandler(e) {
    var a = this.newComment.length + 100
    for (let index = this.newComment.length; index <= a; index++) {
      if (this.newComment[index] == null) {
        break;
      } else {
        this.newComment.push(this.newComment[index])
      }
    }
  }
  showExerciseDescription() {
    this.isShowDiscription = !this.isShowDiscription
  }
  goToBack() {
    this.listOfQuestions = [];
  }
  isCommentUseful(item, status) {
    this.show = true;
    let data = {
      exerciseFAQId: item.quesionId,
      like: status,
    }
    this._MarathonService.getFaqLike(data).then(response => {
      item['like'] = !item['like'];
      this.show = false;
    }, error => {
      this.show = false;
    })
  }
  moreShowDataQA(id){
    if(this.moreShowDataQAArray.indexOf(id) == -1){
    this.moreShowDataQAArray.push(id)
    }else{
      this.moreShowDataQAArray.splice(this.moreShowDataQAArray.indexOf(id),1)
    }
  }
  isCommentUsefulQA(item, status) {
    this.show = true;
    let data = {
      exerciseFAQId: item.ID,
      like: status,
    }
    this._MarathonService.getFaqLike(data).then(response => {
      item['like'] = !item['like'];
      this.show = false;
    }, error => {
      this.show = false;
    })
  }

  showComments() {
    this.isShowComment = !this.isShowComment
    if (this.getComment.length <= 100) {
      this.newComment = []
      for (let index = 0; index < this.getComment.length; index++) {
        this.newComment.push(this.getComment[index]);
      }
    } else if (this.getComment.length > 100) {
      this.newComment = []
      for (let index = 0; index < 100; index++) {
        if (this.getComment[index] == null) {
          break;
        } else {
          this.newComment.push(this.getComment[index]);
        }
      }
    }
    this.filteredComment = this.newComment; 
  }

  showToggleComments(index: number) {
    this.toggleButtonActiveIndex = index;
    if (index) {
       this.filteredComment = this.newComment.filter(comment => comment.userId === this.userId)
    } else { this.filteredComment = this.newComment; }
  }

  checkData() {
    let a = document.getElementById('iframeId')
  }

  //get name initials
  getNameInitials(name) {
    if (name) {
      this.FullName = name;
      this.splitFullName = this.FullName.split(" ");
      var stringArray = new Array();
      if (this.splitFullName.length > 1) {
        for (var i = 0; i <= 1; i++) {
          stringArray.push(this.splitFullName[i]);
        }
        this.NameInitial = stringArray[0].substring(0, 1) + stringArray[1].substring(0, 1)
      } else {
        this.NameInitial = this.FullName.substring(0, 1)
      }
      return this.NameInitial.toUpperCase();
    }
  }

  openModel() {
    this.isOpenAnswerModel = true
  }
  collapseModel() {
    this.isOpenAnswerModel = !this.isOpenAnswerModel
  }

  /**get all comments list done by users */
  getCommentList() {
    debugger
    this._MarathonService.getMarathoncomment(this.data.id, this.activeRoute.snapshot.params.marathonID, this.extension).then(response => {
      this.getComment = response.json()['items'];
      this.newComment = response.json()['items'];
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status) {
        this.show = false;
        this.isError = true;
        this.user_marathondayerror = error.text();
      }
    });
  }

  addclass(id) {
    document.getElementById(id).classList.remove("vjs-user-inactive");
    document.getElementById(id).classList.add("vjs-user-active");
  }
  setIndex(index, array) {
    array[index]['currentActiveQuestion'] = !array[index]['currentActiveQuestion'];
  }
  suggestion(event, comment, name) {
    // if (event.keyCode == 32 || event.code == "") {
    //   this.listOfQuestions = [];
    //   this._MarathonService.getSuggestion(comment).then(response => {
    //     this.activeFaq = name;
    //     this.listOfQuestions = response.json();
    //     this.isMainCommentAcordian = true;
    //     for (let i = 0; i < this.listOfQuestions.length; i++) {
    //       this.listOfQuestions[i]['currentActiveQuestion'] = false;
    //     }
    //   }, error => {
    //     this.show = false;
    //     this.isError = true;
    //     this.user_marathondayerror = error.text();
    //   })
    // } else if (event.keyCode == 8) {
    //   this.activeFaq = null;
    //   this.listOfQuestions = [];
    // }
    // else {
    //   this.activeFaq = null;
    //   this.listOfQuestions = [];
    // }

  }
  suggestionQA(){
          this._MarathonService.suggestionQA(this.data.exerciseName + ' ' +this.data.marathonExerciseName + ' '+this.comment_message).then(response => {
        this.listOfQuestionsAns = response.json()['value'];
        for (let index = 0; index < this.listOfQuestionsAns.length; index++) {
          this.listOfQuestionsAns[index]['question'] = this.listOfQuestionsAns[index]['question'].replace(new RegExp("&nbsp;", "g"), "").replace(new RegExp('Froala Editor', 'g'), '').replace(new RegExp('Powered by', 'g'), '').replace(new RegExp("&quot;", "g"), "").replace(new RegExp("&gt;", "g"), "").replace(new RegExp("&amp;", "g"), "").replace(new RegExp("&quot;", "g"), "");
          this.listOfQuestionsAns[index]['answer'] = this.listOfQuestionsAns[index]['answer'].replace(new RegExp("&nbsp;", "g"), "").replace(new RegExp('Froala Editor', 'g'), '').replace(new RegExp('Powered by', 'g'), '').replace(new RegExp("&quot;", "g"), "").replace(new RegExp("&gt;", "g"), "").replace(new RegExp("&amp;", "g"), "").replace(new RegExp("&quot;", "g"), "");
        }
      }, error => {
        this.show = false;
        this.isError = true;
        this.user_marathondayerror = error.text();
      })
  }
  closeAcordian() {
    this.listOfQuestions = [];
  }

  getAnswer(event) {
    this.isanswer = !this.isanswer;
  }

  /**show reply of comments */
  onReply(array, index) {
    this.comment_reply_message = null;
    this.name_From = '@' + array[index].userModel.fullName;
    for (let i = 0; i < array.length; i++) {
      if (i == index) {
        array[index]['showReply'] = true;
      } else {
        array[i]['showReply'] = false;
      }
    }
  }

  hideComment(id, index) {
    this.newComment[index]['pageIndex'] = undefined
    for (let j = 0; j < this.newComment.length; j++) {
      if (this.newComment[j].id == id) {
        if (!this.newComment[j]['replyArray']) {
          this.newComment[j]['replyArray'] = [];
          this.newComment[j]['startIndex'] = 0;
          this.newComment[j]['length'] = 5
        }
        this.newComment[j].replyArray = [];
        this.newComment[j]['startIndex'] = 0;
        this.newComment[j]['length'] = 5
      }
    }
  }

  onHidePopup() {
    this.showConfirmBox = false
    this.currentRowData = null
    this.currentStatus = null
  }
  removeCommentOnList(items, status, levels, CurrentRowIndex, commenttype, currentArray, parentIndex) {
    this.show = true;
    this.rowData = items;
    let data = {
      commentId: this.rowData.id,
      status: status
    }
    this._MarathonService.changeMessageStatus(data).then(response => {
      this.show = false;
      this.showConfirmBox = false
      this.isError = false;
      if (levels == 'parent') {
        this.getCommentList()
      } else {
        this.newComment[parentIndex].childCommentCount = this.newComment[parentIndex].childCommentCount - 1
        currentArray.splice(CurrentRowIndex, 1)
      }
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status) {
        this.show = false;
        this.isError = true;
        this.user_marathondayerror = error.text();
      }
    });
  }

  checkUrl(str) {

    if (str != null && str != undefined && str != '') {
      if (str.changingThisBreaksApplicationSecurity != undefined) {
        var string = str.changingThisBreaksApplicationSecurity;
        var n = string.includes("vimeo");
        return n
      } else {
        var string = str;
        var n = string.includes("vimeo");
        return n
      }
    }
  }
  
  stopVideo() {
    let listVemioFrames = document.getElementsByTagName("video");
    for (var index = 0; index < listVemioFrames.length; index++) {
      let vid = <HTMLVideoElement>document.getElementById('iframe$' + index);
      if (vid)
        vid.pause();
    }
    let listaFrames = document.getElementsByTagName("video");
    for (var index = 0; index < listaFrames.length; index++) {
      listaFrames[index].pause();
    }
  }

  // update message status
  updateMessageStatus(items, status, event, childIndex, levels, array, parentIndex) {
    if (event == 'reject') {
      this.showConfirmBox = true;
      this.currentRowData = items;
      this.currentStatus = status;
      this.lavels = levels
      this.currentArray = array
      this.CurrentRowIndex = childIndex
      this.parentIndex = parentIndex
    } else {
      this.show = true;
      this.rowData = items;
      let data = {
        commentId: this.rowData.id,
        status: status
      }
      this._MarathonService.changeMessageStatus(data).then(response => {
        this.show = false;

        this.isError = false;

        this.getCommentList()
      }, error => {
        let status = this.authenticationService.errormessag(error);
        if (status) {
          this.show = false;
          this.isError = true;
          this.user_marathondayerror = error.text();
        }
      });
    }
  }

  // get child comment
  getChildComment(id, index, message) {
    message['show'] = true;
    if (this.newComment[index]['pageIndex'] === undefined) {
      this.newComment[index]['pageIndex'] = 0;
      this.commentData['marathonId'] = this.activeRoute.snapshot.params.marathonID;
      this.commentData['exerciseId'] = this.data.id,
        this.commentData['commentId'] = id,
        this.commentData['pageIndex'] = this.newComment[index]['pageIndex']
      this._MarathonService.getChildCmnt(this.commentData, this.extension).then(response => {
        message['show'] = false
        this.isError = false;
        this.getCommentchild = response.json()['items'];
        let oneDayTimeStamp = 86400
        for (let k = 0; k < this.getCommentchild.length; k++) {
          this.getCommentchild[k]['cmttime'] = new Date(this.getCommentchild[k]['createdDate']).getTime();
          this.getCommentchild[k]['checkTime'] = this.getCommentchild[k]['cmttime'] + 86400;
          if (oneDayTimeStamp > (Math.floor(new Date().getTime() / 1000) - Math.floor(this.getCommentchild[k]['cmttime'] / 1000))) {
            this.getCommentchild[k]['greaterThenDay'] = true;
          } else {
            this.getCommentchild[k]['greaterThenDay'] = false;
          }
        }
        for (let j = 0; j < this.newComment.length; j++) {
          if (this.newComment[j].id == id) {
            if (!this.newComment[j]['replyArray'] && !this.newComment[j]['startIndex']) {
              this.newComment[j]['replyArray'] = [];
              this.newComment[j]['startIndex'] = 0;
              this.newComment[j]['length'] = 5
            }
            for (this.newComment[j]['startIndex']; this.newComment[j]['startIndex'] < this.newComment[j]['length']; this.newComment[j]['startIndex']++) {
              if (this.getCommentchild[this.newComment[j]['startIndex']])
                this.newComment[j]['replyArray'].push(this.getCommentchild[this.newComment[j]['startIndex']]);
            }
            this.newComment[j]['length'] = this.newComment[j]['length'] + 5
          }
        }
      }, error => {
        let status = this.authenticationService.errormessag(error);
        if (status) {
          this.show = false;
          this.isError = true;
          this.user_marathondayerror = error.text();
        }
      });
    }
    else {
      for (let j = 0; j < this.newComment.length; j++) {
        if (this.newComment[j].id == id) {
          if (!this.newComment[j]['replyArray']) {
            this.newComment[j]['replyArray'] = [];
          }
          for (this.newComment[j]['startIndex']; this.newComment[j]['startIndex'] < this.newComment[j]['length']; this.newComment[j]['startIndex']++) {
            if (this.getCommentchild[this.newComment[j]['startIndex']])
              this.newComment[j]['replyArray'].push(this.getCommentchild[this.newComment[j]['startIndex']]);
          }
          this.newComment[j]['length'] = (this.newComment[j].childCommentCount - this.newComment[j]['replyArray'].length) > 5 ? this.newComment[j]['length'] + 5 : (this.newComment[j]['startIndex'] + this.newComment[j].childCommentCount - this.newComment[j]['replyArray'].length)
        }
      }
      message['show'] = false
    }
  }

  videoSafeUrl(url) {
    url.replace("http://", "//aka.ms/ampembed?url=%2F%2F")
  }
  /**show reply on comments reply */
  onReplyOfReply(array, parentIndex, childIndex) {
    this.name_From = null
    let name = localStorage.getItem("user_name");
    this.name_From = '@' + array[0].userModel.fullName
    this.comment_reply_message_of_reply = null
    for (let i = 0; i < array.length; i++) {
      if (childIndex == i) {
        array[childIndex]['showReply'] = true
      } else {
        array[i]['showReply'] = false
      }
    }
  }

  timer(time) {
    // Update the count down every 1 second
    this.splitString = time
    this.countDownDate = new Date(this.splitString).getTime();
    // Get todays date and time
    this.now = new Date().getTime();
    // Find the distance between now and the count down date
    this.distance = this.now - this.countDownDate;
    // Time calculations for days, hours, minutes and seconds
    this.days = Math.floor(this.distance / (1000 * 60 * 60 * 24));
    this.minutes = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60));
    this.hours = Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    if (this.days) {
      return this.days + 'd'
    } else if (this.hours) {
      return this.hours + 'h'
    } else {
      return this.minutes + 'm'
    }
  }

  onReplyCommentReply(item, parentIndex, childIndex, array) {
    if (this.comment_reply_message_of_reply) {
      this.show = true;
      this.cmt_r = null
      let style = `<label style="color: #003569;">${this.name_From} </label>`
      this.cmt_r = style + ' ' + this.comment_reply_message_of_reply;
      let data = {
        exerciseId: item.exerciseId,
        marathonId: item.marathonId,
        commentId: item.id,
        comment: this.cmt_r,
        parentCommentId: array[childIndex].id
      }
      this._MarathonService.commentMessage(data).then(response => {
        this.newComment[parentIndex].replyArray[childIndex]['showReply'] = false;
        this.show = false;
        this.replyData = response.json()
        array.push(this.replyData)
        this.isError = false;
        this.comment_reply_message_of_reply = null;
      }, error => {
        let status = this.authenticationService.errormessag(error);
        if (status) {
          this.show = false;
          this.isError = true;
          this.user_marathondayerror = error.text();
        }
      });
    }
  }

  commitComment() {
    if (this.comment_message) {
      this.show = true;
      let data = {
        comment: this.comment_message,
        exerciseId: this.data.id,
        marathonId: this.activeRoute.snapshot.params.marathonID
      }
      /**get comments list in exercise detil */
      this._MarathonService.commentMessage(data).then(response => {
        this.show = false;
        this.isError = false;
        this.comment_message = null;
        this.getCommentList()
      }, error => {
        let status = this.authenticationService.errormessag(error);
        if (status) {
          this.show = false;
          this.isError = true;
          this.user_marathondayerror = error.text();
        }
      });
    }
  }

  /** show comments on reply*/
  onReplyComment(item, index) {
    if (this.comment_reply_message) {
      this.show = true;
      this.newComment[index]['showReply'] = false;
      let style = `<label style="color: #003569;">${this.name_From} </label>`
      this.cmt_r = style + ' ' + this.comment_reply_message;
      let data = {
        exerciseId: item.exerciseId,
        marathonId: item.marathonId,
        commentId: item.id,
        comment: this.cmt_r,
        parentCommentId: item.id
      };

      this._MarathonService.commentMessage(data).then(response => {
        this.show = false;
        this.childData = response.json();
        ++item.childCommentCount;
        if (!this.newComment[index]['replyArray'])
          this.newComment[index]['replyArray'] = []
        this.newComment[index]['replyArray'].push(this.childData)
        this.isError = false;
        this.comment_reply_message = null
      }, error => {
        this.show = false;
        this.isError = true;
        this.user_marathondayerror = error.text();
      });
    }
  }
  checkAccessComment(){
    if(localStorage.getItem('productType') === 'Marathon'){
      if (localStorage.getItem('accessComment') == 'true') {
        return true;
      } else {
        return false;
      }
    }else{
      return true;
    }
  }
}
