import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { MarathonService } from '../../services/marathon.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { PlatformLocation } from '@angular/common';
import { Location } from '@angular/common';
import { AuthenticationService } from 'app/services/authentication.service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  public show = false;
  public iserror;
  languageType: any;
  showdropdowemenu: boolean;
  langCode: string
  showandHideDayPlan: boolean;
  getNotificationDetails: any;
  getNotificationSettingDetails: any;
  activeIds = [];

  timeMorning = '12:00';
  timeMassages = '12:00';
  msgDay = false;
  morningDay = false;
  reminderDaily = true;
  days = { 0: "Mon", 1: "Tue", 2: "Wed", 3: "Thu", 4: "Fri", 5: "Sat", 6: "Sun" }
  morningReminderDays = [];
  messageReminderDays = [];

  constructor(private language: LanguageService, private authenticationService: AuthenticationService, private platformLocation: PlatformLocation, private _MarathonService: MarathonService, private activeRoute: ActivatedRoute, private location: Location, private sanitizer: DomSanitizer, private router: Router) {
    platformLocation.onPopState(() => {
      this.router.navigateByUrl('/user_marathon_day_exercise/' + this.activeRoute.snapshot.params.marathonID + '/' + this.activeRoute.snapshot.params.id);
      history.forward();
    })
  }

  ngOnInit() {
    this.showandHideDayPlan = true;

    var data = localStorage.getItem('language');
    if (data != null) {
      this.langCode = data;
      this.language.language(data).then((response) => {
        this.languageType = response
      });
    } else {
      this.langCode = 'Rus';
      this.language.language('Rus').then((response) => {
        this.languageType = response
      });
    }
    this.show = true;
    this._MarathonService.getNotification().then(response => {
      this.show = false;
      this.getNotificationDetails = response.json();
    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        // this.error = status.errormessag;
      }
    })
    this.getUserNotificationSetting()
    this.show = true;
    this._MarathonService.GetUserNotificationSetting().then(response => {
      this.show = false;
      this.getNotificationSettingDetails = response.json();
      this.reminderDaily = this.getNotificationSettingDetails.dailyReminder;

      //massage
      this.timeMassages = this.getNotificationSettingDetails.massageReminderTime;
      this.msgDay = this.getNotificationSettingDetails.messageReminder;
      this.messageReminderDays = this.getNotificationSettingDetails.messageReminderDays;

      //Morning
      this.timeMorning = this.getNotificationSettingDetails.morningReminderTime;
      this.morningDay = this.getNotificationSettingDetails.morningReminder;
      this.morningReminderDays = this.getNotificationSettingDetails.morningReminderDays;

      console.log(this.getNotificationSettingDetails)

    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        console.log(this.iserror)
        // this.error = status.errormessag;
      }
    })
  }
  getUserNotificationSetting() {
    this.show = true;
    this._MarathonService.GetUserNotificationSetting().then(response => {
      this.show = false;
      this.getNotificationSettingDetails = response.json();
      this.reminderDaily = this.getNotificationSettingDetails.dailyReminder;

      //Morning
      this.timeMorning = this.getNotificationSettingDetails.morningReminderTime;
      this.morningDay = this.getNotificationSettingDetails.morningReminder;
      this.morningReminderDays = this.getNotificationSettingDetails.morningReminderDays;

      //massage
      this.timeMassages = this.getNotificationSettingDetails.massageReminderTime;
      this.msgDay = this.getNotificationSettingDetails.messageReminder;
      this.messageReminderDays = this.getNotificationSettingDetails.messageReminderDays;


      console.log(this.getNotificationSettingDetails)

    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        console.log(this.iserror)
        // this.error = status.errormessag;
      }
    })
  }
  clickActive(id) {
    if (this.activeIds.indexOf(id) != -1) {
      const remIndex = this.activeIds.indexOf(id);
      this.activeIds.splice(remIndex, 1);
    } else {
      this.activeIds.push(id);
    }
  }
  showdropdowe() {
    this.showdropdowemenu = !this.showdropdowemenu;
  }
  timer(time) {
    // Update the count down every 1 second
    let splitString = time
    let countDownDate = new Date(splitString).getTime();
    // Get todays date and time
    let now = new Date().getTime();
    // Find the distance between now and the count down date
    let distance = now - countDownDate;
    // Time calculations for days, hours, minutes and seconds
    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    if (days) {
      return days + ' days ago'
    } else if (hours) {
      return hours + ' h'
    } else {
      return minutes + ' m'
    }
  }

  english() {
    localStorage.setItem('language', 'Eng');
    this.langCode = 'Eng';
    this.language.language('Eng').then((response) => {
      this.languageType = response
    });
  }

  russian() {
    localStorage.setItem('language', 'Rus');
    this.langCode = 'Rus';
    this.language.language('Rus').then((response) => {
      this.languageType = response
    });
  }
  italian() {
    localStorage.setItem('language', 'Ita');
    this.langCode = 'Ita'
    this.language.language('Ita').then((response) => {
      this.languageType = response
    });
  }

  moveUp() {
    window.scrollBy(0, -40000);
  }

  getTrustedUrl(event) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(event);
  }

  logout() {
    localStorage.clear()
    this.router.navigateByUrl('/register/userlogin');
  }

  changePassword() {
    localStorage.setItem('url', this.router.url)
    this.router.navigateByUrl('/changepassword');
  }
  notifications() {
    localStorage.setItem('url', this.router.url)
    this.router.navigateByUrl('/notifications');
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    this.showdropdowemenu = false;
  }

  contest() {
    this.router.navigateByUrl('/contest');
  }

  contestvote() {
    this.router.navigateByUrl('/contest_vote');
  }

  myOrder() {
    this.router.navigateByUrl('/orderlist');
  }
  accordStatus = false

  openAccord() {
    // debugger
    this.accordStatus = !this.accordStatus;
  }


  reminderMsg() {
    if (this.reminderDaily) {
      this.msgDay = !this.msgDay;

      this.show = true;
      let data = {
        "objectName": "MassageReminder",
        "value": this.msgDay
      }

      this.updateUserNotificationSettings(data);

    }
  }
  updateUserNotificationSettings(data) {
    this._MarathonService.updateUserNotificationSettings(data).then(response => {
      this.show = false;
      this.getUserNotificationSetting();
    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        console.log(this.iserror)
        // this.error = status.errormessag;
      }
    })
  }

  reminderMor() {
    if (this.reminderDaily) {
      this.morningDay = !this.morningDay;

      this.show = true;
      let data = {
        "objectName": "MorningReminder",
        "value": this.morningDay
      }
      this.updateUserNotificationSettings(data);
    }
  }

  morningTime(tag) {
    let data;
    if (tag === 'Morning Reminder') {
      data = {
        "objectName": "MorningTime",
        "value": this.timeMorning
      }
    } else {
      data = {
        "objectName": "MassageTime",
        "value": this.timeMassages
      }
    }
    this.show = true;

    this.updateUserNotificationSettings(data);
  }

  dailyReminder() {
    this.reminderDaily = !this.reminderDaily;
    if (!this.reminderDaily) {
      this.morningDay = false;
      this.msgDay = false;
    }
    else {
      this.msgDay = this.getNotificationSettingDetails.messageReminder;
      this.morningDay = this.getNotificationSettingDetails.morningReminder;
    }



    this.show = true;
    let data = {
      "objectName": "DailyReminder",
      "value": this.reminderDaily
    }

    this.updateUserNotificationSettings(data);

  }

  activateDayMorning(activateDayMorning) {

    console.log(activateDayMorning);
  }




  morningReminderDaysPush(day: string) {
    let isDayActive = -1;
    let dayAvail = this.morningReminderDays.indexOf(day);
    let days = "";

    if (isDayActive == dayAvail) {
      this.morningReminderDays.push(day)
      // console.log("data Added");
      console.log(this.morningReminderDays);
    }
    else {
      this.morningReminderDays.splice(dayAvail, 1);
      // console.log("data removed");
      console.log(this.morningReminderDays);
    }
    days = this.morningReminderDays.toString();
    console.log(days)

    this.show = true;
    debugger
    let data = {
      "objectName": "MorningDays",
      "value": days
    }

    this.updateUserNotificationSettings(data);
  }
  messageReminderDaysPush(day: string) {
    let isDayActive = -1;
    let dayAvail = this.messageReminderDays.indexOf(day);
    let days = "";
    if (isDayActive == dayAvail) {
      this.messageReminderDays.push(day).toString()
      console.log("Day Add")
    }
    else {
      this.messageReminderDays.splice(dayAvail, 1)
      console.log("Day removed")
    }

    days = this.messageReminderDays.toString();
    console.log(days)

    this.show = true;
    // debugger
    let data = {
      "objectName": "MassageDays",
      "value": days
    }

    this.updateUserNotificationSettings(data);
  }


}
