import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replacelogin'
})
export class ReplaceloginPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value == undefined) {
      return null
    }
    // return value
    let welcomeMessage1 = value.replace(new RegExp("Froala Editor", "g"), "");
    return welcomeMessage1.replace(new RegExp("Powered by", "g"), "");
  }


}
