import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-download-app-popup',
  templateUrl: './download-app-popup.component.html',
  styleUrls: ['./download-app-popup.component.scss']
})
export class DownloadAppPopupComponent implements OnInit {

  constructor() { }
  nameOffset;
  verOffset;
  nAgt = navigator.userAgent;
  desktopDevice: Boolean;
  appAddClosed: Boolean;

  ngOnInit(): void {


    this.appAddClosed = false;

    // if (document.cookie.indexOf("App Ad closed") == -1) {
    //   this.appAddClosed = false;
    // } else {
    // }

    if ((this.verOffset = this.nAgt.indexOf("Macintosh")) != -1 ) {
      this.desktopDevice = true;
    } else if ((this.verOffset = this.nAgt.indexOf("Windows")) != -1) {
      this.desktopDevice = true;
      debugger
    } else if(localStorage.getItem('AppDownloadPopup') !== "Open"){
      this.appAddClosed = true;
    }
  }
  setCookie() {
    this.appAddClosed = true;
    localStorage.setItem('AppDownloadPopup','Closed');
  }

  downloadApp() {
    let appStore = "https://miyabiaccedmy.page.link/download";
    let playStore = "https://miyabiaccedmy.page.link/download";
    let nAgt = navigator.userAgent;
    if ((this.verOffset = nAgt.indexOf("iPhone")) != -1) {
      window.open(appStore, '_blank').focus();
    } else if ((this.verOffset = nAgt.indexOf("iPad")) != -1) {
      window.open(appStore, '_blank').focus();
    } else if ((this.verOffset = nAgt.indexOf("Macintosh")) != -1) {
      this.desktopDevice = true;
      console.log("mac")
    } else if ((this.verOffset = nAgt.indexOf("Windows")) != -1) {
      this.desktopDevice = true;
      console.log("windows")
    } else {
      window.open(playStore, '_blank').focus();
    }
  }
}
