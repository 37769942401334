import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { LanguageService } from '../language.service';
// import { CookieService } from 'ngx-cookie-service';

@Injectable()

export class Authorizeheader {
    constructor(
        private language: LanguageService,
        // private cookieService: CookieService
    ) { }


    downloadHeader() {
        let header = new Headers({
            'Content-Type': 'application/zip'
        });

        let token = localStorage.getItem('access_token');
        /*if access token is set then append it to headers of api */
        if (token) {
            // let token1 = JSON.parse(token).access_token;          
            header.append('Authorization', 'Bearer ' + token);
            header.append('UserLanguage', this.language.languageHeader());
        }
        return header;
    }

    createHeader() {
        /*Access all local storage data */
        let header = new Headers({
            'Content-Type': 'application/json',
        });

        let token = localStorage.getItem('access_token');
        header.append('UserLanguage', this.language.languageHeader());
        /*if access token is set then append it to headers of api */
        if (token) {

            // let token1 = JSON.parse(token).access_token;          
            header.append('Authorization', 'Bearer ' + token);
        }
        return header;
    }
    createHeaderAzure() {
        /*Access all local storage data */
        let header = new Headers({
            'Content-Type': 'application/json',
            'api-key':"9A6FC6ABCE5F199457D17A9837743787"
        });

        return header;
    }

    createHeaderForMarathon() {

        /*Access all local storage data */
        let header = new Headers({

        });
        let token = localStorage.getItem('access_token');
        header.append('UserLanguage', this.language.languageHeader());
        /*if access token is set then append it to headers of api */
        if (token) {

            // let token1 = JSON.parse(token).access_token;          
            header.append('Authorization', 'Bearer ' + token);
        }
        return header;
    }

}