import { Component, OnInit } from '@angular/core';
import { MarathonService } from '../services/marathon.service';
import { EncrDecrService } from '../services/encr-decr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {
  email: any;
  apiError: boolean;
  Error: any;
  show: boolean;
  languageType: any;
  constructor(
    public language: LanguageService,
    private router: Router,
    private _MarathonService: MarathonService,
    private EncrDecr: EncrDecrService,
    private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    var data = localStorage.getItem("language")
    if (data != null) {
       this.language.language(data).then((response) => {
        this.languageType = response
      });
    } else {
      this.language.language("Rus").then((response) => {
        this.languageType = response
      });
    }
    var email1 = localStorage.getItem('rememberemail');
    this.email = this.EncrDecr.get('123456$#@$^@1ERF', email1);
  }

  forgotpassword() {
    var filter = /\s*[a-zA-Zа-яА-Я0-9.-_!#$%-^&*()]{1,}@[a-zA-Zа-яА-Я0-9.-]{1,}[.]{1}[a-zA-Zа-яА-Я0-9]{1,}\s*/;
    if (!filter.test(this.email)) {
      this.apiError = true
      this.Error = ' Введите верный Email'
    } else {
      this.show = true
      if (this.email.toLowerCase() != 'admin@miyabi.com') {
        this._MarathonService.UserResetpassword(this.email).then(response => {
          this.show = false
          this.apiError = false;
          let a = document.getElementById('showpopup')
          a.click();
        }, error => {
          this.apiError = true;
          this.show = false
          if (error.text() == "User not found.") {
            this.Error = "Пользователь с таким Email не найден"
          } else {
            this.Error = error.text()
          }
        });
      } else {
        this.show = false;
        this.apiError = true
        this.Error = 'no верный e-mail'
      }

    }
  }
  navigateBylogin() {
    if (this.email != '' && this.email != undefined) {
      var encrypted1 = this.EncrDecr.set('123456$#@$^@1ERF', this.email);
      localStorage.setItem('rememberemail', encrypted1);
    }
    
    // this.router.navigateByUrl('/register/userlogin');
    if (this.activeRoute.snapshot.params.payMarathonId !== undefined) {
      this.router.navigateByUrl('/register/userlogin/' + this.activeRoute.snapshot.params.payMarathonId + '/' + this.activeRoute.snapshot.params.isGift);
    } else {
      this.router.navigateByUrl('/register/userlogin');
    }
  }
}