import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MarathonService } from '../../services/marathon.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-gotouserlogin',
  templateUrl: './gotouserlogin.component.html',
  styleUrls: ['./gotouserlogin.component.scss']
})
export class GotouserloginComponent implements OnInit {
  show = true;
  apiError: any;
  Error: boolean;

  constructor(private authenticationService: AuthenticationService, private router: Router, private _MarathonService: MarathonService, private activeRoute: ActivatedRoute) {
    this.show = true;
  }

  ngOnInit() {
    this.show = true;

    localStorage.setItem("access_token", this.activeRoute.snapshot.params.token);
    let data = {
      grant_type: "password",
      username: this.activeRoute.snapshot.params.email,
    }

    this._MarathonService.getUserByToken(data).then(response => {
      let data = response.json();

      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("email", data.email);
      localStorage.setItem("refresh_token", data.refresh_token);
      localStorage.setItem("role", data.role);
      localStorage.setItem("username", data.username);
      if(data.role == 'Admin'){
        this.router.navigateByUrl('/marathon_exercise/' + this.activeRoute.snapshot.params.marathonId);
      }else{
      this.getmarathon();
    }
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.Error = true;
        this.apiError = status.errormessag;
      }

    })


  }
  getmarathon() {
    this.show = true;
    this._MarathonService.getActiveOrder().then(response => {
      let checkMarathonPurchace = response.json();
      localStorage.setItem("Marathontitle", checkMarathonPurchace.title)
      localStorage.setItem("cost", checkMarathonPurchace.cost);
      localStorage.setItem("marathonid", checkMarathonPurchace.id);
      localStorage.setItem("orderStatus", checkMarathonPurchace.orderStatus);
      localStorage.setItem("role", 'User');
      localStorage.setItem("user_name", checkMarathonPurchace.fileName);
      localStorage.setItem("expires_in", '86400');
     
      if (checkMarathonPurchace.orderStatus == 'Approved') {
        // this.show = true;
        // this._MarathonService.getUserMarathonDetail(checkMarathonPurchace.id).then(response => {
          // this.show = false;
          var data = checkMarathonPurchace.userMarthonModel;
          localStorage.setItem('isContest', data.contest)
          localStorage.setItem('productType', data.productType)
          localStorage.setItem('isComment', data.comment)
          localStorage.setItem('extension', data.isExtension)
          if (data.marathonDays == null) {
            this.show = true;
            this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);

          } else {
            for (let index = 0; index < data.marathonDays.length; index++) {
              if (data.marathonDays[index].isActive == true) {
                var newid = data.marathonDays[index].id
                this.show = true;
                this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + newid);
              }
              else {
                this.show = true;
                this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);
              }
            }
          }

        // }, error => {
        //   let status = this.authenticationService.errormessag(error);
        //   if (status.showError) {
        //     this.show = false
        //     this.Error = true
        //     if (error.text() == "Marathon is over") {
        //       this.apiError = "марафон завершен"
        //     } else {
        //       this.apiError = status.errormessag;
        //     }

        //   }
        // });

      } else if (checkMarathonPurchace.orderStatus == 'Declined') {
        localStorage.setItem('marthonidorder', checkMarathonPurchace.id)
        this.router.navigateByUrl('/errorstatus');
      }
      else if (checkMarathonPurchace.orderStatus == 'Initiated') {
        localStorage.setItem('marthonidorder', checkMarathonPurchace.id)
        this.router.navigateByUrl('/orderlist');
      }
      else if (checkMarathonPurchace.orderStatus == 'Pending') {
        localStorage.setItem('marthonidorder', checkMarathonPurchace.id)
        this.router.navigateByUrl('/errorstatus');
      }

    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false
        this.Error = true
        this.apiError = status.errormessag;
      }
    });
  }
}
