import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.css']
})
export class LoginErrorComponent implements OnInit {

  constructor( 
    public popup: MatDialog
    ) { }

  ngOnInit(): void {
    
  }
  // closeModal(){
  //   this.popup.getDialogById("Login401")
  // }
}
