import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
// Router
import { Router, ActivatedRoute } from '@angular/router';
import { MarathonService } from '../../services/marathon.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PlatformLocation } from '@angular/common';
import { AppComponent } from '../../app.component';
import { AuthenticationService } from 'app/services/authentication.service';
import { LanguageService } from '../../services/language.service'

@Component({
  selector: 'app-marathon-exercise',
  templateUrl: './marathon-exercise.component.html',
  styleUrls: ['./marathon-exercise.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MarathonExerciseComponent implements OnInit {
  public iserror;
  pagehead: boolean
  public exerciseerror;
  public show;
  today: number = Date.now();
  data: any;
  id: any;
  website_error;
  showContent: boolean;
  videoUrl: any;
  reversArray: any;
  public sampledata = [];
  public emptyCol: string
  firstDayId: any;
  countDownDate: number;
  now: number;
  distance: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  split: any;
  countown: boolean;
  texterror: any;
  daysOfWeek = [];
  day = [];
  MarathonName: any;
  splitString: any;
  mDta: any;
  windowclose: any;
  role: any
  exercise_daily_error: any;
  responce: any;
  finalMessageData = []
  checkfinalmessages: boolean;
  isActive: boolean;
  datashoe: any;
  title: any;
  Subtitle: any;
  showtime: boolean;
  showfinalmessgas: boolean
  showwelcomeMessage: any;
  Rules: any;
  closetime: boolean;
  startdate: any;
  newfinalmessagearray = [];
  languageType: any;
  showdropdowemenu: boolean;
  userNotification: any[];
  getComment: any;
  uploaded: any;
  langCode: string;
  extension: string;
  marathonId: string;
  isContest: string
  firstDayIdGreatExtension: any;
  sampledataGreatExtension: any;
  isCourse: string
  daysOfWeekGreatExtension = [];
  dayGreatExtension = [];
  isGreatExtension: string;
  paymet: any;
  finalDescription: any;
  error: any;
  description: any;
  finalmessagesShowDate: any;
  getSubscriptionDetails: any;
  showSubscribeHistory = false;
  constructor(
    private language: LanguageService,
    private authenticationService: AuthenticationService,
    private appComponent: AppComponent,
    private platformLocation: PlatformLocation,
    private router: Router,
    private _MarathonService: MarathonService,
    private activeRoute: ActivatedRoute,
    private sanitizer: DomSanitizer) {
    // platformLocation.onPopState(() => {
    //   this.router.navigateByUrl('/marathon_exercise/' + this.activeRoute.snapshot.params.id);
    //   history.forward();
    // })
  }

  /**show rules to the marathon app  */
  showRules() {
    if (!this.data.isAcceptCourseTerm && (this.data.productType === 'Course' || this.data.productType === 'Marathon' || this.data.productType === 'GXCourse')) {
      return false
    }
    this.showContent = !this.showContent;
  }
  ngOnInit() {


    const data = localStorage.getItem('language')
    if (data != null) {
      this.langCode = data;
      this.language.language(data).then((response) => {
        this.languageType = response
      });
    } else {
      this.langCode = 'Rus';
      this.language.language('Rus').then((response) => {
        this.languageType = response
      });
    }
    // this.servicestoken();
    this.show = true;
    /**get marathon user detail with condition check welcome message type video or image */
    this.id = this.activeRoute.snapshot.params.id
    this._MarathonService.getUserMarathonDetail(this.id).then(response => {

      this.iserror = false;
      this.show = false;
      this.data = response.json();
      if(this.data.productType === 'Course'){
        this._MarathonService.GetSubscriptionDetails(this.marathonId).then(response => {
          if (response['_body'] !== '') {
            this.getSubscriptionDetails = response.json();
          }
    
        }, error => {
          const status = this.authenticationService.errormessag(error);
          if (status.showError) {
            this.show = false;
            this.iserror = true;
            this.error = status.errormessag;
          }
        })
      }
      if ((this.data.productType === 'GreatExtension' || this.data.productType === 'Course') && this.data.oldGreatExtensions) {
        this.data.oldGreatExtensions.reverse();
      }
      debugger
      // new condtion add
      // this.data.isAcceptCourseTerm = true;
      if (this.data.productType === 'Course') {
        this.data.startDate = this.data.purchaseDate;
      }
      if (!this.data.isAcceptCourseTerm && (this.data.productType === 'Course' || this.data.productType === 'Marathon' || this.data.productType === 'GXCourse')) {
        this.showContent = true;
      }
      if (this.data.greatExtensionDays != null
        && this.data.greatExtensionDays.length === 28
        && this.data.greatExtensionDays[27].day === 28) {
        this.data.greatExtensionDays.push({ leftDays: 28, isEmpty: true })

      }
      localStorage.setItem('isContest', this.data['contest'])
      localStorage.setItem('productType', this.data.productType)
      localStorage.setItem('isComment', this.data['comment'])

      localStorage.setItem('extension', this.data['isExtension'])
      this.extension = localStorage.getItem('extension');
      if (!this.data.isMarathonStart) {
        this.closetime = false
      } else {
        this.closetime = true
      }


      if (this.data.welcomeMessage != null) {

        const showwelcomeMessage = this.data.welcomeMessage.welcomeMessage.replace(new RegExp('Froala Editor', 'g'), '');
        const showwelcomeMessage1 = showwelcomeMessage.replace(new RegExp('Powered by', 'g'), '');
        this.showwelcomeMessage = showwelcomeMessage1.replace(new RegExp('{{order_number}}', 'g'), localStorage.getItem('order_number'));
      }
      if (this.data.rule != null) {
        const Rules = this.data.rule.rule.replace(new RegExp('Froala Editor', 'g'), '');
        this.Rules = Rules.replace(new RegExp('Powered by', 'g'), '');
        //     if (!this.data.isAcceptCourseTerm && this.data.productType === 'Course') {
        //       this.Rules = this.Rules + `<div class="custom-control custom-checkbox mb-3">
        //     <label class="custom-control-label" for="customCheckCourse">I agree with the rules</label>
        //   <input type="checkbox" class="custom-control-input" id="customCheckCourse" name="example1">
        // </div>`
        //     }
      }
      // setTimeout(() => {
      //   const customCheckCourse = document.querySelectorAll('#customCheckCourse')
      //   customCheckCourse[0].addEventListener('change', this.onChangeselectDataPropertyValue.bind(this))
      // }, 1000);

      if (this.data.marathonFinalMessages != null) {
        for (let index = 0; index < this.data.marathonDays.length; index++) {
          const a = this.data.marathonDays.length - 1;
          if (this.data.marathonDays[a].isActive === true) {

            this.showtime = false;
            this.showfinalmessgas = false
            break
          } else {
            this.showfinalmessgas = false;

            this.showtime = true;
            if (a === index) {
              if (this.data.marathonDays[a].isActive === false) {
                const b = this.data.marathonFinalMessages.length - 1
                // finamessageid
                if (b === 0) {
                  this.showfinalmessgas = true;
                  setTimeout(() => {
                    if (this.data.greatExtensionDays == null && this.data.isAcceptCourseTerm) {
                      document.getElementById('finamessageid2').click()
                    }
                  }, 100);

                } else if (b === 1) {
                  this.showfinalmessgas = true;
                  setTimeout(() => {
                    if (this.data.greatExtensionDays == null && this.data.isAcceptCourseTerm) {
                      document.getElementById('finamessageid1').click()
                    }
                  }, 100);
                } else if (b === 2) {
                  this.showfinalmessgas = true
                  setTimeout(() => {
                    if (this.data.greatExtensionDays == null && this.data.isAcceptCourseTerm) {
                      document.getElementById('finamessageid0').click()
                    }
                  }, 100);
                }
              }
            }
          }
        }
      }

      if (this.data['productType'] === 'Marathon' && this.data['marathonFinalMessages'] != null) {
        this.finalMessageData = [];
        for (let i = 0; i < this.data['marathonFinalMessages'].length; i++) {
          // if (!this.data.isExtension) {
          //   this.finalMessageData.push(this.data['marathonFinalMessages'][i]);
          // } else {
          if (this.data.leftDays != null && this.data.leftDays <= 3) {
            if (i !== 1) {
              this.finalMessageData.push(this.data['marathonFinalMessages'][i])
            }
          } else {
            this.finalMessageData.push(this.data['marathonFinalMessages'][i])
          }
          // }
        }
      }

      if (this.data['productType'] === 'GreatExtension' && this.data['marathonFinalMessages'] != null) {
        this.finalMessageData = [];
        for (let i = 0; i < this.data['marathonFinalMessages'].length; i++) {
          if (this.data.leftDays != null) {
            if (i !== 1) {
              this.finalMessageData.push(this.data['marathonFinalMessages'][i])
            }
          } else {
            this.finalMessageData.push(this.data['marathonFinalMessages'][i])
          }
        }
      }
      if (this.data['productType'] === 'Extension' && this.data['marathonFinalMessages'] != null) {

        this.finalMessageData = [];
        for (let i = 0; i < this.data['marathonFinalMessages'].length; i++) {
          if (this.data.leftDays != null && this.data['marathonFinalMessages'].length == 3) {
            if (i !== 1) {
              this.finalMessageData.push(this.data['marathonFinalMessages'][i])
            }
          } else {
            this.finalMessageData.push(this.data['marathonFinalMessages'][i])
          }
        }
      }



      localStorage.setItem('startDate', this.data.startDate);

      if (this.data.isMarathonStart) {
        if (this.data.welcomeMessage.contentType === 'video') {
          if (this.data.welcomeMessage.videoUrl.split('/')[2] === 'youtu.be') {
            this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.welcomeMessage.videoUrl.replace('youtu.be', 'www.youtube.com/embed/'))
          } else {
            this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.welcomeMessage.videoUrl.replace('watch?v=', 'embed/'))
          }
        }
      }

      if (!this.data.isMarathonStart) {
        setInterval(() => {
          this.timer();
        }, 1000);
      } else {
        this.showtime = true;
      }


      // this.data.marathonDays.splice(10, 1)
      // this.data.marathonDays.splice(9, 1)

      // this.data.leftDays = 0
      this.sampledata = this.data.marathonDays;
      this.sampledataGreatExtension = this.data.greatExtensionDays;
      if (this.data.marathonDays != null || this.data.greatExtensionDays != null) {
        const daysCount = this.data.marathonDays.length;
        const startColCount = 2;
        const totalColCount = daysCount + startColCount;
        const colPerRow = 3;
        const totalRow = Math.ceil((totalColCount / colPerRow));
        const emptyCol = (totalRow * colPerRow) - totalColCount;
        localStorage.setItem('marathon_name', this.data.title);
        this.firstDayId = this.sampledata[this.sampledata.length - 1].id
        for (let i = 0; i < this.sampledata.length; i++) {
          this.daysOfWeek.push(this.sampledata[i]);
          if ((i + 1) % 7 === 0) {
            this.day.push(this.daysOfWeek);
            this.daysOfWeek = [];
          }
        }
        if (this.daysOfWeek.length) {
          this.day.push(this.daysOfWeek);
        }
        this.day.reverse()
        for (let j = 0; j < this.day.length; j++) {
          this.day[j].reverse()
        }
        const lastWeekdaysCount = this.day[0].length;
        const lastWeektotalColCount = lastWeekdaysCount + startColCount;
        const lastWeektotalRow = Math.ceil((lastWeektotalColCount / colPerRow));
        const lastWeekemptyCol = (lastWeektotalRow * colPerRow) - lastWeektotalColCount;
        for (let k = 0; k < lastWeekemptyCol; k++) {

          // if (this.data.leftDays > 3 && (this.data.productType === 'Marathon' || this.data.productType === 'Course')) {
          this.day[0].unshift({ isEmpty: true })
          // }
        }
        this.split = this.data.startDate
        const fcolPerRow = 3;
        const fstartColCount = 3;

        if (this.finalMessageData != null) {
          const flastWeekdaysCount = this.finalMessageData.length
          const flastWeektotalColCount = flastWeekdaysCount + fstartColCount;
          const flastWeektotalRow = Math.ceil((flastWeektotalColCount / fcolPerRow));
          const flastWeekemptyCol = (flastWeektotalRow * fcolPerRow) - flastWeektotalColCount;
          for (let k = 0; k < flastWeekemptyCol; k++) {
            // if (this.data.leftDays > 3) {
            // if (this.data.leftDays > 3 && (this.data.productType === 'Marathon' || this.data.productType === 'Course')) {
              if (this.data['productType'] != 'GreatExtension' && this.data['productType'] != 'Extension'){
            this.finalMessageData.unshift({ isEmpty: true })
              }
            // }
            // }
          }
        }

        if (this.finalMessageData != null) {
          this.finalMessageData.sort((a, b) => {
            return <any>(b.order) - <any>(a.order);
          });
        }
      
        debugger
        // this.data.greatExtensionDays.splice(16, 1)
        // this.data.greatExtensionDays.splice(15, 1)
        if (this.data.greatExtensionDays != null) {
          const startColCountGreatExtension = 2;
          const colPerRowGreatExtension = 3;
          localStorage.setItem('marathon_name', this.data.title);
          this.firstDayIdGreatExtension = this.sampledataGreatExtension[this.sampledataGreatExtension.length - 1].id;

          for (let i = 0; i < this.sampledataGreatExtension.length; i++) {
            this.daysOfWeekGreatExtension.push(this.sampledataGreatExtension[i]);
            if ((i + 1) % 7 === 0) {
              this.dayGreatExtension.push(this.daysOfWeekGreatExtension);
              this.daysOfWeekGreatExtension = [];
            }
          }
          if (this.daysOfWeekGreatExtension.length) {
            this.dayGreatExtension.push(this.daysOfWeekGreatExtension);
          }
          this.dayGreatExtension.reverse()
          for (let j = 0; j < this.dayGreatExtension.length; j++) {
            this.dayGreatExtension[j].reverse()
          }
          const lastWeekdaysCountGreatExtension = this.dayGreatExtension[0].length;
          const lastWeektotalColCountGreatExtension = lastWeekdaysCountGreatExtension + startColCountGreatExtension;
          const lastWeektotalRowGreatExtension = Math.ceil((lastWeektotalColCountGreatExtension / colPerRowGreatExtension));
          let lastWeekemptyColGreatExtension
            = (lastWeektotalRowGreatExtension * colPerRowGreatExtension) - lastWeektotalColCountGreatExtension;
          // if (lastWeekemptyColGreatExtension === 1) {
          //   lastWeekemptyColGreatExtension = 0
          // } else if (lastWeekemptyColGreatExtension === 0) {
          //   lastWeekemptyColGreatExtension = 2
          // } else if (lastWeekemptyColGreatExtension === 2) {
          //   lastWeekemptyColGreatExtension = 1
          // }
          for (let k = 0; k < lastWeekemptyColGreatExtension; k++) {
            this.dayGreatExtension[0].unshift({ isEmpty: true })
          }
          this.split = this.data.startDate;
        }
      }
      setTimeout(() => {
        if(this.checkGXbutton() &&  this.finalMessageData != null && this.finalMessageData.length == 3){
          this.finalMessageData.splice(1, 1);
        }  
      }, 1000);
      
    }, error => {

      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        if (error.text() === 'Marathon is over') {
          this.exercise_daily_error = 'марафон завершен'
        } else {
          this.exercise_daily_error = status.errormessag;
        }
      }
    });
    this.isGreatExtension = localStorage.getItem('isGreatExtension')
    this.isCourse = localStorage.getItem('isCourse')
    this.marathonId = localStorage.getItem('marathonid')
    this.isContest = localStorage.getItem('isContest')
  
  }
  onChangeselectDataPropertyValue(event) {
    this.show = true;
    let data = {
      courseId: this.data.marathonId,
      status: event
    }
    this._MarathonService.acceptCourseRules(data).then(response => {
      this.data.isAcceptCourseTerm = response.json();
      this._MarathonService.userMarathonDetailId = '';
      this.show = false;

    }, error => {
      this.show = false;

    })
  }
  changeYouHaveCompletedXPracticeCourse(name){
return this.languageType.marathonStartPage.youHaveCompletedXPracticeCourse.replace(new RegExp('{{practice}}', 'g'), name);
  }
  startGreatExtension() {
    this.show = true;
    this._MarathonService.startGreatExtension(this.activeRoute.snapshot.params.id).then(response => {
      this.show = false;
      let data = response.json();
      this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);
      setTimeout(() => {
        this.ngOnInit();  
      }, 100);
      
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        this.error = status.errormessag;
      }
    })
  }
  checkExtendedExtensionStartDate(){
    let NFDLD = new Date(this.data.extendedExtensionStartDate);
    let newFDLD = (NFDLD.setDate(NFDLD.getDate())) / 1000;
    let NDLD = new Date();
    let newNDLD = (NDLD.setDate(NDLD.getDate())) / 1000;
    if (newFDLD <= newNDLD) {
      return true;
    }else{
      return  false;
    }
  }
  goToPayment() {
    localStorage.setItem('extension', this.data.isExtension);
    localStorage.setItem('Marathontitle', this.data.title);
    localStorage.setItem('marthonidorder', this.data.greatExtensionId);
    localStorage.setItem('cost', this.data.cost)
    this.router.navigateByUrl('/payment');
  }

  ChangecontestParticpentStatus(event, descriptionValue) {
    this.show = true;
    const data = {
      marathonId: this.marathonId,
      isContestParticipated: event
    }
    this._MarathonService.particpentStatus(data).then(response => {

      this.show = false;
      descriptionValue.isContestParticipated = event

    }, error => {
      this.show = false;

    })
  }
  checkGXbutton(){
    let geAceess = localStorage.getItem('geAceess');
    if(parseInt(geAceess) > 0 && this.data.productType == 'Marathon' ){
      return true;
    }else{
      return false
    }
  }

  english() {
    localStorage.setItem('language', 'Eng')
    this.langCode = 'Eng'
    this.language.language('Eng').then((response) => {
      this.languageType = response
    });
  }

  russian() {
    localStorage.setItem('language', 'Rus')
    this.langCode = 'Rus'
    this.language.language('Rus').then((response) => {
      this.languageType = response
    });
  }
  italian() {
    localStorage.setItem('language', 'Ita')
    this.langCode = 'Ita'
    this.language.language('Ita').then((response) => {
      this.languageType = response
    });
  }

  timerclose() {
    this.closetime = true
  }
  finalMessageshow(array, index) {

    this.newfinalmessagearray = array;
    this.title = array[index].title
    this.Subtitle = array[index].subTitle
    this.finalmessagesShowDate = array[index].showDate

    this.pagehead = true
    this.checkfinalmessages = true
    this.isActive = true
    const datashoe1 = this.finalMessageData[index].finalMessage
    const datashoe2 = datashoe1.replace(new RegExp('Froala Editor', 'g'), '');
    this.datashoe = datashoe2.replace(new RegExp('Powered by', 'g'), '');
    for (let i = 0; i < array.length; i++) {
      if (i === index) {
        array[index]['IsActive'] = true
      } else {
        array[i]['IsActive'] = false
      }
    }
  }

  splitDate(str) {
    const date = str.split('T')
    return date[0]
  }

  startdatechange(date) {
    if (date == null) {
      return
    }

    if (date !== undefined && this.languageType !== undefined) {
      let datearray
      if (this.languageType.language.language === 'Eng') {
        datearray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      } else if (this.languageType.language.language === 'Ita') {
        datearray = ['gennaio', 'febbraio', 'marcia', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'];
      } else {
        datearray = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
      }
      if (date.indexOf('-') === -1 && date !== undefined) {
        const newdate = date.split('/');
        return datearray[newdate[0] - 1]
      } else {
        const newdate = date.split('-');
        return datearray[newdate[1] - 1]
      }
    }
  }

  moveUp() {
    window.scrollBy(0, -40000);
  }
  timer() {
    // Update the count down every 1 second
    this.splitString = this.data.startDate
    this.countDownDate = new Date(this.splitString).getTime();
    this.MarathonName = this.data.title

    // Get todays date and time
    this.now = new Date().getTime();
    // Find the distance between now and the count down date
    this.distance = this.countDownDate - this.now;
    // Time calculations for days, hours, minutes and seconds
    this.days = Math.floor(this.distance / (1000 * 60 * 60 * 24));
    this.hours = Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.minutes = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60));
    this.seconds = Math.floor((this.distance % (1000 * 60)) / 1000);
    this.countown = true
  }
  /**get marathon user selected video url */
  getVideoUrl(item) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(item);
  }
  /**get marathon exercise detail by day */
  getMarathonExercise(id, day) {
    this.router.navigateByUrl('/user_marathon_day_exercise/' + this.activeRoute.snapshot.params.id + '/' + id);
  }

  goToLandingPage() {
    this.checkfinalmessages = false;
    this.pagehead = false;

    this.show = true;
    for (let i = 0; i < this.newfinalmessagearray.length; i++) {
      this.newfinalmessagearray[i]['IsActive'] = false;
    }
    setTimeout(() => {
      this.show = false;
    }, 100);


    this.router.navigateByUrl('/marathon_exercise/' + this.activeRoute.snapshot.params.id);
  }
  /**get rule text to the marathon user  */
  getRuleText(item) {
    return this.sanitizer.bypassSecurityTrustStyle(item)
  }
  minTwoDigits(n) {
    return (n < 10 ? '0' : '') + n;
  }
  clickcross() {
    this.router.navigateByUrl('/dashboards/marathon_rule/' + this.activeRoute.snapshot.params.id);
  }

  uploadComplete() {
    this.uploaded.emit();
  }
  dayOfWeekInRussian(dayNumber: any) {

    if (dayNumber !== undefined && this.languageType !== undefined) {
      if (this.languageType.language.language === 'Eng') {
        const d = new Date(dayNumber);
        const weekday = new Array(7);
        weekday[0] = 'Sunday';
        weekday[1] = 'Monday';
        weekday[2] = 'Tuesday';
        weekday[3] = 'Wednesday';
        weekday[4] = 'Thursday';
        weekday[5] = 'Friday';
        weekday[6] = 'Saturday';

        return weekday[d.getDay()];
      } else if (this.languageType.language.language === 'Ita') {
        const d = new Date(dayNumber);
        const weekday = new Array(7);
        weekday[0] = 'domenica';
        weekday[1] = 'lunedi';
        weekday[2] = 'martedì';
        weekday[3] = 'medio';
        weekday[4] = 'giovedi';
        weekday[5] = 'venerdì';
        weekday[6] = 'sabato';

        return weekday[d.getDay()];
      } else {
        const d = new Date(dayNumber);
        const weekday = new Array(7);
        weekday[0] = 'воскресенье';
        weekday[1] = 'понедельник';
        weekday[2] = 'вторник';
        weekday[3] = 'среда';
        weekday[4] = 'четверг';
        weekday[5] = 'пятница';
        weekday[6] = 'суббота';
        return weekday[d.getDay()];
      }
    }
  }


  scrollDown() {
    window.scrollBy(0, 40000);
  }

  showdropdowe() {
    this.showdropdowemenu = !this.showdropdowemenu;

  }
  logout() {
    localStorage.clear()
    this.router.navigateByUrl('/register/userlogin');
  }
  notifications() {
    localStorage.setItem('url', this.router.url)
    this.router.navigateByUrl('/notifications');
  }

  changePassword() {
    localStorage.setItem('url', this.router.url)
    this.router.navigateByUrl('/changepassword');
  }


  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    this.showdropdowemenu = false;
  }

  contest() {
    this.router.navigateByUrl('/contest');
  }

  contestvote() {
    this.router.navigateByUrl('/contest_vote');
  }
  myOrder() {
    this.router.navigateByUrl('/orderlist');
  }

  getPaymentDetails(id) {
    this._MarathonService.PaymentDetail(id).then(response => {
      this.paymet = response.json()
      if (this.paymet.description != null) {
        this.description = this.paymet.description.replace(new RegExp('@greatExtensionButton', 'g'), `<div class="text-center"><button id=\"greatextension\" class="btn btn-modal" (click)="purchaseMarathonForPayment(paymet.greatExtensionCost)">
            оплатить ${this.paymet.greatExtensionCost}
          </button></div>`);
        this.finalDescription = this.description.replace(new RegExp('@simpleExtensionButton', 'g'), ` <div class="text-center"><button id=\"simpleExtension\" class="btn btn-modal" (click)="purchaseMarathon(paymet.simpleExtensionCost)">
            оплатить ${this.paymet.simpleExtensionCost}
          </button></div>`);

        setTimeout(() => {
          this.great()
        }, 1000);

        setTimeout(() => {
          this.simple()
        }, 1000);

      } else {
        this.finalDescription = null
      }

    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        this.error = status.errormessag;
      }
    })
  }
  great() {
    document.getElementById('greatextension').onclick = () => {
      // do something
      localStorage.setItem('cost', this.paymet.greatExtensionCost)
      localStorage.setItem('marthonidorder', this.paymet.greatExtensionId);
      this.router.navigateByUrl('/payment');
    }
  }
  simple() {
    document.getElementById('simpleExtension').onclick = () => {
      // do something
      localStorage.setItem('marthonidorder', this.paymet.simpleExtensionId);
      localStorage.setItem('cost', this.paymet.simpleExtensionCost)
      this.router.navigateByUrl('/payment');
    }
  }

  russianMonths(month) {
    if (month !== undefined  && this.languageType !== undefined) {
      if (month.indexOf('-') === -1) {
        let monthArray;
        if (this.languageType.language.language === 'Eng') {
          monthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        } else if (this.languageType.language.language === 'Ita') {
          monthArray = ['gennaio', 'febbraio', 'marcia', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'];
        } else {
          monthArray = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        }
        const monthOrder = month.split('/')[0];
        return monthArray[monthOrder - 1]
      } else {
        let monthArray;
        if (this.languageType.language.language === 'Eng') {
          monthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        } else if (this.languageType.language.language === 'Ita') {
          monthArray = ['gennaio', 'febbraio', 'marcia', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'];
        } else {
          monthArray = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        }
        const monthOrder = month.split('-')[1];
        return monthArray[monthOrder - 1]
      }
    }
  }
  checkContestShowAndHindden() {
    if(localStorage.getItem('productType') === 'Marathon'){
      if (localStorage.getItem('accessContest') == 'true') {
        return 'Contest';
      } else {
        return 'Photo diary';
      }
    }else{
      return 'Photo diary';
    }
  }
  addDays(date, days) {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
  }
  changeUnsubscribeSuccessPageDetail(item) {
    if (!this.getSubscriptionDetails) {
      return item;
    }
    const date = new Date(this.getSubscriptionDetails.startDateIso);

    let newDate = this.addDays(date, 3);
    var dd = newDate.getDate();
    var mm = newDate.getMonth() + 1;
    var y = newDate.getFullYear();

    var courseAccessTillDate = mm + '.' + dd + '.' + y;
    item = item.replace(new RegExp('{{date}}', 'g'), courseAccessTillDate)
    item = item.replace(new RegExp('{{title}}', 'g'), localStorage.getItem('Marathontitle'))
    return item
  }

  subscribeToCourseCloudFirstTime(item) {
    if (item == 'open') {
      return localStorage.getItem('subscribeToCourseCloudFirstTime') == 'Yes' && localStorage.getItem('productTypeCheckForMarathonPurchased') == 'Course' ? true : false;
    } else {
      localStorage.setItem('subscribeToCourseCloudFirstTime', 'No');
    }
  }
}
