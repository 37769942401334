import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MarathonExerciseComponent } from './marathon-exercise/marathon-exercise.component';
// import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
// import { NgxPaginationModule } from 'ngx-pagination';
import { ContestUploadImageComponent } from './contest/contest-upload-image/contest-upload-image.component';
import { UserDailyExerciseComponent } from './user-daily-exercise/user-daily-exercise.component';
import { UniqueExercisePipe } from '../pipe/unique-exercise.pipe';
import { UserExerciseDetailComponent } from './user-exercise-detail/user-exercise-detail.component';
import { SortingPipe } from '../pipe/sorting.pipe';
import { UserHeaderComponent } from './user-header/user-header.component';
import { SearchPipe } from '../pipe/search.pipe';
import { ExerciseSearchPipe } from '../pipe/exercise-search.pipe';
import { MarathonListSortingPipe } from '../pipe/marathon-list-sorting.pipe';
import { ExerciseListSortingPipe } from '../pipe/exercise-list-sorting.pipe';
import { MessageListSortingPipe } from '../pipe/message-list-sorting.pipe';
import { DndModule } from 'ng2-dnd';
import { UserSearchPipe } from '../pipe/user-search.pipe';
import { UsersortingPipe } from '../pipe/usersorting.pipe';
import { DayPlanComponent } from './day-plan/day-plan.component';
import { SafeHtmlPipe } from '../pipe/safe-html.pipe';
import { DayPlanSmallComponent } from './day-plan-small/day-plan-small.component';
// import { CKEditorModule } from 'ng2-ckeditor';
// import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
// import "froala-editor/js/froala_editor.pkgd.min.js";
import { ContestVoteComponent } from './contest-vote/contest-vote/contest-vote.component';
import { CloseWimdowComponent } from './close-wimdow/close-wimdow.component';
import { SessionPipe } from '../pipe/session.pipe';
import { ScrollableDirective } from '../services/scrollable.directive';
// import { CropperModule } from 'ngx-cropper';
import { ImageCropperModule } from './contest/contest-upload-image/image-cropper/image-cropper.module'
import { MarathonProfileComponent } from './marathon-profile/marathon-profile.component';
import { ErrorpaymentStatusComponent } from './errorpayment-status/errorpayment-status.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MarathonListSortingnewPipe } from '../pipe/marathon-list-sortingnew.pipe';
// import { ChartsModule } from 'ng2-charts';
// import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BypassSecurityTrustResourceUrlPipe } from '../pipe/bypass-security-trust-resource-url.pipe';
import { ReplacePipe } from '../pipe/replace.pipe';
import { MyOrdersListComponent } from './my-orders-list/my-orders-list.component';
import { GotouserloginComponent } from './gotouserlogin/gotouserlogin.component';
import { GiftCouponComponent } from './gift-coupon/gift-coupon.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ReplacesPipe } from 'app/pipe/replaces.pipe';
import { MarathonPlanComponent } from './marathon-plan/marathon-plan.component';
import { DownloadAppPopupComponentModule } from 'app/download-app-popup/download-app-popup-component.module';
// import { SubscribeNavigateByUrlComponent } from './subscribe-navigate-by-url/subscribe-navigate-by-url.component';
// import { TagInputModule } from 'ngx-chips';
// import { AngularDualListBoxModule } from 'angular-dual-listbox';

@NgModule({
  imports: [
    // TagInputModule,
    // Ng2SearchPipeModule,
    ImageCropperModule,
    // ChartsModule,
    // CropperModule,
    CommonModule,
    RouterModule,
    HttpClientModule,
    // AngularEditorModule,
    // AngularDateTimePickerModule,
    FormsModule,
    BrowserModule,
    // OwlDateTimeModule, OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    // NgxPaginationModule,
    // AngularDualListBoxModule,
    // CKEditorModule,
    // FroalaEditorModule.forRoot(), FroalaViewModule.forRoot(),
    DndModule.forRoot(),
    DownloadAppPopupComponentModule

  ],
  declarations: [
    GiftCouponComponent,
    MyOrdersListComponent,
    ReplacePipe,
    BypassSecurityTrustResourceUrlPipe,
    MarathonListSortingnewPipe,
    ChangePasswordComponent,
    ErrorpaymentStatusComponent,
    MarathonProfileComponent,
    ScrollableDirective,
    UserHeaderComponent,
    MarathonExerciseComponent,
    UserDailyExerciseComponent,
    UniqueExercisePipe,
    UserExerciseDetailComponent,
    SortingPipe,
    SearchPipe,
    ExerciseSearchPipe,
    MarathonListSortingPipe,
    SessionPipe,
    ExerciseListSortingPipe,
    MessageListSortingPipe,
    UserSearchPipe,
    ReplacesPipe,
    UsersortingPipe,
    DayPlanComponent,
    SafeHtmlPipe,
    DayPlanSmallComponent,
    ContestUploadImageComponent,
    ContestVoteComponent,
    CloseWimdowComponent,
    GotouserloginComponent,
    NotificationsComponent,
    MarathonPlanComponent,
    
    // SubscribeNavigateByUrlComponent,
  ],
  exports: [
    DndModule,

  ],

  schemas: [NO_ERRORS_SCHEMA]
})
export class ViewsModule { }