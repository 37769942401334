import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
@Pipe({
  name: 'uniqueExercise'
})
export class UniqueExercisePipe implements PipeTransform {
  public transform(itemsList: any, filtersList: any, excludeItem: any, row: any, currItem: any) {

    
    // return options.filter(function(option) {return option.id !== optionToRemove.id)}

  // transform(options, optionToRemove): any {
  //   return options.filter(function (option) { return option.id !== optionToRemove.id)
  // }
  // value.forEach(element => {
  //   let index = args.findIndex((el) => element.id == el.exerciseId);
  //   let currentItem = args[currentItemIndex];
  //   if (index !== -1 && value[index] && currentItem.exerciseId !== value[index].id) {
  //     value.splice(index, 1);
  //   }
  // });
  // return value;
  }

}
