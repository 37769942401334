
export const environment = {
  // Staging configs
  // production: false,
  // baseUrl: 'https://miyabi-staging-service.azurewebsites.net/api/',
  // robokassaMrchLogin: 'miyabi.academy',
  // robokasaLivesMrchPass1: 'kh4vY5e2OKdh7upp1Afh',
  // robokasaLivesMrchPass2: 'bjE7BWpWziM78Pj00jFP',
  // appleId:'com.ionic.marathanMiyabi.backend',
  // REDIRECT_API_URL:"https://miyabi-staging-user.azurewebsites.net/register/userlogin",
  // oneSignalAppId: "31cb91ac-624e-4156-a387-82fdeb000fd3",
  // facebookAppId:'4195504920548343',
  // googleOAuthClientId: "593533801709-v590vc4qc37nl4p15rsfqgke68t7vdob.apps.googleusercontent.com",

  // Production configs
  production: true,
  baseUrl: 'https://miyabi-services.azurewebsites.net/api/',
  // baseUrl: 'https://miyabi-staging-service.azurewebsites.net/api/',
  robokassaMrchLogin: 'miyabi.academy',
  robokasaLivesMrchPass1: 'kh4vY5e2OKdh7upp1Afh',
  robokasaLivesMrchPass2: 'bjE7BWpWziM78Pj00jFP',
  appleId:'com.ionic.marathanMiyabi.backend',
  REDIRECT_API_URL: "https://marathon.miyabi.academy/register/userlogin",
  oneSignalAppId: "c9477ce4-0db5-4b52-80ba-4587a2b7aad7",
  facebookAppId:'4195504920548343',
  googleOAuthClientId: "593533801709-v590vc4qc37nl4p15rsfqgke68t7vdob.apps.googleusercontent.com",
  // StripePublishableKey:"pk_test_51MtqaOIo2dnQl2eaCTzBc6CXD2Vg7yUClHDeLlfIuF1yOdFB7swqJDUU31pd6wWZ2lWMmy44DRQnldWXNn3vxFUl00NAhDIjlH"
  StripePublishableKey:"pk_live_51MtqaOIo2dnQl2eaU7CuKgLDD4LRKUEuVkY34MpgHgSHQo7CyVIgExS0D0xojIog30QFuILOGWriT5nfJ5XZ11jW00wa50uW49"
};
