import { Component, OnInit, ElementRef, ViewChild, ViewEncapsulation, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MarathonService } from '../services/marathon.service';
import { environment } from '../environment';
import { LanguageService } from '../services/language.service';
import {
  IPayPalConfig,
  ICreateOrderRequest
} from 'ngx-paypal';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
// import { json } from 'ngx-custom-validators/src/app/json/validator';
// import { defineComponent } from '@angular/core/src/render3';
import { Md5 } from 'ts-md5/dist/md5';
import { AuthenticationService } from '../services/authentication.service';
import { isDevMode } from '@angular/core';
declare var paypal: any;
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-pay-pal-payment',
  templateUrl: './pay-pal-payment.component.html',
  styleUrls: ['./pay-pal-payment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PayPalPaymentComponent implements OnInit {
  public confettiClassCopy: number[] = new Array(200);
  @ViewChild('downloadZipLink') private downloadZipLink: ElementRef;
  showSuccess: boolean;
  name: string;
  email: string;
  cost: any;
  showcheckboxpaypa: boolean
  showrobockekboz: boolean;
  getComment: any;
  checkMarathonPurchace: any;
  paymentDate: any;
  orderDetail: any;
  baseurl: any;
  showckeckbox: boolean
  startDate: any;
  startDate1: any;
  show: boolean;
  apiError: any
  Error: boolean
  Check: boolean;
  sigValue: string | Int32Array;
  showpayment = '';
  lname: any;
  title = 'Вы оплачиваете участие';
  ruledata: any;
  showriles: boolean;
  exerciseerror: boolean;
  IsGift: boolean;
  hiddenpayment: boolean;
  Couponcode: any;
  baseurlgift: string;
  SelfPurchased: boolean;
  checkSelfPurchased: boolean;
  isGiftbox: boolean;
  languageType: any;
  showdropdowemenu: boolean;
  userNotification: any[];
  uploaded: any;
  langCode: string;
  paypalClientId: string;
  isContest: string
  extension: string;
  today: Date;
  dateTime: string;
  showPaypalSubscribeButton: boolean;
  public payPalConfig?: IPayPalConfig;
  description = { description: '', marathonId: '', marathonTitle: '', isContestParticipated: '' };
  marathonId: string;
  cloudPayment = '0'
  payPal = '0'
  robokassa = '0'
  getSubscriptionCardDetailsData = [];
  saveCardValue: any;
  paymetCourseDetail: any;
  getSubscriptionDetails: any;
  savedCardCloudSubscriptionButton: boolean;
  stripe: any;
  elements: Elements;
  card: StripeElement;

  // optional parameters
  elementsOptions: any = {
    locale: 'ru'
  };

  stripeFormGroup: FormGroup;
  priceUSD: any;
  constructor(
    private fb: FormBuilder,
    private stripeService: StripeService,
    private language: LanguageService,
    private authenticationService: AuthenticationService,
    public router: Router,
    public _MarathonService: MarathonService,
    public activeRoute: ActivatedRoute,
    private md5: Md5) {
    debugger
    if (isDevMode()) {

      this.paypalClientId = 'AYjUD-eTmLLMfKxjFc1zl3TKqwQG5TAfFwl5WkcSQpWeHzoMNp9pL1dfq8ceo6Hnjejxpo3VGNRJNZl7'
      console.log('a');
    } else {

      this.paypalClientId = 'AchN2kZYje5JHRWOeHOzD8bDiVBbSO_YF7HNixV5dSfE7wjQtzRvy9BWXVSzHK14EdDGzdAmy86ZsA2G'
      console.log('b');
    }
  }

  ngOnInit() {
    this.stripeFormGroup = new FormGroup({
      name: new FormControl()
    });
    this.GetGeneralSetting();
    this.getSubscriptionCardDetails();
    // localStorage.setItem('cost','0')
    this.moveUp();

    this.marathonId = localStorage.getItem('marathonid');
    this.isContest = localStorage.getItem('isContest');
    this.extension = localStorage.getItem('extension');
    this.show = true;
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    this.dateTime = date + ' ' + time;
    const data = localStorage.getItem('language');
    this.setElementsOptionsStripe();
    if (data != null) {
      this.langCode = data;
      this.language.language(data).then((response) => {
        this.languageType = response
      });
    } else {
      this.langCode = 'Rus';
      this.language.language('Rus').then((response) => {
        this.languageType = response
      });
    }
    this.md5 = new Md5();
    this.getuserpofile();
    this.CheckSelfMarathonPurchased();
    const marathonid = localStorage.getItem('marthonidorder');
    this._MarathonService.CheckForMarathonPurchased(marathonid).then(response => {
      this.checkMarathonPurchace = response.json();
      // this.cost = localStorage.getItem('cost');
      this.cost = this.checkMarathonPurchace.cost;
      this.priceUSD = this.checkMarathonPurchace?.priceUSD;
      if (this.cost === '0') {
        this.hiddenpayment = true;
      }
      localStorage.setItem('productTypeCheckForMarathonPurchased', this.checkMarathonPurchace.productType)
      // const planId = this.checkMarathonPurchace.planId;

      // if (this.checkMarathonPurchace.productType == 'Course') {
      //   setTimeout(() => {
      //     paypal.Buttons({
      //       style: {
      //         shape: 'rect',
      //         color: 'gold',
      //         layout: 'horizontal',
      //         label: 'subscribe',
      //       },
      //       createSubscription: function (createSubscriptionData, actions) {

      //         return actions.subscription.create({
      //           'plan_id': planId
      //           // 'plan_id': 'P-5HN06846YM866842UL4CGH4Q'
      //         });
      //       },
      //       onApprove: (onApproveData, actions) => {
      //         this.saveTransaction(onApproveData);
      //       }
      //     }).render('#paypal-button-container');
      //   }, 1000);
      // }
      if (this.checkMarathonPurchace.productType === 'Course') {
        this.showpayment = 'cloud';
        this.saveCardValue = null;
        this._MarathonService.PaymentDetail(marathonid).then(response => {
          this.paymetCourseDetail = response.json();
          if (!this.paymetCourseDetail.isTrail) {
            this._MarathonService.GetSubscriptionDetails(marathonid).then(response => {
              this.getSubscriptionDetails = response.json();
            }, error => {
              const status = this.authenticationService.errormessag(error);
              if (status.showError) {
                this.Error = true
                this.show = false
                this.apiError = status.errormessag;
              }
            })
          }
          // this.paymetCourseDetail.isTrail = false
        }, error => {
          const status = this.authenticationService.errormessag(error);
          if (status.showError) {
            this.Error = true
            this.show = false
            this.apiError = status.errormessag;
          }
        })

      }


      this.IsGift = this.checkMarathonPurchace.isGift;
      if (this.activeRoute.snapshot.params.isGift === '1') {
        this.router.navigateByUrl('/payment/1/' + this.activeRoute.snapshot.params.planId);
      } else if (this.activeRoute.snapshot.params.planId) {
        this.router.navigateByUrl('/payment/' + this.activeRoute.snapshot.params.planId);
      } else {
        this.router.navigateByUrl('/payment');
      }
      if (this.IsGift) {
        this.isGiftbox = true;
      }
      if (!this.checkSelfPurchased) {
        this.SelfPurchased = false;
      } else {
        if (this.IsGift) {
          this.SelfPurchased = false;
        } else {
          this.SelfPurchased = true;
        }
      }
      // this.checkMarathonPurchace.startDate=new Date().toLocaleDateString();
      let currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 3);
      this.paymentDate = currentDate.toISOString().split('T')[0];
      // this.startDate1 = this.price.startDate;
      // console.log(this.price, "gdgdgdgdg")

      this.startDate = this.checkMarathonPurchace.startDate;
      const modeldata = {
        marathonId: this.checkMarathonPurchace.id,
        wpOrderId: null,
        isFromWordpress: false,
        planId: this.activeRoute.snapshot.params.planId
      }

      this._MarathonService.createOrderForTransaction(modeldata).then(response => {
        this.show = false
        this.orderDetail = response['_body'];
        debugger

        localStorage.setItem('orderNumber', this.orderDetail);
        if (this.activeRoute.snapshot.params.isGift === '1') {
          this.IsGift = true
          this.OrderAsGift(true);
        }
      }, error => {
        const status = this.authenticationService.errormessag(error);
        if (status.showError) {
          this.Error = true
          this.show = false
          this.apiError = status.errormessag;
        }
      })
    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.Error = true
        this.show = false
        this.apiError = status.errormessag;
      }
    });
    this.initConfig();


  }
  setElementsOptionsStripe() {
    const data = localStorage.getItem('language');
    if (data == 'Rus') {
      this.elementsOptions.locale = 'ru';
    } else if (data == 'Eng') {
      this.elementsOptions.locale = 'en';
    } else if (data == 'Ita') {
      this.elementsOptions.locale = 'it';
    }
  }

  stripeStyle() {

    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.card) {
          this.card = this.elements.create('card', {
            hidePostalCode: true,
            style: {
              base: {
                iconColor: '#666EE8',
                color: '#70d1cc',
                lineHeight: '40px',
                fontWeight: 300,
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                  color: '#70d1cc'
                }
              }
            }
          });
          this.card.mount('#card-element');
          this.show = false;
        }
      });
    setTimeout(() => {
      this.show = false;
    }
      , 10000);
  }
  stripePayment() {

    this.Error = false;
    const name = this.stripeFormGroup.get('name').value;
    if (name == "" || name == null || name == undefined) {
      this.Error = true;
      this.apiError = this.languageType?.paymentPage.pleaseEnterCardHolderName;
      return;
    }
    this.show = true;
    this.stripeService
      .createToken(this.card, { name })
      .subscribe(result => {
        if (result.token) {
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges
          const json = {
            "orderId": this.orderDetail,
            "paymentToken": result.token.id,
            "coupon": this.Couponcode,
          }
          console.log(result.token);
          console.log(result);
          this._MarathonService.stripePayment(json).then(response => {
            document.getElementById('clickPaymentSuccessModel').click();
            this.show = false;
            this.Error = false;

            setTimeout(() => {
              this.navigate();
            }, 5000);

          }, error => {
            this.show = false;
            this.Error = true;
            this.apiError = error.text();
          });
        } else if (result.error) {
          // Error creating the token
          this.show = false;
          this.Error = true;
          this.apiError = result.error.message;
          console.log(result.error.message);
        }
      });
  }
  GetGeneralSetting() {
    // this.show = true;
    this._MarathonService.GetGeneralSetting().then(response => {
      // this.show = false;
      this.Error = false;
      let getGeneralSettingdata;
      getGeneralSettingdata = response.json();

      getGeneralSettingdata = getGeneralSettingdata.items;
      for (let index = 0; index < getGeneralSettingdata.length; index++) {
        if (getGeneralSettingdata[index].settingName === 'Cloud') {
          this.cloudPayment = getGeneralSettingdata[index].settingValue;
        }
        if (getGeneralSettingdata[index].settingName === 'Paypal') {
          this.payPal = getGeneralSettingdata[index].settingValue;
        }
        if (getGeneralSettingdata[index].settingName === 'Robokassa') {
          this.robokassa = getGeneralSettingdata[index].settingValue;
        }
        if (getGeneralSettingdata[index].settingName == 'Stripe') {
          this.stripe = getGeneralSettingdata[index].settingValue;
        }

        if (this.cloudPayment === '1') {
          this.showpayment = 'cloud';
        } else if (this.robokassa === '1') {
          this.showpayment = 'robokassa';
        } else if (this.payPal === '1') {
          this.showpayment = 'PayPal';
        } else if (this.stripe === '1') {
          this.showpayment = 'Stripe';
        }

      }
    }, error => {
      this.show = false;
      this.Error = true;
      this.apiError = error.text();
    });
  }
  getSubscriptionCardDetails() {
    // this.show = true;
    this._MarathonService.getSubscriptionCardDetails().then(response => {
      // this.show = false;
      this.Error = false;
      // let getGeneralSettingdata;
      this.getSubscriptionCardDetailsData = response.json();
    }, error => {
      this.show = false;
      this.Error = true;
      this.apiError = error.text();
    });
  }

  saveTransaction(data) {

    const dataPaypal = JSON.stringify(data).replace(new RegExp('"', 'g'), '\'');
    const date = new Date().toISOString();
    const responseData = {
      status: 'COMPLETED',
      transactionId: data.subscriptionID,
      transactionDate: date,
      orderNumber: JSON.parse(this.orderDetail),
      transactionAmount: this.cost,
      PaidVia: 'paypal',
      PaymentGetway: 'paypal',
      GetwayResponse: dataPaypal
    }
    this._MarathonService.createTransactionDetail(responseData).then(response => {
      this.router.navigateByUrl('/orderlist');
    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.Error = true
        this.show = false
        this.apiError = status.errormessag;
      }
    })
  }
  // ChangecontestParticpentStatus(event, descriptionValue) {
  //   this.show = true;
  //   const data = {
  //     marathonId: this.marathonId,
  //     isContestParticipated: event
  //   }
  //   this._MarathonService.particpentStatus(data).then(response => {
  //     this.show = false;
  //     descriptionValue.isContestParticipated = event
  //   }, error => {
  //     this.show = false;

  //   })
  // }

  english() {
    localStorage.setItem('language', 'Eng')
    this.langCode = 'Eng'
    this.language.language('Eng').then((response) => {
      this.languageType = response;
      this.setElementsOptionsStripe();
    });
  }

  russian() {
    localStorage.setItem('language', 'Rus')
    this.langCode = 'Rus'
    this.language.language('Rus').then((response) => {
      this.languageType = response;
      this.setElementsOptionsStripe();
    });
  }
  italian() {
    localStorage.setItem('language', 'Ita')
    this.langCode = 'Ita'
    this.language.language('Ita').then((response) => {
      this.languageType = response;
      this.setElementsOptionsStripe();
    });
  }


  CheckSelfMarathonPurchased() {
    const marathonid = localStorage.getItem('marthonidorder')
    this._MarathonService.CheckSelfMarathonPurchased(marathonid).then(response => {
      if (response['_body'] === 'false') {
        this.SelfPurchased = false;
        this.checkSelfPurchased = false;
      } else {
        this.SelfPurchased = true;
        this.checkSelfPurchased = true;
      }
    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.Error = true;
        this.apiError = status.errormessag;
      }
    });
  }
  getuserpofile() {
    this._MarathonService.GetUserProfileDetail().subscribe(response => {
      this.getComment = response.json();
      this.name = this.getComment.firstName
      this.lname = this.getComment.lastName
      this.email = this.getComment.email

    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.Error = true
        this.apiError = status.errormessag;
      }
    });
  }

  public initConfig(): void {
    this.payPalConfig = {
      currency: 'RUB',
      clientId: this.paypalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'RUB',
              value: this.cost,
              breakdown: {
                item_total: {
                  currency_code: 'RUB',
                  value: this.cost
                }
              }
            },
            items: [
              {
                name: this.checkMarathonPurchace.title,
                quantity: '1',
                category: 'DIGITAL_GOODS',
                unit_amount: {
                  currency_code: 'RUB',
                  value: this.cost,
                },
              }
            ]
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        actions.order.get().then(details => { });
      },
      onClientAuthorization: (data) => {
        this.show = true
        this.showSuccess = true;
        const responseData = {
          status: data.status,
          transactionId: data.id,
          transactionDate: JSON.parse(JSON.stringify(data.create_time)),
          orderNumber: this.orderDetail,
          transactionAmount: data['purchase_units'][0].amount.value,
          PaidVia: 'Web',
          PaymentGetway: 'paypal'
        }
        this.show = true
        this._MarathonService.createTransactionDetail(responseData).then(response => {
          this.show = true
          this.navigate();
        }, error => {
          const status = this.authenticationService.errormessag(error);
          if (status.showError) {
            this.show = false
            this.Error = true
            this.apiError = status.errormessag;
          }
        })
      },
      onCancel: (data, actions) => {
        this.show = false
        this.Error = true
        this.apiError = 'вы отменили способ оплаты';
      },
      onError: err => {
        this.Error = true
        this.apiError = err;
      },
      onClick: (data, actions) => {
        this.show = true;
      },
    };
  }
  paymentByRoboCasa(amount, signature) {
    let receipt;
    let receiptJson;
    const marathonName = localStorage.getItem('Marathontitle')
    // receiptJson = {
    //   'items': [
    //     {
    //       'name': marathonName,
    //       'quantity': 1,
    //       'sum': this.cost,
    //       'tax': 'none'
    //     }
    //   ]
    // }
    if (this.email && this.email.search('@codechefs.com') !== -1) {
      this.cost = 1
    }
    receiptJson = `{\"sno\":\"usn_income\",\"items\":[{\"name\":\"${marathonName}\",\"quantity\":1,\"sum\":${this.cost},\"tax\":\"none\"}]}`
    // receiptJson = `{\"items\":[{\"name\":\"${marathonName}\",\"quantity\":1,\"sum\":${this.cost},\"tax\":\"none\"}]}`
    // receipt = `%7b%22sno%22%3anull%2c%22items%22%3a%5b%7b%22name%22%3a%22${encodeURI(marathonName)}%22%2c%22quantity%22%3a1%2c%22sum%22%3a${this.cost}%7d%5d%7d`
    this.sigValue = this.md5.appendAsciiStr(`${environment.robokassaMrchLogin}:${this.cost}:${this.orderDetail}:${encodeURI(receiptJson)}:${environment.robokasaLivesMrchPass1}`).end();
    // this.sigValue = this.md5.appendAsciiStr(`${environment.robokassaMrchLogin}:${this.cost}:${this.orderDetail}:${environment.robokasaLivesMrchPass1}`).end();
    this.md5 = new Md5();
    const sigValue2 =
      this.md5.appendAsciiStr(`${environment.robokassaMrchLogin}:${this.orderDetail}:${environment.robokasaLivesMrchPass2}`).end();
    this.show = true;
    const data = {
      orderId: this.orderDetail,
      signature: sigValue2,
      PaidVia: 'Web',
      PaymentGetway: 'robokassa'
    }

    this._MarathonService.SaveAdvancedTransactionForRobokasa(data).then(response => {
      this.show = false
      let receiptURL;

      const marathonName = localStorage.getItem('Marathontitle');
      // console.log(encodeURI(`https://auth.robokassa.ru/Merchant/Index.aspx?MrchLogin=${environment.robokassaMrchLogin}&OutSum=${amount}&InvId=${this.orderDetail}&Desc=${marathonName}&Receipt=${encodeURI(receiptJson)}&SignatureValue=${this.sigValue}&Email=${this.email}`));
      // debugger

      window.location.replace(encodeURI(`https://auth.robokassa.ru/Merchant/Index.aspx?MrchLogin=${environment.robokassaMrchLogin}&OutSum=${amount}&InvId=${this.orderDetail}&Desc=${marathonName}&Receipt=${encodeURI(receiptJson)}&SignatureValue=${this.sigValue}&Email=${this.email}`));
    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false
        this.Error = true
        this.apiError = status.errormessag;
      }
    })
  }
  paymentByCloudPayment(amount) {
    const marathonName = localStorage.getItem('Marathontitle');
    window.location.replace(encodeURI(`https://marathon.miyabi.academy/CloudPayments.html?${this.orderDetail}&${amount}&${this.email}&${marathonName}&web`));
  }
  removeCard(item) {
    this.show = true;
    this._MarathonService.removeCard(item.id).then(response => {
      this.show = false
      this.Error = false
      this.getSubscriptionCardDetails();
    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false
        this.Error = true
        this.apiError = status.errormessag;
      }
    })
  }
  OrderAsGift(isGift) {
    this.isGiftbox = isGift
    this.IsGift = isGift;
    if (!this.checkSelfPurchased) {
      this.SelfPurchased = false;
    } else {
      if (this.IsGift) {
        this.SelfPurchased = false;
      } else {
        this.SelfPurchased = true;
      }
    }
    this.show = true;
    this._MarathonService.OrderAsGift(this.orderDetail, isGift).then(response => {
      this.show = false
      this.Error = false
    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false
        this.Error = true
        this.apiError = status.errormessag;
      }
    })
  }


  CheckDiscount(event) {
    if (event !== '') {
      this.show = true;
      this.Couponcode = event
      this._MarathonService.CheckDiscount(this.orderDetail, event).then(response => {
        this.show = false;
        this.Error = false;
        const CheckDiscount = response.json();
        this.cost = CheckDiscount.cost;
        this.priceUSD = CheckDiscount?.priceUSD;
        if (CheckDiscount.cost === 0 || this.priceUSD === 0) {
          this.hiddenpayment = true;
          this.cost = 0;
        }
      }, error => {
        this.cost = this.checkMarathonPurchace?.cost;
        this.priceUSD = this.checkMarathonPurchace?.priceUSD;
        const status = this.authenticationService.errormessag(error);
        if (status.showError) {
          this.show = false;
          this.Error = true;
          this.apiError = error.text();
          if (this.apiError === 'Coupon not valid!') {
            this.apiError = 'Неверный код купона!'
          } else {
            this.apiError = status.errormessag;
          }
        }
      })
    } else {
      this.cost = this.checkMarathonPurchace?.cost;
      this.priceUSD = this.checkMarathonPurchace?.priceUSD;
    }
  }

  showcheckboxpaypal() {
    this.showcheckboxpaypa = !this.showcheckboxpaypa
  }
  onCheckBoxToggle() {
    this.Check = !this.Check
  }

  showpay(event) {
    this.showpayment = event;
    this.saveCardValue = null;
    this.card = null;
    if (event == 'Stripe') {
      this.show = true;
      setTimeout(() => {
        this.stripeStyle();
      }, 1000);
    }
  }

  navigate() {
    if (!this.IsGift) {
      this.show = false;
      this.show = true;
      const marathonid = localStorage.getItem('marthonidorder')
      this.baseurl = location.origin + '/#/marathon_exercise/' + marathonid
      const link = this.downloadZipLink.nativeElement;
      link.href = this.baseurl;
      link.click();
    } else {
      this.baseurlgift = location.origin + '/#/gift'
      const link = this.downloadZipLink.nativeElement;
      link.href = this.baseurlgift;
      link.click();
    }
  }

  showmarathondata() {
    // this._MarathonService.getUserMarathonDetail(this.checkMarathonPurchace.id).then(response => {
    this.showriles = true;
    // const data = response.json()
    // const ruledata = data.rule.rule
    // const ruledata1 = ruledata.replace(new RegExp('Froala Editor', 'g'), '');
    // this.ruledata = ruledata1.replace(new RegExp('Powered by', 'g'), '');
    // }, error => {
    //   const status = this.authenticationService.errormessag(error);
    //   if (status.showError) {
    //     this.show = false;
    //     this.Error = true;
    //     if (error.text() === 'Marathon is over') {
    //       this.apiError = 'марафон завершен'
    //     } else {
    //       this.apiError = status.errormessag;
    //     }
    //   }
    // })
  }

  PurchaseMarathonnavigate() {
    this.show = true;
    this._MarathonService.PurchaseMarathon(this.orderDetail, this.Couponcode).then(response => {
      this.show = false;
      this.Error = false;
      const PurchaseMarathon = response['_body'];
      if (PurchaseMarathon === 'Success') {
        this.show = true;
        const marathonid = localStorage.getItem('marthonidorder')
        // this.router.navigateByUrl('/marathon_exercise/' + marathonid);
        this._MarathonService.getUserMarathonDetail(marathonid).then(response => {
          this.show = false;
          const data = response.json();
          localStorage.setItem('isContest', data['contest']);
          localStorage.setItem('productType', data.productType)
          localStorage.setItem('isComment', data['comment']);
          localStorage.setItem('startDate', data.startDate);
          localStorage.setItem('newmarathonid', data.marathonId);
          localStorage.setItem('Marathontitle', data.title);
          localStorage.setItem('MarathonName', data.title);
          localStorage.setItem('marathon_name', data.title);
          localStorage.setItem('marathonid', data.marathonId);
          localStorage.setItem('orderStatus', 'Approved');
          if (data.marathonDays == null || (!data.isAcceptCourseTerm && data.productType === 'Course')) {
            this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);
          } else {
            if (data.greatExtensionDays !== null && data.greatExtensionDays.length !== 0) {
              for (let index = 0; index < data.greatExtensionDays.length; index++) {
                if (data.greatExtensionDays[index].isActive === true) {
                  const newid = data.greatExtensionDays[index].id
                  this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + newid);
                } else {
                  if (data.productType === 'GreatExtension') {
                    const gId = data.greatExtensionDays.findIndex(i => i.isActive);
                    this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + data.greatExtensionDays[gId].id);
                  } else {
                    this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);
                  }
                }
              }
            } else {
              for (let index = 0; index < data.marathonDays.length; index++) {
                if (data.marathonDays[index].isActive === true) {
                  const newid = data.marathonDays[index].id
                  this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + newid);
                } else {
                  if (data.productType === 'GreatExtension') {
                    const gId = data.greatExtensionDays.findIndex(i => i.isActive);
                    this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + data.greatExtensionDays[gId].id);
                  } else {
                    this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);
                  }
                }
              }
            }
          }
        }, error => {
          const status = this.authenticationService.errormessag(error);
          if (status.showError) {
            this.show = false;
            this.Error = true;
            if (error.text() === 'Marathon is over') {
              this.apiError = 'марафон завершен';
            } else {
              this.apiError = status.errormessag;
            }
          }
        });
      }
    }, error => {

      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false
        this.Error = true
        this.apiError = status.errormessag;
      }
    })
  }

  Giftbox() {
    this.isGiftbox = false;
  }
  moveUp() {
    window.scrollBy(0, -40000);
  }

  uploadComplete() {
    this.uploaded.emit();
  }
  scrollDown() {
    window.scrollBy(0, 40000);
  }

  showdropdowe() {
    this.showdropdowemenu = !this.showdropdowemenu;
  }

  logout() {
    localStorage.clear()
    this.router.navigateByUrl('/register/userlogin');
  }

  notifications() {
    localStorage.setItem('url', this.router.url)
    this.router.navigateByUrl('/notifications');
  }


  changePassword() {
    localStorage.setItem('url', this.router.url)
    this.router.navigateByUrl('/changepassword');
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    this.showdropdowemenu = false
  }
  myOrder() {
    this.router.navigateByUrl('/orderlist');
  }

  startdatechange(date) {
    debugger
    if (date !== undefined && this.languageType !== undefined) {
      let datearray
      if (this.languageType.language.language === 'Eng') {
        datearray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      } else {
        datearray = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
      }
      if (date.indexOf('-') === -1 && date !== undefined) {
        const newdate = date.split('/');
        return datearray[newdate[0] - 1]
      } else {
        const newdate = date.split('-');
        return datearray[newdate[1] - 1]
      }
    }
  }
  subscribeToCourse() {
    // const dataPaypal = JSON.stringify(data).replace(new RegExp('"', 'g'), '\'');
    // const date = new Date().toISOString();
    const marathonid = localStorage.getItem('marthonidorder')
    const baseurl = location.origin + '/#/marathon_exercise/' + marathonid
    const responseData = {
      orderId: this.orderDetail,
      gateway: 'paypal',
      email: this.email,
      redirectUrl: baseurl,
    }
    this._MarathonService.subscribeToCourse(responseData).then(response => {
      // this.baseurl = response
      const link = this.downloadZipLink.nativeElement;
      link.href = response['_body']
      link.click();
      debugger
    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.Error = true
        this.show = false
        this.apiError = status.errormessag;
      }
    })

  }
  subscribeToCourseCloud() {
    // const dataPaypal = JSON.stringify(data).replace(new RegExp('"', 'g'), '\'');
    // const date = new Date().toISOString();

    const marathonid = localStorage.getItem('marthonidorder')
    const baseurl = location.origin + '/#/marathon_exercise/' + marathonid
    const responseData = {
      orderId: this.orderDetail,
      transactionId: this.saveCardValue.transactionId
    }
    this.savedCardCloudSubscriptionButton = false;
    this._MarathonService.savedCardCloudSubscription(responseData).then(response => {
      // this.baseurl = response
      this.savedCardCloudSubscriptionButton = true;
      localStorage.setItem('subscribeToCourseCloudFirstTime', 'Yes');
      const link = this.downloadZipLink.nativeElement;
      link.href = baseurl
      link.click();
      debugger
    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.Error = true
        this.show = false
        this.apiError = status.errormessag;
      }
    })

  }
  // changeDateAndCost(event) {
  //   if (event) {
  //     return event.replace(new RegExp('{{date}}', 'g'), this.checkMarathonPurchace.startDate.split('T')[0]).replace(new RegExp('{{cost}}', 'g'), this.cost)
  //   } else {
  //     return '';
  //   }
  // }
  changeDateAndCost(event: string): string {
    if (event) {
      return event
        .replace(new RegExp('{{date}}', 'g'), this.paymentDate)
        .replace(new RegExp('{{cost}}', 'g'), this.cost.toString());
    } else {
      return '';
    }
  }

  // changeDateAndCostSuccessfulTransactionsNumber(event) {
  //   if (event) {
  //     return event.replace(new RegExp('{{date}}', 'g'), this.checkMarathonPurchace.startDate.split('T')[0]).replace(new RegExp('{{cost}}', 'g'), this.cost).split('.')[0] + event.replace(new RegExp('{{date}}', 'g'), this.checkMarathonPurchace.startDate.split('T')[0]).replace(new RegExp('{{cost}}', 'g'), this.cost).split('.')[1]
  //   } else {
  //     return '';
  //   }
  // }
   getCurrentDate(): string {
    const currentDate = new Date();
    return this.formatDate(currentDate);
  }
  formatDate(date: Date): string {
    // Format date to 'YYYY-MM-DD'
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
  changeDateAndCostSuccessfulTransactionsNumber(event: string): string {
    if (event) {
      let currentDate = new Date();
      currentDate.setDate(currentDate.getDate()); // Add 3 days to the current date
      const formattedDate = this.formatDate(currentDate);

      const updatedEvent = event
        .replace(new RegExp('{{date}}', 'g'), formattedDate)
        .replace(new RegExp('{{cost}}', 'g'), this.cost.toString());

      // Handling the splitting around period (.)
      const [beforeDot, afterDot] = updatedEvent.split('.');
      return beforeDot + (afterDot ? afterDot : '');
    } else {
      return '';
    }
  }
}
