import { Component, OnInit, HostListener } from '@angular/core';
import { MarathonService } from '../../services/marathon.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-errorpayment-status',
  templateUrl: './errorpayment-status.component.html',
  styleUrls: ['./errorpayment-status.component.scss']
})
export class ErrorpaymentStatusComponent implements OnInit {
  show: boolean;
  apiError: any;
  marathonPayment: any;
  marathonName: any;
  orderId: any;
  transectionId: any;
  orderStatus: any;
  showdropdowemenu: boolean;
  exerciseerror: boolean;
  description = { description: '', marathonId: '', marathonTitle: '', isContestParticipated: '' };
  marathonId: string;
  isContest: string;

  constructor(private authenticationService: AuthenticationService, private _MarathonService: MarathonService, private router: Router) { }

  ngOnInit() {
    this.show = true
    this.marathonId = localStorage.getItem("marathonid");
    console.log(this.marathonId);
    
    this.isContest = localStorage.getItem('isContest');
    const marathonid = localStorage.getItem('marthonidorder');
    this._MarathonService.CheckForMarathonPurchased(marathonid).then(response => {
      this.show = false
      this.marathonPayment = response.json();
      this.marathonName = this.marathonPayment.title;
      this.orderId = this.marathonPayment.orderNumber;
      this.transectionId = this.marathonPayment.transactionId;
      this.orderStatus = this.marathonPayment.orderStatus;
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false
        this.apiError = status.errormessag;
      }
    });

  }
  ChangecontestParticpentStatus(event, descriptionValue) {
    this.show = true;
    let data = {
      marathonId: this.marathonId,
      isContestParticipated: event
    }
    this._MarathonService.particpentStatus(data).then(response => {

      this.show = false;
      descriptionValue.isContestParticipated = event

    }, error => {
      this.show = false;

    })
  }

  showdropdowe() {
    this.showdropdowemenu = !this.showdropdowemenu;
  }
  logout() {
    localStorage.clear()

    this.router.navigateByUrl('/register/userlogin');
  }
  notifications() {
    localStorage.setItem('url', this.router.url)
    this.router.navigateByUrl('/notifications');
  }

  myOrder() {
    this.router.navigateByUrl('/orderlist');
  }
  nevigate() {

    this.show = false
    this._MarathonService.inactiveOrder(this.orderId).then(response => {
      this.show = false
      this.router.navigateByUrl('/payment');

    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false
        this.apiError = status.errormessag;
      }
    });

  }
  changePassword() {

    localStorage.setItem("url", this.router.url)

    this.router.navigateByUrl('/changepassword');
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    this.showdropdowemenu = false;
  }

  moveUp() {
    window.scrollBy(0, -40000);
  }
}
