import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EncrDecrService {

  constructor(private router: Router) { }

  //The set method is use for encrypt the value.
  set(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

    return encrypted.toString();
  }

  //The get method is use for decrypt the value.
  get(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  roleAdminNavigateComment() {


    let encrypted = localStorage.getItem("roleAdmin")
    let a = this.get('123456$#@$^@1ERF', encrypted);
    let showpage = false;

    if (a == "admin" || a == "comment") {
      showpage = true;
    }
    if (!showpage) {
      this.router.navigateByUrl('/register/userlogin');
    }
  }

  roleAdminNavigateorder() {

    let encrypted = localStorage.getItem("roleAdmin")
    let a = this.get('123456$#@$^@1ERF', encrypted);
    let showpage = false;

    if (a == "admin" || a == "order") {
      showpage = true;
    }
    if (!showpage) {
      this.router.navigateByUrl('/register/userlogin');
    }
  }


  roleAdminNavigateFaq() {

    let encrypted = localStorage.getItem("roleAdmin")
    let a = this.get('123456$#@$^@1ERF', encrypted);
    let showpage = false;
    if (a == "comment") {
      showpage = true;
    }
    else if (a == "admin" || a == "faq") {
      showpage = true;
    }
    if (!showpage) {
      this.router.navigateByUrl('/register/userlogin');
    }
  }
  roleAdminNavigateAdmin() {


    let encrypted = localStorage.getItem("roleAdmin")
    let a = this.get('123456$#@$^@1ERF', encrypted);
    let showpage = false;

    if (a == "admin") {
      showpage = true;
    }

    if (!showpage) {
      this.router.navigateByUrl('/register/userlogin');
    }
  }
}
