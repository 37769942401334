import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'exerciseListSorting'
})
export class ExerciseListSortingPipe implements PipeTransform {

  transform(list_of_exercise: Array<any>, args?: any): any {
if(list_of_exercise){
    return list_of_exercise.sort(function (a, b) {
      if (a[args.property] < b[args.property]) {
        return -1 * args.direction;
      }
      else if (a[args.property] > b[args.property]) {
        return 1 * args.direction;
      }
      else {
        return 0;
      }
    });
  }
  };

}
