import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userSearch'
})
export class UserSearchPipe implements PipeTransform {

  transform(items: any[], args: String): any {
    if (!args) {
      return items;
    }

    return items.filter(item => item.fullName.toLowerCase().indexOf(args.toLowerCase()) !== -1 || item.email.toLowerCase().indexOf(args.toLowerCase()) !== -1);

  }


}
