import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usersorting'
})
export class UsersortingPipe implements PipeTransform {

  transform(userdata: Array<any>, args?: any): any {
    if(userdata){
        return userdata.sort(function (a, b) {
          if (a[args.property] < b[args.property]) {
            return -1 * args.direction;
          }
          else if (a[args.property] > b[args.property]) {
            return 1 * args.direction;
          }
          else {
            return 0;
          }
        });
      }
      };

}
