import { BaseLoginProvider , SocialUser } from 'angularx-social-login';

declare let AppleID: any;

export class AppleLoginProvider extends BaseLoginProvider {
  public static readonly PROVIDER_ID: string = 'APPLE';

  protected auth2: any;

  constructor(
    private clientId: string,
    private scope: string,
    private redirectURI: string
  ) {
      super();
  }

  public initialize(): Promise<void> {
    return new Promise((resolve, _reject) => {
      this.loadScript(
        AppleLoginProvider.PROVIDER_ID,
        'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
        () => {
          this.auth2 = AppleID.auth.init({
            clientId: this.clientId,
            scope : this.scope,
            redirectURI: this.redirectURI,
            usePopup: true,
          });

          resolve();
        }
      );
    });
  }
  

  
  public getLoginStatus(): Promise<SocialUser> {
    return new Promise((resolve, reject) => {
      // todo: implement
    //  resolve();
    });
  }
  
  

  public async signIn(signInOptions?: any): Promise<SocialUser> {
    try {
      const data = await AppleID.auth.signIn();
      return data;
    } catch (er) {
      console.log(er);
    }
  }
  
  public signOut(revoke?: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      // AppleID doesnt have revoke method
     // resolve();
    });
  }
  
}