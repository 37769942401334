import { Component, OnInit, HostListener } from '@angular/core';
import { MarathonService } from '../../services/marathon.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gift-coupon',
  templateUrl: './gift-coupon.component.html',
  styleUrls: ['./gift-coupon.component.scss']
})
export class GiftCouponComponent implements OnInit {
  Couponcode: string;
  show: boolean;
  iserror: boolean;
  description = { description: '', marathonId: '', marathonTitle: '', isContestParticipated: '' };
  error: any;
  showdropdowemenu: boolean;
  textCopy: boolean;
  marathonId: string;
  isContest: string;

  constructor(private _MarathonService: MarathonService, private authenticationService: AuthenticationService, private router: Router) { }

  ngOnInit() {
    let Couponcode = localStorage.getItem('orderNumber');
    this.marathonId = localStorage.getItem("marathonid");
    this.isContest = localStorage.getItem('isContest');
    this.show = true;
    this._MarathonService.GetCoupoun(Couponcode).then(response => {
      this.show = false;
      this.iserror = false;
      this.Couponcode = response["_body"];
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        this.error = status.errormessag;
      }
    });
  }
  ;


  ChangecontestParticpentStatus(event, descriptionValue) {
    this.show = true;
    let data = {
      marathonId: this.marathonId,
      isContestParticipated: event
    }
    this._MarathonService.particpentStatus(data).then(response => {
      this.show = false;
      descriptionValue.isContestParticipated = event
    }, error => {
      this.show = false;
    })
  }

  navigateBymarathon() {
    let id = localStorage.getItem("marathonid")
    if (localStorage.getItem("orderStatus") == 'Approved') {
      this.router.navigateByUrl('/marathon_exercise/' + id);
    } else {
      this.router.navigateByUrl('/payment');
    }
  }
  navigateByurlpage() {
    let url = localStorage.getItem("url")
    this.router.navigateByUrl(url);
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    this.showdropdowemenu = false;
  }
  changePassword() {
    localStorage.setItem("url", this.router.url);
    this.router.navigateByUrl('/changepassword');
  }

  dropdowenclose() {
    this.showdropdowemenu = false;
  }
  myOrder() {
    this.router.navigateByUrl('/orderlist');
  }
  logout() {
    localStorage.clear();
    this.router.navigateByUrl('/register/userlogin');
  }
  notifications() {
    localStorage.setItem('url', this.router.url)
    this.router.navigateByUrl('/notifications');
  }


  showdropdowe() {
    this.showdropdowemenu = !this.showdropdowemenu
  }

  copyCoupon(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.textCopy = true
  }
}
