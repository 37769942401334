import { Component, OnInit, HostListener, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MarathonService } from '../../services/marathon.service';
import { AuthenticationService } from '../../services/authentication.service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-my-orders-list',
  templateUrl: './my-orders-list.component.html',
  styleUrls: ['./my-orders-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MyOrdersListComponent implements OnInit {
  showdropdowemenu: boolean;
  show: boolean;
  iserror: boolean;
  error: any;
  marathonlistdata: any;
  allMarathon: any;
  description = { description: '', marathonId: '', marathonTitle: '', isContestParticipated: '' };
  textCopy: boolean;
  contestshow: boolean;
  languageType: any;
  exerciseerror: boolean
  showdetails: any;
  langCode: string;
  isSafari: boolean;
  CurrentMarathonId: any;
  marathonId: string;
  isContest: string;
  available: any;
  current: any;
  archives: any;
  paymet: any;
  discription: any;
  finalDescription: any;
  paymetCourseDetail: any;
  unsubscribePaymentId: any;
  showSubscribeHistory= false;
  getSubscriptionDetails: any;
  unSubscribePaypalSubscriptionDone: boolean;
  getCourseMyCourseDetailsData: any;
  constructor(
    private _MarathonService: MarathonService,
    private language: LanguageService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    const ua = navigator.userAgent.toLowerCase();
    this.marathonId = localStorage.getItem('marathonid');
    this.isContest = localStorage.getItem('isContest');
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') > -1) {
      } else {
        this.isSafari = true
      }
    }
    const data = localStorage.getItem('language')
    if (data != null) {
      this.langCode = data;
      this.language.language(data).then((response) => {
        this.languageType = response
      });
    } else {
      this.langCode = 'Rus';
      this.language.language('Rus').then((response) => {
        this.languageType = response
      });
    }
    this.marathonlist()
    if (localStorage.getItem('orderStatus') === 'Approved') {
      this.contestshow = true;
    }
  }
  ChangecontestParticpentStatus(event, descriptionValue) {
    this.show = true;
    const data = {
      marathonId: this.marathonId,
      isContestParticipated: event
    }
    this._MarathonService.particpentStatus(data).then(response => {
      this.show = false;
      descriptionValue.isContestParticipated = event
    }, error => {
      this.show = false;
    })
  }
  getdate(str) {
    const stringArray = str.split('T');
    return stringArray[0]
  }
  purchaseMarathonForPayment(cost) {
    localStorage.setItem('cost', cost)
    this.router.navigateByUrl('/payment');
  }
  great() {
    document.getElementById('greatextension').onclick = () => {
      // do something
      localStorage.setItem('cost', this.paymet.greatExtensionCost)
      localStorage.setItem('marthonidorder', this.paymet.greatExtensionId);
      this.router.navigateByUrl('/payment');
    }
  }

  simple() {
    document.getElementById('simpleExtension').onclick = () => {
      // do something
      localStorage.setItem('marthonidorder', this.paymet.simpleExtensionId);
      localStorage.setItem('cost', this.paymet.simpleExtensionCost)
      this.router.navigateByUrl('/payment');
    }
  }
  russianMonths(month) {
    if (month !== undefined && this.languageType !== undefined) {
      if (month.indexOf('-') === -1) {
        let monthArray;
        if (this.languageType.language.language === 'Eng') {
          monthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        } else if (this.languageType.language.language === 'Ita') {
          monthArray = ['gennaio', 'febbraio', 'marcia', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'];
        } else {
          monthArray = ['январь', 'февраль', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        }
        const monthOrder = month.split('/')[0];
        return monthArray[monthOrder - 1]
      } else {
        let monthArray;
        if (this.languageType.language.language === 'Eng') {
          monthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        } else if (this.languageType.language.language === 'Ita') {
          monthArray = ['gennaio', 'febbraio', 'marcia', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'];
        } else {
          monthArray = ['январь', 'февраль', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        }
        const monthOrder = month.split('-')[1];
        return monthArray[monthOrder - 1]
      }
    }
  }

  getPaymentDetails(id, item) {
    localStorage.setItem('extension', item.isExtensionAvilable);
    localStorage.setItem('Marathontitle', item.title);
    this._MarathonService.PaymentDetail(id).then(response => {
      this.paymet = response.json()
      if (this.paymet.description != null) {
        this.discription = this.paymet.description.replace(new RegExp('@greatExtensionButton', 'g'), `<div class="text-center"><button id=\"greatextension\" class="btn btn-modal btn-pink" (click)="purchaseMarathonForPayment(paymet.greatExtensionCost)">
            оплатить ${this.paymet.greatExtensionCost}
          </button></div>`);
        this.finalDescription = this.discription.replace(new RegExp('@simpleExtensionButton', 'g'), ` <div class="text-center"><button id=\"simpleExtension\" class="btn btn-modal btn-pink" (click)="purchaseMarathon(paymet.simpleExtensionCost)">
            оплатить ${this.paymet.simpleExtensionCost}
          </button></div>`);
        setTimeout(() => {
          this.great()
        }, 1000);
        setTimeout(() => {
          this.simple()
        }, 1000);
      } else {
        this.finalDescription = null
      }
    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        this.error = status.errormessag;
      }
    })
  }
  getCoursePaymentDetails(id, item, cost) {
    localStorage.setItem('marthonidorder', id);
    localStorage.setItem('cost', cost);
    localStorage.setItem('Marathontitle', item.title);
    this._MarathonService.PaymentDetail(id).then(response => {
      this.paymetCourseDetail = response.json();
    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        this.error = status.errormessag;
      }
    })
  }
  marathonlist() {
    this.show = true;
    this._MarathonService.GetMarathonslist().then(response => {
      this.show = false;
      this.iserror = false;
      this.marathonlistdata = response.json();
      this.available = this.marathonlistdata.availableCourses;
      this.current = this.marathonlistdata.currentCourses;
      this.archives = this.marathonlistdata.archives;
      if(localStorage.getItem('coursePagesOpen')){
        localStorage.removeItem('coursePagesOpen');
        setTimeout(() => {
          document.getElementById('getCoursePaymentDetails').click();  
          this.getCoursePaymentDetails(localStorage.getItem('marthonidorder'), {title:localStorage.getItem('Marathontitle')}, localStorage.getItem('cost'))
        }, 100);
        
        
      }
    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        this.error = status.errormessag;
      }
    });
  }

  english() {
    localStorage.setItem('language', 'Eng');
    this.langCode = 'Eng';
    this.language.language('Eng').then((response) => {
      this.languageType = response
    });
  }

  russian() {
    localStorage.setItem('language', 'Rus');
    this.langCode = 'Rus';
    this.language.language('Rus').then((response) => {
      this.languageType = response
    });
  }
  italian() {
    localStorage.setItem('language', 'Ita');
    this.langCode = 'Ita';
    this.language.language('Ita').then((response) => {
      this.languageType = response
    });
  }

  gotomarathonpage() {
    if (localStorage.getItem('orderStatus') === 'Approved') {
      this.show = true;
      const id = this.marathonId;
      this._MarathonService.getUserMarathonDetail(id).then(response => {
        this.show = false;
        const data = response.json();
        localStorage.setItem('isContest', data['contest']);
        localStorage.setItem('productType', data.productType)
        localStorage.setItem('isComment', data['comment']);
        if (data.marathonDays == null) {
          this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);
        } else {
          for (let index = 0; index < data.marathonDays.length; index++) {
            if (data.marathonDays[index].isActive === true) {
              const newid = data.marathonDays[index].id;
              this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + newid);
            } else {
              this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);
            }
          }
        }
      }, error => {
        const status = this.authenticationService.errormessag(error);
        if (status.showError) {
          this.show = false;
          this.iserror = true;
          if (error.text() === 'Marathon is over') {
            this.error = 'марафон завершен'
          } else {
            this.error = status.errormessag;
          }
        }
      });
    } else {
      this.router.navigateByUrl('/payment');
    }
  }

  goToMarathon(id, extension) {
    debugger
    this.CurrentMarathonId = id
    localStorage.setItem('extension', extension)
    this.show = true;
    this._MarathonService.getUserMarathonDetail(id).then(response => {
      this.show = false;
      const data = response.json();
      localStorage.setItem('isContest', data['contest']);
      localStorage.setItem('productType', data.productType)
      localStorage.setItem('isComment', data['comment']);
      localStorage.setItem('startDate', data.startDate);
      localStorage.setItem('newmarathonid', data.marathonId);
      localStorage.setItem('Marathontitle', data.title);
      localStorage.setItem('MarathonName', data.title);
      localStorage.setItem('marathon_name', data.title);
      localStorage.setItem('marathonid', data.marathonId);
      localStorage.setItem('orderStatus', 'Approved');
      if (data.marathonDays == null || (!data.isAcceptCourseTerm && data.productType === 'Course')) {
        this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);
      } else {
        if(data.greatExtensionDays !== null && data.greatExtensionDays.length !== 0 ){
          for (let index = 0; index < data.greatExtensionDays.length; index++) {
            if (data.greatExtensionDays[index].isActive === true) {
              const newid = data.greatExtensionDays[index].id
              this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + newid);
            } else {
              if (data.productType === 'GreatExtension') {
                const gId = data.greatExtensionDays.findIndex(i => i.isActive);
                this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + data.greatExtensionDays[gId].id);
              } else {
                this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);
              }
            }
          }
        }else{ 
        for (let index = 0; index < data.marathonDays.length; index++) {
          if (data.marathonDays[index].isActive === true) {
            const newid = data.marathonDays[index].id
            this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + newid);
          } else {
            if (data.productType === 'GreatExtension') {
              const gId = data.greatExtensionDays.findIndex(i => i.isActive);
              this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + data.greatExtensionDays[gId].id);
            } else {
              if(data.productType === 'Marathon' && data.marathonFinalMessages && data.marathonFinalMessages.length == 0){
                this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + data.marathonDays[data.marathonDays.length - 1].id);
              }else{
              this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);
              }

            }
          }
        }
      } 
      }
    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        if (error.text() === 'Marathon is over') {
          this.error = 'марафон завершен';
        } else {
          this.error = status.errormessag;
        }
      }
    });
  }

  details(data) {
    this.showdetails = data;
  }

  copyCoupon(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.textCopy = true;
    this.moveUp();
    setTimeout(() => {
      this.textCopy = false;
    }, 3000);
  }

  purchaseMarathon(id, cost, item) {
    localStorage.setItem('extension', item.isExtension);
    localStorage.setItem('Marathontitle', item.title);
    localStorage.setItem('marthonidorder', id);
    localStorage.setItem('cost', cost)
    this.router.navigateByUrl('/marathonPlan/'+id);
    // this.router.navigateByUrl('/payment');
  }
  purchaseGXCourse(id, cost, item) {
    localStorage.setItem('extension', item.isExtension);
    localStorage.setItem('Marathontitle', item.title);
    localStorage.setItem('marthonidorder', id);
    localStorage.setItem('cost', cost)
    // this.router.navigateByUrl('/marathonPlan/'+id);
    this.router.navigateByUrl('/payment');
  }

  showdropdowe() {
    this.showdropdowemenu = !this.showdropdowemenu;
  }

  logout() {
    localStorage.clear()
    this.router.navigateByUrl('/register/userlogin');
  }
  notifications() {
    localStorage.setItem('url', this.router.url)
    this.router.navigateByUrl('/notifications');
  }

  changePassword() {
    localStorage.setItem('url', this.router.url)
    this.router.navigateByUrl('/changepassword');
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    this.showdropdowemenu = false;
  }

  moveUp() {
    window.scrollBy(0, -40000);
  }

  contest() {
    this.router.navigateByUrl('/contest');
  }

  contestvote() {
    this.router.navigateByUrl('/contest_vote');
  }

  dropdowenclose() {
    this.showdropdowemenu = false;
  }

  myOrder() {
    this.router.navigateByUrl('/orderlist');
  }

  daySpelling(day) {
    if (this.langCode === 'Rus') {
      if (day === 1 || day === 21 || day === 31 || day === 41 || day === 51 || day === 61 || day === 71 || day === 81 || day === 91) {
        return 'день';
      } else if ((day > 1 && day < 5)
        || (day > 21 && day < 25) || (day > 31 && day < 35)
        || (day > 41 && day < 45) || (day > 51 && day < 55)
        || (day > 61 && day < 65) || (day > 71 && day < 75)
        || (day > 81 && day < 85) || (day > 91 && day < 95)) {
        return 'дня';
      } else {
        return 'дней';
      }
    } else if (this.langCode === 'Eng') {
      if (day === 1) {
        return 'day';
      } else {
        return 'days';
      }
    } else {
      if (day === 1) {
        return 'giorno';
      } else {
        return 'giorni';
      }
    }
  }
  navigatePayment() {
    this.router.navigateByUrl('/payment')
  }

  unsubscribePayment() {
    this._MarathonService.unSubscribePaypalSubscription(this.unsubscribePaymentId).then(response => {
      // this.paymetCourseDetail = response.json();
      this.unSubscribePaypalSubscriptionDone = true;
      this.marathonlist();
      this.unSubscribePaypalSubscriptionDone = true;
    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        this.error = status.errormessag;
      }
    })
  }
  changeUnsubscribePageDetail(item){
    if(!this.getSubscriptionDetails){
return
    }
    let courseLastPaymentDate = this.getSubscriptionDetails.courseLastPaymentDate != null ? this.getSubscriptionDetails.courseLastPaymentDate.split('T')[0].replace(new RegExp('-', 'g'),'.'): ''
    return item.replace(new RegExp('{termination_date}', 'g'),courseLastPaymentDate)
  }
  changeUnsubscribeSuccessPageDetail(item){
      if(!this.getSubscriptionDetails){
return
    }
    let courseLastPaymentDate = this.getSubscriptionDetails.courseLastPaymentDate != null ? this.getSubscriptionDetails.courseLastPaymentDate.split('T')[0].replace(new RegExp('-', 'g'),'.'): ''
    item = item.replace(new RegExp('{last_payment}', 'g'),courseLastPaymentDate)
    let courseAccessTillDate = this.getSubscriptionDetails.courseAccessTillDate != null ? this.getSubscriptionDetails.courseAccessTillDate.split('T')[0].replace(new RegExp('-', 'g'),'.'): ''
    item = item.replace(new RegExp('{until_payment}', 'g'),courseAccessTillDate)
    item = item.replace(new RegExp('{SSC_title}', 'g'),localStorage.getItem('Marathontitle'))
    return item
  }
  getCourseMyCourseDetails(id, item, cost) {
    localStorage.setItem('marthonidorder', id);
    localStorage.setItem('cost', cost)

    localStorage.setItem('extension', item.isExtensionAvilable);
    localStorage.setItem('Marathontitle', item.title);
    
    this.unsubscribePaymentId = id;
    this.getCourseMyCourseDetailsData = item;


    // this.getCourseMyCourseDetailsData.subscribe = true



    this._MarathonService.PaymentDetail(id).then(response => {
      this.paymetCourseDetail = response.json();
      // this.paymetCourseDetail.isTrail = true
      this.unSubscribePaypalSubscriptionDone = false;
    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        this.error = status.errormessag;
      }
    })
    this._MarathonService.GetSubscriptionDetails(id).then(response => {
      this.getSubscriptionDetails = response.json();
      console.log(response.json());
      
    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        this.error = status.errormessag;
      }
    })
  }

  checkContestShowAndHindden(){
    if(localStorage.getItem('productType') === 'Marathon'){
      if (localStorage.getItem('accessContest') == 'true') {
        return 'Contest';
      } else {
        return '';
      }
    }else{
      return 'Photo diary';
    }
  }
}
