import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any[], args: String): any {
    if(!args) {
      return items;
    }
    
    return items.filter(item => item.title.toLowerCase().indexOf(args.toLowerCase()) !== -1 || item.subtitle.toLowerCase().indexOf(args.toLowerCase()) !== -1);
   
}

}
