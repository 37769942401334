import { AuthenticationService } from './../services/authentication.service';
import { Http } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EncrDecrService } from '../services/encr-decr.service';
import { MarathonService } from '../services/marathon.service';
import { LanguageService } from '../services/language.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public password1;
  public email1;
  checkboxsignup: boolean;
  public phoneNumber: any = null;
  lname: any
  fname: any
  apiError = '';
  token_Detail: any;
  hideSocialIcons: boolean = false;
  refresh_token: any;
  responce: any;
  refresh_error: any;
  expires_in: any;
  count = 1;
  form = new FormGroup({
    account: new FormGroup({
      email: new FormControl('', [
        Validators.required,
      ]),
      password: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', Validators.required),
      lname: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Zа-яА-Я]+(([',. -][                a-zA-Zа-яА-Я                 ])?[            a-zA-Zа-яА-Я           ]*)*$")]),
      fname: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Zа-яА-Я]+(([',. -][                a-zA-Zа-яА-Я                 ])?[            a-zA-Zа-яА-Я           ]*)*$")]),
      checkboxsignup: new FormControl('', Validators.required)
    })
  });
  cleartime: any;
  loginpage: boolean;
  regiterpage: boolean;
  success: boolean;
  successdata: any;
  show: boolean;
  Error: boolean;
  GetGeneralSettingdata: any;
  // TermCondtion: any;
  emailError: boolean;
  fnameError: boolean;
  lnameError: boolean;
  fnameErrornull: boolean;
  phoneNumberError: boolean;
  timePassed: any
  lnameErrornull: boolean;
  languageType: any;
  userId: any

  constructor(
    private activeRoute: ActivatedRoute,
    private EncrDecr: EncrDecrService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private _MarathonService: MarathonService,
    public http: Http,
    public language: LanguageService,
  ) {
    debugger
    const email = localStorage.getItem('rememberemail');
    const password = localStorage.getItem('rememberPassword');
    const language1 = localStorage.getItem('language');
    this.userId = localStorage.getItem('userId');

    localStorage.clear();
    if (email != null && email !== 'null') {
      localStorage.setItem('rememberemail', email);
    }
    if (password != null && password !== 'null') {
      localStorage.setItem('rememberPassword', password);
    }
    if (language1 != null && language1 !== 'null') {
      localStorage.setItem('language', language1);
    } else {
      localStorage.setItem('language', 'Rus');
    }
    // localStorage.clear();
    var email1 = localStorage.getItem('rememberemail');
    var password1 = localStorage.getItem('rememberPassword');
    if (password1 != null && email1 != null) {
      var decrypted1 = this.EncrDecr.get('123456$#@$^@1ERF', email1);
      var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', password1);
      this.password1 = decrypted;
      this.email1 = decrypted1;
    } else if (email1 != null) {
      var decrypted1 = this.EncrDecr.get('123456$#@$^@1ERF', email1);
      this.email1 = decrypted1;
    }


  }
  rememberMe() {
    if (this.email1 !== '' && this.password1 !== '') {
      var encrypted1 = this.EncrDecr.set('123456$#@$^@1ERF', this.email1);
      localStorage.setItem('rememberemail', encrypted1);
      var encrypted = this.EncrDecr.set('123456$#@$^@1ERF', this.password1);
      localStorage.setItem('rememberPassword', encrypted);
    }
  }

  checkboxsignupfun(event) {
    this.checkboxsignup = event;
  }
  errorHandling() {
    let emialpattern = /\s*[a-zA-Zа-яА-Я0-9.-_!#$%-^&*()]{1,}@[a-zA-Zа-яА-Я0-9.-]{1,}[.]{1}[a-zA-Zа-яА-Я0-9]{1,}\s*/;
    let namepattern = /^[     a-zA-Zа-яА-Я]+(([',. -][                a-zA-Zа-яА-Я                 ])?[            a-zA-Zа-яА-Я           ]*)*$/;
    if (!emialpattern.test(this.email1 || this.email == null)) {
      this.Error = false;
      this.emailError = true;
    } else {
      this.emailError = false;
    }

    if (!namepattern.test(this.fname)) {
      this.Error = false;
      this.fnameError = true;
    } else {
      this.fnameError = false;
    }

    if (this.fname == null) {
      this.Error = false;
      this.fnameErrornull = true;
    } else {
      this.fnameErrornull = false;
    }

    if (!namepattern.test(this.lname)) {
      this.Error = false;
      this.lnameError = true;
    } else {
      this.Error = false;
      this.lnameError = false;
    }
    if (this.lname == null) {
      this.Error = false;
      this.lnameErrornull = true;
    } else {
      this.lnameErrornull = false;
    }
   
    if (this.phoneNumber == '' || this.phoneNumber == null || this.phoneNumber == undefined) {
      this.phoneNumberError = true;
    } else {
      this.phoneNumberError = false;
    }
  }

  goToLandingPage() {
    this.router.navigateByUrl('/');
  }

  successLogin(Response: any) {
    localStorage.setItem('AppDownloadPopup', 'Open')
    this.show = false;
    this.token_Detail = Response.json()
    this.refresh_token = this.token_Detail.refresh_token;
    localStorage.setItem('access_token', this.token_Detail.access_token);
    localStorage.setItem('role', this.token_Detail.role);
    localStorage.setItem('user_name', this.token_Detail.username);
    localStorage.setItem('user_email', this.token_Detail.email);
    localStorage.setItem('expires_in', this.token_Detail.expires_in);
    localStorage.setItem('refresh_token', this.token_Detail.refresh_token);

    if (this.token_Detail.role === 'User' && this.activeRoute.snapshot.params.payMarathonId === undefined) {
      this.show = true;
      this.getActiveOrder();
    } else if (this.activeRoute.snapshot.params.payMarathonId !== undefined) {
      this.show = true;
      this._MarathonService.CheckForMarathonPurchased(this.activeRoute.snapshot.params.payMarathonId).then(response => {
        // this.show = false;
        this.Error = false;
        let getMarathons;
        getMarathons = response.json();
        console.log(getMarathons);
        localStorage.removeItem('coursePagesOpen');
        if (getMarathons.orderStatus == "Approved" && getMarathons.productType !== 'Course') {
          this.getUserMarathonDetail();
        } else if (getMarathons.orderStatus == "Approved" && getMarathons.subscribe && getMarathons.productType == 'Course') {
          this.getUserMarathonDetail();
        } else if (getMarathons.productType == 'Course') {
          localStorage.setItem('marthonidorder', getMarathons.id);
          localStorage.setItem('cost', getMarathons.cost);
          localStorage.setItem('Marathontitle', getMarathons.title);
          localStorage.setItem('coursePagesOpen', this.activeRoute.snapshot.params.payMarathonId);
          this.router.navigateByUrl('/orderlist');
        } else {
          this._MarathonService.GetMarathons().then(response => {
            this.show = false;
            this.Error = false;
            let getMarathons;
            getMarathons = response.json();
            for (let index = 0; index < getMarathons.length; index++) {
              if (getMarathons[index].id === this.activeRoute.snapshot.params.payMarathonId) {
                this.purchaseMarathon(getMarathons[index].id, getMarathons[index].cost, getMarathons[index])
                break;
              } else {
                this.router.navigateByUrl('/');
              }
            }
          }, error => {
            let status = this.authenticationService.errormessag(error);
            if (status.showError) {
              this.show = false;
              this.Error = true;
              this.apiError = status.errormessag;
            }
          });
        }
      }, error => {
        let status = this.authenticationService.errormessag(error);
        if (status.showError) {
          this.show = false;
          this.Error = true;
          this.apiError = status.errormessag;
        }
      });

    }

    this.expires_in = localStorage.getItem('expires_in');
    this.expires_in = this.token_Detail.expires_in * 1000 - 10000;
    this.cleartime = setInterval(() => {
      this.servicestoken();
    }, this.expires_in);
    this.getuserpofile()
  }

  signInWithEmailAndPassword(data) {
    this.errorHandling()
    if (this.activeRoute.snapshot.params.id === 'userlogin') {
      this.rememberMe();
      let user = {
        username: data.email.trim(),
        password: data.password,
        grant_type: 'password',
        requestFrom: "web"
      };

      this.show = true;
      this.authenticationService.login(user)
        .subscribe(Response => {
          this.successLogin(Response);
        }, error => {
          this.show = false;
          this.Error = true;
          this.apiError = error.text();
        })
    } else {
      if (this.checkboxsignup === true) {
        if (!this.emailError && !this.fnameError && !this.fnameErrornull && !this.lnameError && !this.lnameErrornull) {
          let user = {
            email: this.email1.trim(),
            phoneNumber: parseInt(this.phoneNumber),
            lastName: this.lname.trim(),
            firstName: this.fname.trim(),
            TermCondtion: this.checkboxsignup,
            grant_type: 'password',
          };

          this.show = true;
          this._MarathonService.UserRegister(user).then(response => {
            this.show = false;
            this.Error = false;
            this.loginpage = true;
            this.regiterpage = false;
            this.success = true;
            this.successdata = this.languageType.registerPage.passwordSentToEmailAddressMessage;
            // this.router.navigateByUrl('/register/userlogin');
            if (this.activeRoute.snapshot.params.payMarathonId !== undefined) {
              this.router.navigateByUrl('/register/userlogin/' + this.activeRoute.snapshot.params.payMarathonId + '/' + this.activeRoute.snapshot.params.isGift);
            } else {
              this.router.navigateByUrl('/register/userlogin');
            }
          }, error => {
            let a = error.text();
            let b = 'User already register';
            let c = 'First Name or LastName can not be null or empty.';
            if (a.toLowerCase() === b.toLowerCase()) {
              this.Error = true;
              this.show = false;
              this.apiError = 'Пользователь с таким email уже зарегистирован в системе';
            } else if (a.toLowerCase() === c.toLowerCase()) {
              this.Error = true;
              this.show = false;
              this.apiError = 'Имя и Фамилия не могут быть пустыми';
            } else {
              this.Error = true;
              this.show = false;
              this.apiError = error.text();
            }
          });
        }
      } else {
        this.Error = true;
        this.show = false;
        this.apiError = 'Необходимо принять Условия пользования';
      }

    }
  }
  getUserMarathonDetail() {
    this._MarathonService.getUserMarathonDetail(this.activeRoute.snapshot.params.payMarathonId).then(response => {
      this.show = false;
      let checkMarathonPurchace = response.json();
      localStorage.setItem('Marathontitle', checkMarathonPurchace.title);
      localStorage.setItem('startDate', checkMarathonPurchace.startDate);
      localStorage.setItem('cost', checkMarathonPurchace.cost);
      localStorage.setItem('marathonid', checkMarathonPurchace.marathonId);
      localStorage.setItem('orderStatus', checkMarathonPurchace.orderStatus);
      // if (checkMarathonPurchace.orderStatus === 'Approved') {
      // if (checkMarathonPurchace.isGift) {
      //   localStorage.setItem('orderStatus', 'Initiated');
      //   this.router.navigateByUrl('/orderlist');
      // } else {
      var data = checkMarathonPurchace;
      localStorage.setItem('extensionType', data.productType)
      localStorage.setItem('isGreatExtension', data.isGreatExtesnionAvilable)
      localStorage.setItem('isCourse', data.isCourse)
      localStorage.setItem('isContest', data.contest)
      localStorage.setItem('productType', data.productType)
      localStorage.setItem('isComment', data.comment)
      localStorage.setItem('extension', data.isExtension)
      if (response.json().productType == 'Marathon') {
        localStorage.removeItem('accessComment');
        localStorage.removeItem('accessContest');
        this._MarathonService.getCurrentMarathonPlan(checkMarathonPurchace.id).then(response => {
          const dataPlan = response.json();
          localStorage.setItem('accessComment', dataPlan['accessComment']);
          localStorage.setItem('accessContest', dataPlan['accessContest']);
          localStorage.setItem('geAceess', dataPlan['geAceess']);
        });
      }
      if (data.marathonDays == null) {
        this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);
      } else {
        if (data.greatExtensionDays != null && data.greatExtensionDays.length > 0) {
          let index = data.greatExtensionDays.findIndex(x => x.isActive)
          if (index !== -1) {
            let newid = data.greatExtensionDays[index].id;
            this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + newid);
          } else {
            if (data.marathonFinalMessages != null && data.marathonFinalMessages.length !== 0) {
              this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);
            } else {
              let newid = data.greatExtensionDays[data.greatExtensionDays.length - 1].id;
              this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + newid);
            }
          }
        } else {
          let index = data.marathonDays.findIndex(x => x.isActive)
          if (index !== -1) {
            let newid = data.marathonDays[index].id;
            this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + newid);
          } else {
            if (data.marathonFinalMessages != null && data.marathonFinalMessages.length !== 0) {
              this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);
            } else {
              let newid = data.marathonDays[data.marathonDays.length - 1].id;
              this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + newid);
            }
          }
        }
      }
      // }
      // }
      // else if (checkMarathonPurchace.orderStatus === 'Declined') {
      //   localStorage.setItem('marthonidorder', checkMarathonPurchace.id);
      //   this.router.navigateByUrl('/errorstatus');
      // }
      // else if (checkMarathonPurchace.orderStatus === 'Initiated') {
      //   localStorage.setItem('marthonidorder', checkMarathonPurchace.id);
      //   this.router.navigateByUrl('/orderlist');
      // }
      // else if (checkMarathonPurchace.orderStatus === 'Pending') {
      //   localStorage.setItem('marthonidorder', checkMarathonPurchace.id);
      //   this.router.navigateByUrl('/errorstatus');
      // }
    }, error => {
      this.show = false;
      this.Error = true;
      this.apiError = error.text();
    });
  }
  getActiveOrder() {
    this._MarathonService.getActiveOrder().then(response => {
      this.show = false;
      let checkMarathonPurchace = response.json();
      localStorage.setItem('Marathontitle', checkMarathonPurchace.title);
      localStorage.setItem('startDate', checkMarathonPurchace.startDate);
      localStorage.setItem('cost', checkMarathonPurchace.cost);
      localStorage.setItem('marathonid', checkMarathonPurchace.id);
      localStorage.setItem('orderStatus', checkMarathonPurchace.orderStatus);
      if (checkMarathonPurchace.orderStatus === 'Approved') {
        if (checkMarathonPurchace.isGift) {
          localStorage.setItem('orderStatus', 'Initiated');
          this.router.navigateByUrl('/orderlist');
        } else {
          var data = checkMarathonPurchace.userMarthonModel;
          localStorage.setItem('extensionType', data.productType)
          localStorage.setItem('isGreatExtension', data.isGreatExtesnionAvilable)
          localStorage.setItem('isCourse', data.isCourse)
          localStorage.setItem('isContest', data.contest)
          localStorage.setItem('productType', data.productType)
          localStorage.setItem('isComment', data.comment)
          localStorage.setItem('extension', data.isExtension)
          if (response.json().productType == 'Marathon') {
            localStorage.removeItem('accessComment');
            localStorage.removeItem('accessContest');
            this._MarathonService.getCurrentMarathonPlan(checkMarathonPurchace.id).then(response => {
              const dataPlan = response.json();
              localStorage.setItem('accessComment', dataPlan['accessComment']);
              localStorage.setItem('accessContest', dataPlan['accessContest']);
              localStorage.setItem('geAceess', dataPlan['geAceess']);
            });
          }
          if (data.marathonDays == null) {
            this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);
          } else {
            if (data.greatExtensionDays != null && data.greatExtensionDays.length > 0) {
              let index = data.greatExtensionDays.findIndex(x => x.isActive)
              if (index !== -1) {
                let newid = data.greatExtensionDays[index].id;
                this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + newid);
              } else {
                if (data.marathonFinalMessages != null && data.marathonFinalMessages.length !== 0) {
                  this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);
                } else {
                  let newid = data.greatExtensionDays[data.greatExtensionDays.length - 1].id;
                  this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + newid);
                }
              }
            } else {
              let index = data.marathonDays.findIndex(x => x.isActive)
              if (index !== -1) {
                let newid = data.marathonDays[index].id;
                this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + newid);
              } else {
                if (data.marathonFinalMessages != null && data.marathonFinalMessages.length !== 0) {
                  this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);
                } else {
                  let newid = data.marathonDays[data.marathonDays.length - 1].id;
                  this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + newid);
                }
              }
            }
          }
        }
      }
      else if (checkMarathonPurchace.orderStatus === 'Declined') {
        localStorage.setItem('marthonidorder', checkMarathonPurchace.id);
        this.router.navigateByUrl('/errorstatus');
      }
      else if (checkMarathonPurchace.orderStatus === 'Initiated') {
        localStorage.setItem('marthonidorder', checkMarathonPurchace.id);
        this.router.navigateByUrl('/orderlist');
      }
      else if (checkMarathonPurchace.orderStatus === 'Pending') {
        localStorage.setItem('marthonidorder', checkMarathonPurchace.id);
        this.router.navigateByUrl('/errorstatus');
      }
    }, error => {
      this.show = false;
      this.Error = true;
      this.apiError = error.text();
    });
  }
  getuserpofile() {
    this._MarathonService.GetUserProfileDetail().subscribe(response => {
      let getComment = response.json();

    }, error => {
      const status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.Error = true
        this.apiError = status.errormessag;
      }
    });
  }
  purchaseMarathon(id, cost, item) {
    localStorage.setItem('extension', item.isExtension);
    localStorage.setItem('Marathontitle', item.title);
    localStorage.setItem('marthonidorder', id);
    localStorage.setItem('cost', cost)
    if (this.activeRoute.snapshot.params.isGift === '1') {
      this.router.navigateByUrl('/marathonPlan/' + id + '/' + '1');
    } else {
      this.router.navigateByUrl('/marathonPlan/' + id);
    }

  }
  remembermelogin(data) {
    if (data.rememberme) {
      localStorage.setItem('Name', data.email);
      localStorage.setItem('password', data.password);
    } else {
      localStorage.setItem('Name', data.email);
      localStorage.setItem('password', data.password);
    }
  }

  get email() {
    return this.form.get('account.email');
  }
  get password() {
    return this.form.get('account.password');
  }

  servicestoken() {

    let refresh_token = localStorage.getItem('refresh_token');

    let user = {
      'grant_type': 'refresh_token',
      'refresh_token': refresh_token,
      'username': this.email1,
      'password': this.password1,
      requestFrom: "web"
    }

    var expires_in = localStorage.getItem('expires_in');
    if (expires_in != null) {
      this.show = true;
      this.authenticationService.RefreshtokenManage().subscribe(Response => {
        this.show = false;
        this.responce = Response.json();
        localStorage.setItem('refresh_token', this.responce.refresh_token);
        localStorage.setItem('expires_in', this.responce.expires_in);
        localStorage.setItem('access_token', this.responce.access_token);
      }, error => {

        this.show = false;
        if (error.status === 405) {

          let role = localStorage.getItem('role');
          if (role === 'SuperAdmin') {
            localStorage.clear();
            this.router.navigateByUrl('/register/userlogin');
          } else {
            localStorage.clear();
            this.router.navigateByUrl('/error');
          }
        }
        this.refresh_error = error.text();
      });

    } else {
      clearTimeout(this.cleartime);
    }

  }

  login() {
    this.Error = false;
    if (this.activeRoute.snapshot.params.payMarathonId !== undefined) {
      this.router.navigateByUrl('/register/userlogin/' + this.activeRoute.snapshot.params.payMarathonId + '/' + this.activeRoute.snapshot.params.isGift);
    } else {
      this.router.navigateByUrl('/register/userlogin');
    }
    this.regiterpage = false;
    this.loginpage = true;
  }

  checkTermsForSocial() {
    if (!this.checkboxsignup === true && this.regiterpage) {
      this.Error = true;
      this.show = false;
      this.apiError = 'Необходимо принять Условия пользования';
      return true;
    }
  }

  googleLogin() {
    if (this.checkTermsForSocial()) {
      return;
    }
    this.show = true;
    this.authenticationService.googleLogin(this.userId).then(Response => {
      Response.subscribe(response => {
        if (response) this.successLogin(response);
      })
    })
      .catch(
        error => {
          this.show = false;
          this.Error = true;
          this.apiError = error.text();
        })
  }


  facebookLogin() {
    if (this.checkTermsForSocial()) {
      return;
    }
    this.show = true;
    this.authenticationService.facebookLogin(this.userId).then(Response => {
      Response.subscribe((response) => {
        if (response) this.successLogin(response);
      })
    })
      .catch(
        error => {
          this.show = false;
          this.Error = true;
          this.apiError = error.text();
        })
  }

  register() {
    this.success = false;
    this.Error = false;
    if (this.activeRoute.snapshot.params.payMarathonId !== undefined) {
      this.router.navigateByUrl('/register/userregister/' + this.activeRoute.snapshot.params.payMarathonId + '/' + this.activeRoute.snapshot.params.isGift);
    } else {
      this.router.navigateByUrl('/register/userregister');
    }
    // this.router.navigateByUrl('/register/userregister');
    this.loginpage = false;
    this.regiterpage = true;
  }

  ngOnInit() {
    this.checkUrl();
    this.router.events.subscribe(() => this.checkUrl());
    localStorage.removeItem('access_token');
    var data = localStorage.getItem('language');
    if (data != null) {
      this.language.language(data).then((response) => {
        this.languageType = response
      });
    } else {
      this.language.language('Rus').then((response) => {
        this.languageType = response
      });
    }
    // this.GetGeneralSetting()
    let errormessages = this._MarathonService.messagesdatasend();
    if (errormessages !== undefined) {
      this.success = true;
      this.successdata = errormessages;
    }
    if (this.activeRoute.snapshot.params.id === 'userlogin') {
      this.regiterpage = false;
      this.loginpage = true;
    } else {
      this.loginpage = false;
      this.regiterpage = true;
    }
  }

  checkUrl(): void {
    const url = this.router.url;
    this.hideSocialIcons = url.includes('/register/userregister');
  }
  // GetGeneralSetting() {
  // this.show = true;
  // this._MarathonService.GetGeneralSetting().then(response => {
  //   this.show = false;
  //   this.Error = false;
  //   this.GetGeneralSettingdata = response.json();

  //   this.GetGeneralSettingdata = this.GetGeneralSettingdata.items;
  //   for (let index = 0; index < this.GetGeneralSettingdata.length; index++) {
  //     if (this.GetGeneralSettingdata[index].settingName === 'TermCondtion') {
  //       this.TermCondtion = this.GetGeneralSettingdata[index].settingValue;
  //     }

  //   }
  // }, error => {
  //   this.show = false;
  //   this.Error = true;
  //   this.apiError = error.text();
  // });
  // }
  ngOnDestroy() {
  }
  forgotpassword() {
    if (this.activeRoute.snapshot.params.payMarathonId !== undefined) {
      this.router.navigateByUrl(
        '/forgotpassword/' + this.activeRoute.snapshot.params.payMarathonId + '/' + this.activeRoute.snapshot.params.isGift
      );
    } else {
      this.router.navigateByUrl('/forgotpassword')
    }
  }

  appleLogin() {
    if (this.checkTermsForSocial()) {
      return;
    }
    this.show = true;
    this.authenticationService.appleLogin(this.userId).then(Response => {
      Response.subscribe((response) => {
        if (response) this.successLogin(response);
      })
    })
      .catch(
        error => {
          this.show = false;
          this.Error = true;
          this.apiError = error.text();
        })
  }
};