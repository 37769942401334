import { RouterModule, Route } from '@angular/router';
import { ModuleWithProviders } from "@angular/compiler/src/core";
import { LoginComponent } from './login/login.component';
import { MarathonExerciseComponent } from './user_views/marathon-exercise/marathon-exercise.component';
import { UserDailyExerciseComponent } from './user_views/user-daily-exercise/user-daily-exercise.component';
import { UserExerciseDetailComponent } from './user_views/user-exercise-detail/user-exercise-detail.component';
import { ContestUploadImageComponent } from './user_views/contest/contest-upload-image/contest-upload-image.component';
import { ContestVoteComponent } from './user_views/contest-vote/contest-vote/contest-vote.component';
import { CloseWimdowComponent } from './user_views/close-wimdow/close-wimdow.component';
import { MarathonProfileComponent } from './user_views/marathon-profile/marathon-profile.component';
import { PayPalPaymentComponent } from './pay-pal-payment/pay-pal-payment.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ErrorpaymentStatusComponent } from './user_views/errorpayment-status/errorpayment-status.component';
import { RoboPaymentStatusComponent } from './pay-pal-payment/robo-payment-status/robo-payment-status.component';
import { ChangePasswordComponent } from './user_views/change-password/change-password.component';
import { MyOrdersListComponent } from './user_views/my-orders-list/my-orders-list.component';
import { GotouserloginComponent } from './user_views/gotouserlogin/gotouserlogin.component';
import { GiftCouponComponent } from './user_views/gift-coupon/gift-coupon.component';
import { CloudPaymentComponent } from './pay-pal-payment/cloud-payment/cloud-payment.component';
import { NotificationsComponent } from './user_views/notifications/notifications.component';
import { MarathonPlanComponent } from './user_views/marathon-plan/marathon-plan.component';
// import { SubscribeNavigateByUrlComponent } from './user_views/subscribe-navigate-by-url/subscribe-navigate-by-url.component';

const routes: Route[] = [
    { path: '', component: MarathonProfileComponent },
    { path: 'error', component: CloseWimdowComponent },
    { path: 'register', component: LoginComponent },
    { path: 'register/:id', component: LoginComponent },
    { path: 'register/:id/:payMarathonId/:isGift', component: LoginComponent },
    { path: 'marathonPlan/:marathonId', component: MarathonPlanComponent },
    { path: 'marathonPlan/:marathonId/:isGift', component: MarathonPlanComponent },
    { path: 'payment', component: PayPalPaymentComponent },
    { path: 'payment/:planId', component: PayPalPaymentComponent },
    { path: 'payment/:isGift', component: PayPalPaymentComponent },
    { path: 'payment/:isGift/:planId', component: PayPalPaymentComponent },
    { path: 'forgotpassword', component: ForgotpasswordComponent },
    { path: 'forgotpassword/:payMarathonId/:isGift', component: ForgotpasswordComponent },
    { path: 'errorstatus', component: ErrorpaymentStatusComponent },
    { path: 'marathon_exercise/:id', component: MarathonExerciseComponent },
    { path: 'user_marathon_day_exercise/:marathonID/:id', component: UserDailyExerciseComponent },
    { path: 'user_marathon_day_detail/:marathonID/:id', component: UserExerciseDetailComponent },
    { path: 'contest', component: ContestUploadImageComponent },
    { path: 'contest_vote', component: ContestVoteComponent },
    { path: 'robo_payment/:result', component: RoboPaymentStatusComponent },
    { path: 'cloud_payment/:invoiceId/:json', component: CloudPaymentComponent },
    { path: 'changepassword', component: ChangePasswordComponent },
    { path: 'orderlist', component: MyOrdersListComponent },
    { path: 'gotouserlogin/:token/:email', component: GotouserloginComponent },
    { path: 'gotouserlogin/:token/:email/:marathonId', component: GotouserloginComponent },
    { path: 'gift', component: GiftCouponComponent },
    { path: 'notifications', component: NotificationsComponent },
    // { path: 'subscribe_navigate_by_url/:id', component: SubscribeNavigateByUrlComponent },
];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: true });