import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'marathonListSorting'
})
export class MarathonListSortingPipe implements PipeTransform {
  transform(tableData: Array<any>, args?: any): any {
    
    if (tableData != null) {
      return tableData.sort(function (a, b) {
        if (a[args.property] < b[args.property]) {
          return -1 * args.direction;
        }
        else if (a[args.property] > b[args.property]) {
          return 1 * args.direction;
        }
        else {
          return 0;
        }
      });
    };
  };
}
