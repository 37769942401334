import { Injectable} from '@angular/core';
import { Http } from '@angular/http';
import { Authorizeheader } from './_helper/authorizeheader';
import { environment } from '../environment';
import { Router } from '@angular/router';
import { EncrDecrService } from '../services/encr-decr.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginErrorComponent } from 'app/login-error/login-error.component';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { AppleLoginProvider } from '../../apple.provider';

@Injectable()
export class AuthenticationService {
    user;
    private userdetail = {
        username: '', password: ''
    }
    responce: any;
    constructor(
        private EncrDecr: EncrDecrService,
        private http: Http,
        private Authorizeheader: Authorizeheader,
        private router: Router,
        public popup: MatDialog,
        private socialService: SocialAuthService,
    ) {
        

        this.socialService.authState.subscribe((user) => {
            console.log('user',user);
            this.user = user;
          });
    }
    

    /*Admin login authentication */
    login(userDetail) {
        this.userdetail = { username: userDetail.username, password: userDetail.password }
        this.EncrDecr.set('123456$#@$^@1ERF', userDetail.username);
        this.EncrDecr.set('123456$#@$^@1ERF', userDetail.password);
        return this.http.post(environment.baseUrl + 'Token/auth', userDetail, { headers: this.Authorizeheader.createHeader() })
    }
    
    async googleLogin(userId){
        
         return this.socialService.signIn(GoogleLoginProvider.PROVIDER_ID)
         .then(()=>{
            if(this.user){
                return this.http.get(environment.baseUrl + 'token/login-with-gmail' ,{  
                    params: {
                    providerToken: this.user.idToken,
                    playerId: userId,
                    requestFrom: "web"
                  } })
            }  
        })
        .catch(err => {
            return err;
        });
      }
    
      facebookLogin(userId){
        return this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID).then(()=>{
            if(this.user){
                return this.http.get(environment.baseUrl + 'token/login-with-facebook' ,{  
                    params: {
                    providerToken: this.user.authToken,
                    playerId: userId,
                    requestFrom: "web"
                  } })
            } 
        })
        .catch(err => {
           return err;
        });
      }

      appleLogin(userId){
        return this.socialService.signIn(AppleLoginProvider.PROVIDER_ID).then(()=>{
            if(this.user && this.user.authorization){
                return this.http.get(environment.baseUrl + 'token/login-with-apple' ,{  
                    params: {
                    firstName: this.user.givenName || '',
                    lastName: this.user.familyName || '',
                    email: this.user.email || '',
                    providerToken: this.user.authorization.id_token,
                    playerId: userId,
                    requestFrom: "web"
                  } })
            } 
        })
        .catch(err => {
           return err;
        });
      }

    /**manage refresh token  */
    RefreshtokenManage() {
        var encEmail = localStorage.getItem('rememberemail');
        var encPassword = localStorage.getItem('rememberPassword');
        var userEmail = this.EncrDecr.get('123456$#@$^@1ERF', encEmail);
        var userPassword = this.EncrDecr.get('123456$#@$^@1ERF', encPassword);
        let refresh_token = localStorage.getItem("refresh_token");
        let user = {
            "grant_type": "refresh_token",
            "refresh_token": refresh_token,
            "username": userEmail,
            "password": userPassword,
            requestFrom:"web"
        }
        return this.http.post(environment.baseUrl + 'Token/auth', user)   
    }
    GetHtmlFromUrlApi() {
        return this.http.get('http://api.tildacdn.info/v1/getpage/?publickey=ar6rh1ikv4saoxwncupp&secretkey=oawj0r17pai02orkp94l&pageid=7288368').toPromise()
    }

    UpdateProfile(data) {
        return this.http.post(environment.baseUrl + 'User/UpdateProfile', data, { headers: this.Authorizeheader.createHeaderForMarathon() }).toPromise()
    }
    /**edit admin profile data */
    editprofile(editprofiledata) {
        return this.http.post(environment.baseUrl + 'Admin/UpdateProfile', editprofiledata, { headers: this.Authorizeheader.createHeaderForMarathon() }).toPromise()
    }

    /**change admin password and confirm new one */
    changepassword(changepassworddata) {
        return this.http.post(environment.baseUrl + 'User/UserChangepassword', changepassworddata, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    /**craete faq for miyabi marathon */
    createFaq(createfaqdata) {
        return this.http.post(environment.baseUrl + 'GeneralSetting/CreateFAQ', createfaqdata, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    /**get admin profile data */
    getadminprofiledata() {
        return this.http.get(environment.baseUrl + 'Admin/GetProfileDetail', { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    /**get admin Faq data */
    get_faq_data() {
        return this.http.get(environment.baseUrl + 'GeneralSetting/GetFAQs', { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    /**update admin Faq data */
    updatefaqdata(data) {
        return this.http.post(environment.baseUrl + 'GeneralSetting/UpdateFAQ', { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    /**delete faq for miyabi marathon */
    deletefaqdata(id) {
        return this.http.post(environment.baseUrl + 'GeneralSetting/DeleteFAQ', id, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    /**get user detail for refreshToken */
    getUserDetails() {
        return this.userdetail
    }
    errormessag(error) {
        let errorRout = this.router;
        if (error.status == 401) {
            
            this.router.navigateByUrl('/register/userlogin');  

            if (errorRout.url != "/register/userlogin"){
                this.popup.open(LoginErrorComponent)
            }

        } else {
            return {showError :true,errormessag:error.text().replace("Object reference not set to an instance of an object.", 'Сделайте скриншот и свяжитесь с поддержкой, пожалуйста. Обновите страницу.')}
        }
    }
}

