import { Component, OnInit, ViewEncapsulation, HostListener, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { MarathonService } from '../../services/marathon.service';
import { Router } from '@angular/router';
import { LanguageService } from '../../services/language.service';
import { ImageCroppedEvent } from '../contest/contest-upload-image/image-cropper/interfaces/image-cropped-event.interface';
import { ImageCropperComponent } from '../contest/contest-upload-image/image-cropper/component/image-cropper.component';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChangePasswordComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = false;
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  password: any;
  newPassword: any;
  confirmPassword: any;
  showLoading: boolean;
  description = { description: '', marathonId: '', marathonTitle: '', isContestParticipated: '' };
  exerciseerror: boolean
  errorMessageS: boolean;
  errorMessageShow: any;
  email: any;
  name: any;
  Surname: any;
  PlayerChoice: any;
  Successfully: string;
  Success: boolean;
  showdropdowemenu: boolean;
  showcontesturl: boolean;
  languageType: any;
  langCode: string;
  isFade: boolean;
  currentInput: any;
  isImageUpload: boolean;
  imageSrc: string | ArrayBuffer;
  currenUserImage: any;
  fd: any;
  isImageUpdate: boolean;
  currentPasswordInput: any;
  extension: string;
  marathonId: string;
  isContest: string;

  constructor(private router: Router, private language: LanguageService, private authenticationService: AuthenticationService, public _MarathonService: MarathonService, ) { }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    var blob = this.dataURItoBlob(this.croppedImage);
    this.isImageUpdate = true;
    this.fd = new FormData(document.forms[0]);
    this.fd.append("file", blob, 'file.png');
  }
  uploadImag() {
    this.isImageUpload = false
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      // this.show = true;
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }
  imageLoaded() {
    this.showCropper = true;
  }
  cropperReady() {
  }
  loadImageFailed() {
  }
  rotateLeft() {
    this.imageCropper.rotateLeft();
  }
  rotateRight() {
    this.imageCropper.rotateRight();
  }
  flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }
  flipVertical() {
    this.imageCropper.flipVertical();
  }

  showimages() {
    var img = this.imageCropper.crop();
  }
  ngOnInit() {
    this.extension = localStorage.getItem('extension');
    this.isContest = localStorage.getItem('isContest');

    this.marathonId = localStorage.getItem("marathonid");
    var data = localStorage.getItem("language");
    if (data != null) {
      this.langCode = data;
     this.language.language(data).then((response) => {
        this.languageType = response
      });
    } else {
      this.langCode = "Rus";
       this.language.language("Rus").then((response) => {
        this.languageType = response
      });
    }
    if (localStorage.getItem("orderStatus") == 'Approved') {
      this.showcontesturl = true;
    } else {
      this.showcontesturl = false;
    }
    this.showUserProfileDetail();
  }
  ChangecontestParticpentStatus(event, descriptionValue) {
    this.showLoading = true;
    let data = {
      marathonId: this.marathonId,
      isContestParticipated: event
    }
    this._MarathonService.particpentStatus(data).then(response => {

      this.showLoading = false;
      descriptionValue.isContestParticipated = event

    }, error => {
      this.showLoading = false;

    })
  }

  showUserProfileDetail() {
    this.showLoading = true;
    this._MarathonService.GetUserProfileDetail().subscribe(response => {
      this.showLoading = false;
      let getUserDetal = response.json();

      this.croppedImage = getUserDetal.profilePicture

      this.email = getUserDetal.email
      this.name = getUserDetal.firstName
      this.Surname = getUserDetal.lastName
      this.PlayerChoice = getUserDetal.videoServer
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.showLoading = false;
        this.errorMessageS = true;
        this.errorMessageShow = status.errormessag;
      }
    });
  }

  english() {
    localStorage.setItem("language", "Eng")
    this.langCode = "Eng"
     this.language.language("Eng").then((response) => {
      this.languageType = response
    });
  }
  onButtonclick(event) {
    this.currentInput = event
  }
  onButtonClickPassword(event) {
    this.currentPasswordInput = event;
  }
  russian() {
    localStorage.setItem("language", "Rus")
    this.langCode = "Rus"
    this.language.language("Rus").then((response) => {
      this.languageType = response
    });
  }
  changeUserImage() {
    this.isImageUpload = true
  }
  readURL(event) {

    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.currenUserImage = event.target.files[0]
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;
      reader.readAsDataURL(file);
      this.isImageUpload = false;
    }
  }

  italian() {
    localStorage.setItem("language", "Ita")
    this.langCode = "Ita"
  this.language.language("Ita").then((response) => {
      this.languageType = response
    });
  }

  changeUserProfileDetail() {
    this.showLoading = true;
    let data = {
      email: this.email,
      firstName: this.name,
      lastName: this.Surname,
      videoServer: this.PlayerChoice
    }
    this.croppedImage
    if (!this.isImageUpdate) {
      this.fd = new FormData();
    }
    this.fd.append('model', JSON.stringify(data));
    this.authenticationService.UpdateProfile(this.fd).then(response => {
      this.showLoading = false;
      this.errorMessageS = false;
      this.Success = true;
      this.Successfully = "Личная информация изменена"
      let a = document.getElementById('showpopup')
      a.click();
      let getUserDetal = response['_body'];
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.Success = false;
        this.showLoading = false;
        this.errorMessageS = true;
        this.errorMessageShow = status.errormessag;
      }
    });
  }

  changePassword() {
    this.showLoading = true;
    let data = {
      password: this.password,
      newPassword: this.newPassword,
      confirmPassword: this.confirmPassword,
    }
    this.authenticationService.changepassword(data).then(response => {
      this.showLoading = false;
      this.errorMessageS = false;
      this.Success = true;
      this.Successfully = "Пароль успешно изменен";
      let a = document.getElementById('showpopup');
      a.click();
      let data = response.json();
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.Success = false
        this.showLoading = false;
        this.errorMessageS = true;
        this.errorMessageShow = status.errormessag;
      }
    })
  }


  showdropdowe() {
    this.showdropdowemenu = !this.showdropdowemenu
  }
  logout() {
    localStorage.clear();
    this.router.navigateByUrl('/register/userlogin');
  }
  notifications() {
    localStorage.setItem('url', this.router.url)
    this.router.navigateByUrl('/notifications');
  }

  changePassword1() {
    this.router.navigateByUrl('/changepassword');
  }
  navigateBymarathon() {
    let id = localStorage.getItem("marathonid")
    if (localStorage.getItem("orderStatus") == 'Approved') {
      this.router.navigateByUrl('/marathon_exercise/' + id);
    } else {
      this.router.navigateByUrl('/payment');
    }
  }
  navigateByurlpage() {
    let url = localStorage.getItem("url")
    this.router.navigateByUrl(url);
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    this.showdropdowemenu = false;
  }
  back() {
    let a = localStorage.getItem("url")
    this.router.navigateByUrl(a);
  }

  moveUp() {
    window.scrollBy(0, -40000);
  }
  contest() {
    this.router.navigateByUrl('/contest');
  }

  contestvote() {
    this.router.navigateByUrl('/contest_vote');
  }

  dropdowenclose() {
    this.showdropdowemenu = false
  }
  myOrder() {
    this.router.navigateByUrl('/orderlist');
  }
  checkContestShowAndHindden(){
    if(localStorage.getItem('productType') === 'Marathon'){
      if (localStorage.getItem('accessContest')  == 'true') {
        return 'Contest';
      } else {
        return '';
      }
    }else{
      return 'Photo diary';
    }
  }
}
