import { Component, OnInit, HostListener } from '@angular/core';
import { MarathonService } from '../../../services/marathon.service'
import { AuthenticationService } from '../../../services/authentication.service';
import { Router } from '@angular/router';
import { LanguageService } from '../../../services/language.service';

@Component({
  selector: 'app-contest-vote',
  templateUrl: './contest-vote.component.html',
  styleUrls: ['./contest-vote.component.scss']
})
export class ContestVoteComponent implements OnInit {
  pollVote: boolean;
  imagespath: any;
  userId: any;
  allFinalist: any;
  marathonId: any;
  ContestId: any;
  description = { description: '', marathonId: '', marathonTitle: '', isContestParticipated: '' };
  exerciseerror: boolean;
  name: any;
  votedata: any[] = []
  show: boolean;
  iserror: boolean;
  Error: any;
  showdropdowemenu: boolean;
  isShowVote: boolean;
  languageType: any;
  langCode: string;
  isContest: string;
  showimageszome: boolean;
  zomeimages:any;

  constructor(private language: LanguageService, private router: Router, private authenticationService: AuthenticationService, private marathonService: MarathonService) { }

  ngOnInit() {
    var data = localStorage.getItem("language");
    this.isContest = localStorage.getItem('isContest');
    this.marathonId = localStorage.getItem("marathonid");
    if (data != null) {
      this.langCode = data;
      this.language.language(data).then((response) => {
        this.languageType = response
      });
    } else {
      this.langCode = "Rus";
      this.language.language("Rus").then((response) => {
        this.languageType = response
      });
    }
    this.allFinalistList();
    this.getcontest();
    this.getvotedate();
  }


  ChangecontestParticpentStatus(event, descriptionValue) {
    this.show = true;
    let data = {
      marathonId: this.marathonId,
      isContestParticipated: event
    }
    this.marathonService.particpentStatus(data).then(response => {
      this.show = false;
      descriptionValue.isContestParticipated = event
    }, error => {
      this.show = false;
    })
  }

  getvotedate() {
    this.show = true;
    if (localStorage.getItem('newmarathonid') == null) {
      var id = localStorage.getItem('marathonid')
    } else {
      var id = localStorage.getItem('newmarathonid')
    }
    this.marathonService.getMarathonTitle(id).then(response => {
      this.show = false;
      var data = response.json();
      if (data.votingLastDate != null) {
        let FD = data.votingLastDate;
        let NFD = new Date(FD);
        let newFD = (NFD.setDate(NFD.getDate())) / 1000 + 84600;
        let ND = new Date();
        let newND = (ND.setDate(ND.getDate())) / 1000;
        if (newFD < newND) {
          this.isShowVote = true
        }
      }
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        this.Error = status.errormessag;
      }
    });
  }
  getcontest() {
    this.show = true;
    let id = localStorage.getItem('marathonid')
    this.marathonService.GetContest(id).then(res => {
      this.show = false;
      this.iserror = false;
      let ContestId = res.json();
      this.ContestId = ContestId.id;
    }), error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        this.Error = status.errormessag;
      }
    }
  }
  allFinalistList() {
    this.iserror = false;
    if (localStorage.getItem('newmarathonid') == null) {
      var id = localStorage.getItem('marathonid')
    } else {
      var id = localStorage.getItem('newmarathonid')
    }
    this.show = true;
    this.marathonService.allFinalistList(id).then(response => {
      this.show = false;
      this.iserror = false;
      this.allFinalist = response.json();
      this.imagespath = this.allFinalist.items[0].finalImagePath;
      this.userId = this.allFinalist.items[0].userId;
      this.marathonId = this.allFinalist.items[0].marathonId;
      this.pollVote = this.allFinalist.items[0].isVoted
      this.name = this.allFinalist.items[0].fullName
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        this.Error = status.errormessag;
      }
    });
  }
  pollYourVote() {
    if (!this.isShowVote) {
      this.pollVote = !this.pollVote
      let data = {
        "contestId": this.ContestId,
        "marathonId": this.marathonId,
        "finalistId": this.userId,
        "isActive": this.pollVote,
      }
      this.show = true;
      this.marathonService.Vote(data).then(response => {
        this.show = false;
        this.iserror = false;
        let data = response['_body'];
      }), error => {
        let status = this.authenticationService.errormessag(error);
        if (status.showError) {
          this.show = false;
          this.iserror = true;
          this.Error = status.errormessag;
        }
      }
    } else {
      this.pollVote = this.pollVote
    }
  }

  allFinalistListnew() {
    this.show = true;
    this.marathonService.allFinalistList(localStorage.getItem("marathonid")).then(response => {
      this.show = false;
      this.iserror = false;
      this.allFinalist = response.json();
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        this.Error = status.errormessag;
      }
    });
  }

  english() {
    localStorage.setItem("language", "Eng")
    this.langCode = "Eng"
    this.language.language("Eng").then((response) => {
      this.languageType = response
    });
  }

  russian() {
    localStorage.setItem("language", "Rus")
    this.langCode = "Rus"
    this.language.language("Rus").then((response) => {
      this.languageType = response
    });
  }
  italian() {
    localStorage.setItem("language", "Ita")
    this.langCode = "Ita"
   this.language.language("Ita").then((response) => {
      this.languageType = response
    });
  }

  nextuserImages() {
    if (this.pollVote) {
      this.pollVote = false
    }
    this.allFinalistListnew();
    for (let index = 0; index < this.allFinalist.items.length; index++) {
      if (this.allFinalist.items.length <= this.allFinalist.items.length && this.allFinalist.items[index].userId == this.userId) {
        const element = this.allFinalist.items[index + 1];
        if (element != undefined) {
          this.imagespath = element.finalImagePath;
          this.userId = element.userId;
          this.pollVote = element.isVoted;
          this.name = element.fullName;
          break
        }
      }
    }
  }

  previoudimaegs() {
    if (this.pollVote) {
      this.pollVote = false
    }
    this.allFinalistListnew();
    for (let index = 0; index < this.allFinalist.items.length; index++) {
      if (this.allFinalist.items.length <= this.allFinalist.items.length && this.allFinalist.items[index].userId == this.userId) {
        const element = this.allFinalist.items[index - 1];
        if (element != undefined) {
          this.imagespath = element.finalImagePath;
          this.userId = element.userId;
          this.marathonId = element.marathonId;
          this.pollVote = element.isVoted;
          this.name = element.fullName;
          break;
        }
      }
    }
  }

  showdropdowe() {
    this.showdropdowemenu = !this.showdropdowemenu;
  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl('/register/userlogin');
  }

  notifications() {
    localStorage.setItem('url', this.router.url)
    this.router.navigateByUrl('/notifications');
  }

  changePassword() {
    localStorage.setItem("url", this.router.url);
    this.router.navigateByUrl('/changepassword');
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    this.showdropdowemenu = false;
  }

  moveUp() {
    window.scrollBy(0, -40000);
  }

  contest() {
    if (localStorage.getItem("orderStatus") == 'Approved') {
      this.router.navigateByUrl('/contest');
    } else {
      this.router.navigateByUrl('/payment');
    }
  }

  contestvote() {
    this.router.navigateByUrl('/contest_vote');
  }
  marathon() {
    if (localStorage.getItem("orderStatus") == 'Approved') {
      this.router.navigateByUrl('/marathon_exercise/' + localStorage.getItem('marathonid'));
    } else {
      this.router.navigateByUrl('/payment');
    }
  }
  dropdowenclose() {
    this.showdropdowemenu = false;
  }
  myOrder() {
    this.router.navigateByUrl('/orderlist');
  }
  checkContestShowAndHindden(){
    if(localStorage.getItem('productType') === 'Marathon'){
      if (localStorage.getItem('accessContest') == 'true') {
        return 'Contest';
      } else {
        return '';
      }
    }else{
      return 'Photo diary';
    }
  }
  closeimageszome() {
    this.showimageszome = false;
  }

}
