import { Component, OnInit, ViewChild, HostListener, ViewEncapsulation, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MarathonService } from '../../../services/marathon.service'
import { ImageCroppedEvent } from './image-cropper/interfaces/image-cropped-event.interface';
import { ImageCropperComponent } from './image-cropper/component/image-cropper.component';
import { AuthenticationService } from '../../../services/authentication.service';
import { LanguageService } from '../../../services/language.service';
import * as FileSaver from 'file-saver';
import { Router } from '@angular/router';
import { Guid } from "guid-typescript";
import heic2any from "heic2any";

@Component({
  selector: 'app-contest-upload-image',
  templateUrl: './contest-upload-image.component.html',
  styleUrls: ['./contest-upload-image.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContestUploadImageComponent implements OnInit {
  description = { description: '', marathonId: '', marathonTitle: '', isContestParticipated: '' };
  private downloadZipLink: ElementRef
  imageChangedEvent: any = '';
  downloadImageas: any;
  croppedImage: any = '';
  showCropper = false;
  isUserTaggedWithYoungBody:any; 

  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  fd: any;
  showimageszome: boolean;
  zomeimages: any;
  exerciseerror: boolean;
  ImagePostiondata: any;
  getMarathons: any;
  iserror: boolean;
  Error: any;
  imageBase64: any;
  show: boolean;
  showdropdowemenu: boolean;
  croppedresultphase: any;
  autoCrop = false

  @ViewChild('myInput')
  myInputVariable: ElementRef;
  isShowVote: boolean;
  tempbase64: string;
  languageType: any;
  langCode: string;
  withoutCropingUrl: boolean;
  wCropingUrl: any;
  fileArray: any;
  isNewImage: boolean;
  marathonId: string;
  error: any;
  uploadUserMaskImagesAnimation: boolean;
  uploadUserMaskImagesData: any;
  isShowphotoDiary: boolean;


  fileChangeEvent(event: any): void {
    if(event && event.target && event.target.files[0].name){
      if(event.target.files[0].name.search('.heic') != -1){
    let f:File;

    //RECEIVE IMAGE
  
    if (event[0] && (event[0]['lastModified'] || event[0]['lastModifiedDate'])) {
      f = event[0];
      if (event.length > 1) {
        event.splice(0,event.length-1);
        f = event[0];
      }
    } else if (event.target && event.target.files && event.target.files[0]) {
      f = event.target.files[0];
    }
  
    if (!f) {
      //Handle error and exit
    }
  
    let blob:Blob = f;
    let file:File = f;
  
    let convProm:Promise<any>;
    
    //CONVERT HEIC TO JPG
  
    // if (/image\/hei(c|f)/.test(f.type)) {
      convProm = heic2any({blob,toType:"image/jpeg",quality:100000000}).then((jpgBlob:Blob) => {
  
        //Change the name of the file according to the new format
        let newName = f.name.replace(/\.[^/.]+$/, ".jpg");
  
        //Convert blob back to file
        file = this.blobToFile(jpgBlob,newName);
        this.isNewImage = true
        this.imageChangedEvent = file;
        this.wCropingUrl = event.target.files[0]
      }).catch(err => {
        //Handle error
      });
    }
    else{
      this.isNewImage = true
      this.imageChangedEvent = event.target.files[0];
      this.wCropingUrl = event.target.files[0]
    }
  }
   
    // this.show = false;

  }
  private blobToFile = (theBlob: Blob, fileName:string): File => {
    let b: any = theBlob;
  
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModified = new Date();
    b.name = fileName;
  
    //Cast to a File() type
    return <File>theBlob;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    var blob = this.dataURItoBlob(this.croppedImage);
    var fd = new FormData(document.forms[0]);
    fd.append("canvasImage", blob, 'file.png');
    this.fd = fd.get('canvasImage');
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      this.show = true;
      ia[i] = byteString.charCodeAt(i);
    }
    // 
    return new Blob([ia], { type: mimeString });
  }
  imageLoaded() {
    this.showCropper = true;
  }
  cropperReady() {
  }
  loadImageFailed() {
  }
  rotateLeft() {
    this.imageCropper.rotateLeft();
  }
  rotateRight() {
    this.imageCropper.rotateRight();
  }
  flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }
  flipVertical() {
    this.imageCropper.flipVertical();
  }


  data: any;
  // public cropperConfig: CropperOption;
  leftFace: any;
  imagePreview: any;
  imagePreviewRight: any;
  imagePreviewState: any;
  imagePreviewSecondLeft: any;
  imagePreviewSecondstate: any;
  imagePreviewSecondRight: any;
  imagePreviewThirdLeft: any;
  imagePreviewThirdState: any;
  imagePreviewThirdRight: any;
  ContestId: any;
  Contestdata: any;
  imagePreviewStat: any;
  updateimagePreviewState: any;
  updateimagePreviewSecondstate: any;
  updateimagePreviewThirdRight: any;
  updateimagePreviewRight: any;
  updateimagePreviewSecondRight: any;
  updateimagePreviewThirdState: any;
  updateimagesid: any;
  imagePreviewThirdRight12: any;
  imagePreviewThirdRight11: any;
  imagePreviewThirdRight10: any;
  imagePreviewThirdRight9: any;
  imagePreviewThirdState8: any;
  imagePreviewThirdLeft7: any;
  updateimagePreviewThirdState7: any;
  imagePreviewThirdState7: any;
  updateimagePreviewThirdRight8: any;
  imagePreviewThirdRight8: any;
  imagePreviewThirdState9: any;
  updateimagePreviewThirdState9: any;
  updateimagePreviewThirdRight10: any;
  imagePreviewThirdState11: any;
  updateimagePreviewThirdState11: any;
  updateimagePreviewThirdRight12: any;
  showeditor: boolean;
  imhsajdjsbjcbd: any;
  imagechangedEvent: any;
  isContest: string

  constructor(private language: LanguageService, private router: Router, private authenticationService: AuthenticationService, private _DomSanitizationService: DomSanitizer, private marathonService: MarathonService) {
  }



  ngOnInit() {
    this.iserror = false;
    var data = localStorage.getItem("language");
    this.isContest = localStorage.getItem('isContest')
    this.marathonId = localStorage.getItem("marathonid")
    if (data != null) {
      this.langCode = data;
      this.language.language(data).then((response) => {
        this.languageType = response
      });
    } else {
      this.langCode = "Rus";
      this.language.language("Rus").then((response) => {
        this.languageType = response
      });
    }
    this.getcomtestId();
    this.getvotedate();
    this.getDescriptionOfMarathon();
    this.GetUserTaggedWithYoungBody();
  }
  ChangecontestParticpentStatus(event, descriptionValue) {
    this.show = true;
    let data = {
      marathonId: this.marathonId,
      isContestParticipated: event
    }
    this.marathonService.particpentStatus(data).then(response => {
      this.show = false;
      debugger
      this.ContestId.isContestParticipated = event;
    }, error => {
      this.show = false;
    })
  }
  acceptContestRules(event, descriptionValue) {
    this.show = true;
    let data = {
      marathonId: this.marathonId,
      contestRulesAccepted: event
    }
    this.marathonService.acceptContestRules(data).then(response => {
      this.show = false;
      // descriptionValue.isContestParticipated = event
    }, error => {
      this.show = false;
    })
  }

  fileChangeEventApiCall(event: any): void {
    this.uploadUserMaskImagesData = null;
    // document.getElementById('dismissMyModalApiImagesCropper').click();
    let masktype;
    if (this.ImagePostiondata === 1 || this.ImagePostiondata === 2) {
      masktype = "front";
    } else if (this.ImagePostiondata === 3 || this.ImagePostiondata === 4) {
      masktype = "3/4left";
    } else if (this.ImagePostiondata === 5 || this.ImagePostiondata === 6) {
      masktype = "left";
    } else if (this.ImagePostiondata === 7 || this.ImagePostiondata === 8) {
      masktype = "3/4right";
    } else if (this.ImagePostiondata === 9 || this.ImagePostiondata === 10) {
      masktype = "right";
    } else {
      masktype = "back";
    }
    this.show = true;
    let model;
    if(this.getMarathons.productType === "Course"){
 model = {
      'marathonId': this.marathonId,
      'masktype': masktype,
      'timeZoneOffSet': null,
      'ImagePostion': this.ImagePostiondata
    }
    }else{
       model = {
      'marathonId': this.marathonId,
      'ContestId': this.ContestId.id,
      'masktype': masktype,
      'timeZoneOffSet': null,
      'ImagePostion': this.ImagePostiondata
    }
    }
   

    const fd = new FormData();
    fd.append('model', JSON.stringify(model));
    fd.append('file', event.target.files[0])
    this.uploadUserMaskImagesAnimation = true;
    this.marathonService.uploadUserMaskImages(fd).then(response => {
      this.show = false;
      this.uploadUserMaskImagesData = response.json();
      this.uploadUserMaskImagesAnimation = false;
      // descriptionValue.isContestParticipated = event
    }, error => {
      this.show = false;
      this.uploadUserMaskImagesAnimation = false;
    })
  }
  uploadFinalImage(): void {
    this.iserror = false;
    // document.getElementById('dismissMyModalApiImagesCropper').click();
    let masktype;
    if (this.ImagePostiondata === 1 || this.ImagePostiondata === 2) {
      masktype = "front";
    } else if (this.ImagePostiondata === 3 || this.ImagePostiondata === 4) {
      masktype = "3/4left";
    } else if (this.ImagePostiondata === 5 || this.ImagePostiondata === 6) {
      masktype = "left";
    } else if (this.ImagePostiondata === 7 || this.ImagePostiondata === 8) {
      masktype = "3/4right";
    } else if (this.ImagePostiondata === 9 || this.ImagePostiondata === 10) {
      masktype = "right";
    } else {
      masktype = "back";
    }
    this.show = true;
    let model;
    if(this.getMarathons.productType === "Course" ){
   model = {
      'marathonId': this.marathonId,
      'masktype': masktype,
      'timeZoneOffSet': null,
      'ImagePostion': this.ImagePostiondata
    }
    }else{
    model = {
      'marathonId': this.marathonId,
      'ContestId': this.ContestId.id,
      'masktype': masktype,
      'timeZoneOffSet': null,
      'ImagePostion': this.ImagePostiondata
    }

    }
    const fd = new FormData();
    fd.append('model', JSON.stringify(model));
    fd.append('fileName', this.uploadUserMaskImagesData[0]);
    // fd.append('filePath', this.uploadUserMaskImagesData[1]);
    fd.append('ImgPath', this.uploadUserMaskImagesData[1]);
    // this.uploadUserMaskImagesAnimation = true;
    this.marathonService.confirmContestMaskImages(fd).then(response => {
      this.show = false;
      // this.uploadUserMaskImagesData = response.json();
      this.getcomtestId();
      // this.uploadUserMaskImagesAnimation = false;
      // descriptionValue.isContestParticipated = event
    }, error => {
      this.Error = error['_body'];
      this.show = false;
      this.iserror = true;
      // this.uploadUserMaskImagesAnimation = false;
    })
  }
  downloadUserImage() {
    this.show = true;
    this.marathonService.DownloadUserImage(this.marathonId).then(response => {
      this.show = false;
      this.iserror = false;
      this.downloadImageas = response['_body'];
      FileSaver.saveAs(this.downloadImageas, Guid.create());
    }, error => {
      this.error = error['_body'];
      this.show = false;
    });
  }

  GetUserTaggedWithYoungBody(){
    this.marathonService.GetUserTaggedWithYoungBody(this.marathonId).then(response =>{
      console.log(response.json());
      this.isUserTaggedWithYoungBody = response.json().tag === 'Young Body';
    })
  }

  getDescriptionOfMarathon() {
    this.show = true;
    this.marathonService.getDescription(this.marathonId).then(response => {
      this.show = false;
      this.description = response.json();
    }, error => {
      this.show = false;
    })
  }
  getvotedate() {
    this.show = true;
    if (localStorage.getItem('newmarathonid') == null) {
      var id = localStorage.getItem('marathonid')
    } else {
      var id = localStorage.getItem('newmarathonid')
    }
    this.marathonService.getMarathonTitle(id).then(response => {
      this.show = false;
      var data = response.json();
      this.getMarathons = data;
      if (data.finalistAnnouncementDate != null) {
        let votingLastDate = data.votingLastDate;
        let newVotingLast = new Date(votingLastDate);
        let newVotingLastDate = (newVotingLast.setDate(newVotingLast.getDate())) / 1000;
        let plusOneDay = newVotingLastDate + 84600;
        let FD = data.finalistAnnouncementDate;
        let NFD = new Date(FD);
        let newFD = (NFD.setDate(NFD.getDate())) / 1000;
        let ND = new Date();
        let newND = (ND.setDate(ND.getDate())) / 1000;
        if (newFD < newND && plusOneDay >= newND) {
          this.isShowVote = true
        }
        let NFDLD = new Date(data.contestUploadLastDate);
        let newFDLD = (NFDLD.setDate(NFDLD.getDate())) / 1000;
        let NDLD = new Date();
        let newNDLD = (NDLD.setDate(NDLD.getDate())) / 1000;
        if (newFDLD < newNDLD) {
          this.isShowphotoDiary = false;
          debugger
        } else {
          this.isShowphotoDiary = true;
        }

      }
    }, error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        this.Error = status.errormessag;
      }
    });
  }

  // GetMarathonsId() {
  //   this.show = true;
  //   this.marathonService.GetMarathons().then(response => {
  //     this.show = false;
  //     this.iserror = false;
  //     this.GetMarathons = response.json();
  //   }, error => {
  //     let status = this.authenticationService.errormessag(error);
  //     if (status.showError) {
  //       this.show = false;
  //       this.iserror = true;
  //       this.Error = status.errormessag;
  //     }
  //   });
  // }

  english() {
    localStorage.setItem("language", "Eng")
    this.langCode = "Eng"
    this.language.language("Eng").then((response) => {
      this.languageType = response
    });
  }

  russian() {
    localStorage.setItem("language", "Rus")
    this.langCode = "Rus"
    this.language.language("Rus").then((response) => {
      this.languageType = response
    });
  }
  italian() {
    localStorage.setItem("language", "Ita")
    this.langCode = "Ita"
    this.language.language("Ita").then((response) => {
      this.languageType = response
    });
  }

  getcomtestId() {
    this.show = true;
    if (localStorage.getItem('newmarathonid') == null) {
      var id = localStorage.getItem('marathonid')
    } else {
      var id = localStorage.getItem('newmarathonid')
    }
    this.marathonService.GetContest(id).then(res => {
      this.iserror = false;
      this.show = false;
      this.ContestId = res.json();
      if (this.ContestId != null) {
        this.imagesshow(this.ContestId.id, id);
      }
      // this.ContestId.isAutoCrop = true;
    }), error => {
      let status = this.authenticationService.errormessag(error);
      if (status.showError) {
        this.show = false;
        this.iserror = true;
        this.Error = status.errormessag;
      }
    }
  }

  imagesshow(contestId, marathonId) {
    this.marathonService.GetUserContestImages(contestId, marathonId).then(response => {
      this.Contestdata = response.json();
      for (let index = 0; index < this.Contestdata.length; index++) {
        if (this.Contestdata[index].imagePostion == 1) {
          this.imagePreviewState = true;
          this.imagePreviewState = this.Contestdata[index].imagePath;
          this.updateimagePreviewState = this.Contestdata[index].id;
        } else if (this.Contestdata[index].imagePostion == 2) {
          this.imagePreviewRight = true;
          this.imagePreviewRight = this.Contestdata[index].imagePath;
          this.updateimagePreviewRight = this.Contestdata[index].id;
        } else if (this.Contestdata[index].imagePostion == 3) {
          this.imagePreviewSecondstate = true;
          this.imagePreviewSecondstate = this.Contestdata[index].imagePath;
          this.updateimagePreviewSecondstate = this.Contestdata[index].id;
        } else if (this.Contestdata[index].imagePostion == 4) {
          this.imagePreviewSecondRight = true;
          this.imagePreviewSecondRight = this.Contestdata[index].imagePath;
          this.updateimagePreviewSecondRight = this.Contestdata[index].id;
        } else if (this.Contestdata[index].imagePostion == 5) {
          this.imagePreviewThirdState = true;
          this.imagePreviewThirdState = this.Contestdata[index].imagePath;
          this.updateimagePreviewThirdState = this.Contestdata[index].id;
        } else if (this.Contestdata[index].imagePostion == 6) {
          this.imagePreviewThirdRight = true;
          this.imagePreviewThirdRight = this.Contestdata[index].imagePath;
          this.updateimagePreviewThirdRight = this.Contestdata[index].id;
        } else if (this.Contestdata[index].imagePostion == 7) {
          this.imagePreviewThirdState7 = true;
          this.imagePreviewThirdState7 = this.Contestdata[index].imagePath;
          this.updateimagePreviewThirdState7 = this.Contestdata[index].id;
        } else if (this.Contestdata[index].imagePostion == 8) {
          this.imagePreviewThirdRight8 = true;
          this.imagePreviewThirdRight8 = this.Contestdata[index].imagePath;
          this.updateimagePreviewThirdRight8 = this.Contestdata[index].id;
        } else if (this.Contestdata[index].imagePostion == 9) {
          this.imagePreviewThirdState9 = true;
          this.imagePreviewThirdState9 = this.Contestdata[index].imagePath;
          this.updateimagePreviewThirdState9 = this.Contestdata[index].id;
        } else if (this.Contestdata[index].imagePostion == 10) {
          this.imagePreviewThirdRight10 = true;
          this.imagePreviewThirdRight10 = this.Contestdata[index].imagePath;
          this.updateimagePreviewThirdRight10 = this.Contestdata[index].id;
        } else if (this.Contestdata[index].imagePostion == 11) {
          this.imagePreviewThirdState11 = true;
          this.imagePreviewThirdState11 = this.Contestdata[index].imagePath;
          this.updateimagePreviewThirdState11 = this.Contestdata[index].id;
        } else if (this.Contestdata[index].imagePostion == 12) {
          this.imagePreviewThirdRight12 = true;
          this.imagePreviewThirdRight12 = this.Contestdata[index].imagePath;
          this.updateimagePreviewThirdRight12 = this.Contestdata[index].id;
        }
      }
    })
  }

  closedadb() {
    document.getElementById("modal").style.display = "none";
  }

  showdatapogi(data) {
    this.uploadUserMaskImagesData = null;
    this.imageChangedEvent = null;
    this.myInputVariable.nativeElement.value = "";
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.ImagePostiondata = data;
    this.Classes();
  }

  showimages(Postiondata) {
    if (Postiondata == undefined) {
      this.show = true;
      var img = this.imageCropper.crop();
      this.uploadImage(this.ImagePostiondata)
    } else {
      this.show = true;
      var img = this.imageCropper.crop();
      this.uploadImage(Postiondata)
    }
  }
  withoutCroping(ImagePostiondata) {
    if (ImagePostiondata == undefined) {
      this.show = true;
      this.withoutCropingUrl = true
      this.uploadImage(ImagePostiondata)
    } else {
      this.show = true;
      this.withoutCropingUrl = true
      this.uploadImage(ImagePostiondata)
    }
  }

  /**onclicl select image upload */
  uploadImage(event) {
    if (event == 1) {
      if (this.withoutCropingUrl) {
        this.fd = this.wCropingUrl
        this.imagePreviewState = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this.wCropingUrl))
      } else {
        this.imagePreviewState = this.croppedImage
      }
    }
    else if (event == 2) {
      if (this.withoutCropingUrl) {
        this.fd = this.wCropingUrl
        this.imagePreviewRight = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this.wCropingUrl))
      } else {
        this.imagePreviewRight = this.croppedImage
      }
    }

    else if (event == 3) {
      if (this.withoutCropingUrl) {
        this.fd = this.wCropingUrl
        this.imagePreviewSecondstate = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this.wCropingUrl))
      } else {
        this.imagePreviewSecondstate = this.croppedImage
      }

    }
    else if (event == 4) {
      if (this.withoutCropingUrl) {
        this.fd = this.wCropingUrl
        this.imagePreviewSecondRight = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this.wCropingUrl))
      } else {
        this.imagePreviewSecondRight = this.croppedImage
      }
    }
    else if (event == 5) {
      if (this.withoutCropingUrl) {
        this.fd = this.wCropingUrl
        this.imagePreviewThirdState = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this.wCropingUrl))
      } else {
        this.imagePreviewThirdState = this.croppedImage
      }
    }
    else if (event == 6) {
      if (this.withoutCropingUrl) {
        this.fd = this.wCropingUrl
        this.imagePreviewThirdRight = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this.wCropingUrl))
      } else {
        this.imagePreviewThirdRight = this.croppedImage
      }
    }
    else if (event == 7) {
      if (this.withoutCropingUrl) {
        this.fd = this.wCropingUrl
        this.imagePreviewThirdState7 = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this.wCropingUrl))
      } else {
        this.imagePreviewThirdState7 = this.croppedImage
      }
    } else if (event == 8) {
      if (this.withoutCropingUrl) {
        this.fd = this.wCropingUrl
        this.imagePreviewThirdRight8 = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this.wCropingUrl))
      } else {
        this.imagePreviewThirdRight8 = this.croppedImage
      }
    } else if (event == 9) {
      if (this.withoutCropingUrl) {
        this.fd = this.wCropingUrl
        this.imagePreviewThirdState9 = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this.wCropingUrl))
      } else {
        this.imagePreviewThirdState9 = this.croppedImage
      }
    }
    else if (event == 10) {
      if (this.withoutCropingUrl) {
        this.fd = this.wCropingUrl
        this.imagePreviewThirdRight10 = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this.wCropingUrl))
      } else {
        this.imagePreviewThirdRight10 = this.croppedImage
      }
    }
    else if (event == 11) {
      if (this.withoutCropingUrl) {
        this.fd = this.wCropingUrl
        this.imagePreviewThirdState11 = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this.wCropingUrl))
      } else {
        this.imagePreviewThirdState11 = this.croppedImage
      }
    } else if (event == 12) {
      if (this.withoutCropingUrl) {
        this.fd = this.wCropingUrl
        this.imagePreviewThirdRight12 = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this.wCropingUrl))
      } else {
        this.imagePreviewThirdRight12 = this.croppedImage
      }
    }
  }

  uploadImag(e, event, uploadImageesid) {
    if (e != undefined) {
      if (uploadImageesid == undefined || uploadImageesid == null) {
        if (localStorage.getItem('newmarathonid') == null) {
          var id = localStorage.getItem('marathonid')
        } else {
          var id = localStorage.getItem('newmarathonid')
        }
        var model = {
          ContestId: this.ContestId.id,
          ImagePostion: event,
          MarathonId: id,
          isNewImage: this.isNewImage,
          OrignalPath: this.wCropingUrl.name,
          EditPath: e.name,
        }
        const fd = new FormData();
        fd.append('model', JSON.stringify(model));
        fd.append('file', e)
        fd.append('file', this.wCropingUrl);
        this.show = true;
        this.marathonService.UploadContestImages(fd).then(response => {
          this.iserror = false;
          this.show = false;
          // let ContestId = response.json();
        }, error => {
          let status = this.authenticationService.errormessag(error);
          if (status.showError) {
            this.show = false;
            this.iserror = true;
            let a = status.errormessag;
            let b = "Marathon not started!";
            if (a.toLowerCase() == b.toLowerCase()) {
              this.Error = 'Марафон ещё не начался, но Вы уже можете загрузить фотографии ДО';
            } else {
              this.Error = status.errormessag;
              let newerror = status.errormessag;
              let Error = newerror.replace(new RegExp("12:00:00 AM", "g"), "");
              this.Error = Error.replace(new RegExp("Can't upload image because upload last date is", "g"), "Загрузка фотографий возможна до : ");
            }
          }
        });
      } else {
        if (localStorage.getItem('newmarathonid') == null) {
          var id = localStorage.getItem('marathonid')
        } else {
          var id = localStorage.getItem('newmarathonid')
        }
        var data = {
          Id: uploadImageesid,
          ContestId: this.ContestId.id,
          ImagePostion: event,
          MarathonId: id,
          isNewImage: this.isNewImage,
          OrignalPath: this.wCropingUrl != undefined ? this.wCropingUrl.name : null,
          EditPath: e.name,
        }
        const fd = new FormData();
        fd.append('model', JSON.stringify(data));
        fd.append('file', e)
        this.wCropingUrl != undefined ? fd.append('file', this.wCropingUrl) : '';
        this.show = true;
        this.marathonService.UploadContestImages(fd).then(response => {
          this.iserror = false;
          this.show = false;
        }, error => {
          let status = this.authenticationService.errormessag(error);
          if (status.showError) {
            this.show = false;
            this.iserror = true;
            let a = status.errormessag;
            let b = "Marathon not started!"
            if (a.toLowerCase() == b.toLowerCase()) {
              this.Error = 'Марафон ещё не начался, но Вы уже можете загрузить фотографии ДО'
            } else {
              let newerror = status.errormessag;
              let Error = newerror.replace(new RegExp("12:00:00 AM", "g"), "");
              this.Error = Error.replace(new RegExp("Can't upload image because upload last date is", "g"), "Загрузка фотографий возможна до : ");
            }
          }
        })
      }
    }
  }

  // new api


  fileChangeEventApiCallUploadImag(event: any): void {
    if (event === undefined || event == null) {
      return;
    }
    this.uploadUserMaskImagesData = null;
    // document.getElementById('dismissMyModalApiImagesCropper').click();
    let masktype;
    if (this.ImagePostiondata === 1 || this.ImagePostiondata === 2) {
      masktype = "front";
    } else if (this.ImagePostiondata === 3 || this.ImagePostiondata === 4) {
      masktype = "3/4left";
    } else if (this.ImagePostiondata === 5 || this.ImagePostiondata === 6) {
      masktype = "left";
    } else if (this.ImagePostiondata === 7 || this.ImagePostiondata === 8) {
      masktype = "3/4right";
    } else if (this.ImagePostiondata === 9 || this.ImagePostiondata === 10) {
      masktype = "right";
    } else {
      masktype = "back";
    }
    this.show = true;
    let model = {
      'marathonId': this.marathonId,
      'ContestId': this.getMarathons.productType === "Course" ? null : this.ContestId.id,
      'masktype': masktype,
      'timeZoneOffSet': null,
      'ImagePostion': this.ImagePostiondata
    }

    const fd = new FormData();
    fd.append('model', JSON.stringify(model));
    fd.append('file', event);
    fd.append('orignalFile', this.wCropingUrl);
    this.uploadUserMaskImagesAnimation = true;
    this.marathonService.uploadUserMaskImages(fd).then(response => {
      this.show = false;
      this.uploadUserMaskImagesData = response.json();
      this.uploadFinalImage()
      this.fd = null;
      this.uploadUserMaskImagesAnimation = false;
      // descriptionValue.isContestParticipated = event
    }, error => {
      this.show = false;
      this.uploadUserMaskImagesAnimation = false;
    })
  }

  showdropdowe() {
    this.showdropdowemenu = !this.showdropdowemenu;
  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl('/register/userlogin');
  }
  notifications() {
    localStorage.setItem('url', this.router.url)
    this.router.navigateByUrl('/notifications');
  }

  changePassword() {
    localStorage.setItem("url", this.router.url)
    this.router.navigateByUrl('/changepassword');
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    this.showdropdowemenu = false
  }

  moveUp() {
    window.scrollBy(0, -40000);
  }

  contest() {
    this.router.navigateByUrl('/contest');
  }

  contestvote() {
    this.router.navigateByUrl('/contest_vote');
  }
  marathon() {
    if (localStorage.getItem("orderStatus") == 'Approved') {
      if (localStorage.getItem('newmarathonid') == null) {
        var id = localStorage.getItem('marathonid')
      } else {
        var id = localStorage.getItem('newmarathonid')
      }
      this.router.navigateByUrl('/marathon_exercise/' + id);
    } else {
      this.router.navigateByUrl('/payment');
    }
  }
  Classes() {
    switch (this.ImagePostiondata) {
      case 1:
        this.croppedresultphase = 'phase-1'
        break;
      case 2:
        this.croppedresultphase = 'phase-1'
        break;
      case 3:
        this.croppedresultphase = 'phase-4'
        break;
      case 4:
        this.croppedresultphase = 'phase-4'
        break;
      case 5:
        this.croppedresultphase = 'phase-3'
        break;
      case 6:
        this.croppedresultphase = 'phase-3'
        break;
      case 7:
        this.croppedresultphase = 'phase-2'
        break;
      case 8:
        this.croppedresultphase = 'phase-2'
        break;
      case 9:
        this.croppedresultphase = 'phase-5'
        break;
      case 10:
        this.croppedresultphase = 'phase-5'
        break;
      case 11:
        this.croppedresultphase = 'phase-6'
        break;
      case 12:
        this.croppedresultphase = 'phase-6'
        break;
      default:
        this.croppedresultphase = 'phase-1'
        break;
    }
  }

  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', 'https://cors-anywhere.herokuapp.com/' + url);
    xhr.responseType = 'blob';
    xhr.send();
  }


  onImageEdit(image, Postiondata, imageUrl) {
    this.imageBase64 = null
    this.showimageszome = false;
    this.imageChangedEvent = true
    this.toDataURL(Postiondata, (dataUrl) => {
      this.imageBase64 = dataUrl
    })
    // this.marathonService.getImageBaseString(Postiondata).then(response => {

    //   debugger
    // })
    this.Classes()
    document.getElementById('imageseditmodel').click()
  }

  // getBase64Image(img) {
  //   var canvas = document.createElement("canvas");
  //   canvas.width = img.width;
  //   canvas.height = img.height;
  //   var ctx = canvas.getContext("2d");
  //   ctx.drawImage(img, 0, 0);
  //   var dataURL = canvas.toDataURL("image/png");
  //   return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  // }

  showphotozone(images, imagePostion) {
    this.ImagePostiondata = imagePostion;
    this.showimageszome = true;
    this.zomeimages = images;
  }

  closeimageszome() {
    this.showimageszome = false;
  }

  dropdownclose() {
    this.showdropdowemenu = false
  }

  myOrder() {
    this.router.navigateByUrl('/orderlist');
  }

  gotomarathon() {
    if (localStorage.getItem("orderStatus") == 'Approved') {
      this.show = true;
      if (localStorage.getItem('newmarathonid') == null) {
        var id = localStorage.getItem('marathonid')
      } else {
        var id = localStorage.getItem('newmarathonid')
      }
      this.marathonService.getUserMarathonDetail(id).then(response => {
        this.show = false;
        var data = response.json()
        localStorage.setItem('isContest', data['contest'])
        localStorage.setItem('productType', data.productType)
        localStorage.setItem('isComment', data['comment'])
        if (data.marathonDays == null) {
          this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);
        } else {
          for (let index = 0; index < data.marathonDays.length; index++) {
            if (data.marathonDays[index].isActive == true) {
              var newid = data.marathonDays[index].id
              this.router.navigateByUrl('/user_marathon_day_exercise/' + data.marathonId + '/' + newid);
            }
            else {
              this.router.navigateByUrl('/marathon_exercise/' + data.marathonId);
            }
          }
        }
      }, error => {
        let status = this.authenticationService.errormessag(error);
        if (status.showError) {
          this.show = false;
          this.iserror = true;
          if (error.text() == "Marathon is over") {
            this.Error = "марафон завершен"
          } else {
            this.Error = status.errormessag;
          }
        }
      });
    } else {
      this.router.navigateByUrl('/payment');
    }
  }
  checkContestOrPhotoDiary() {
    if (this.getMarathons && this.getMarathons.productType === "Marathon" && this.ContestId && this.ContestId.isContestParticipated) {
      return true
    } else {
      return false
    }
  }
  checkContestShowAndHindden(){
    if(localStorage.getItem('productType') === 'Marathon'){
      if (localStorage.getItem('accessContest') == 'true') {
        return 'Contest';
      } else {
        return '';
      }
    }else{
      return 'Photo diary';
    }
  }
}