import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'marathonListSortingnew'
})
export class MarathonListSortingnewPipe implements PipeTransform {

  transform(tableData: Array<any>, args?: any): any {
    if (tableData != null) {
      return tableData.sort(function (a, b) {
        if (a[args.property] < b[args.property]) {
          return -1 * args.direction;
        }
        else if (a[args.property] > b[args.property]) {
          return 1 * args.direction;
        }
        else {
          return 0;
        }
      });
    }
  };

}
