import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-close-wimdow',
  templateUrl: './close-wimdow.component.html',
  styleUrls: ['./close-wimdow.component.scss']
})
export class CloseWimdowComponent implements OnInit {
  @Output() hidePopup = new EventEmitter();
  closeTabV: boolean;
  constructor(private router: Router) { }

  ngOnInit() {
    localStorage.clear()
    localStorage.removeItem('access_token')
  }
  closeTab() {
    this.router.navigateByUrl('/register/userlogin');
    this.closeTabV = true;
  }


}
