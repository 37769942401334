import { Injectable, EventEmitter } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Authorizeheader } from './_helper/authorizeheader';
import { environment } from '../environment';
// import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
    providedIn: 'root'
})
export class MarathonService {
    invokeFirstComponentFunction = new EventEmitter();
    subsVar: Subscription;
    public timeZone
    messagesdata: any;
    MarathoncommentData: any;
    userMarathonDetailId = '';
    userMarathonDetailData: any;
    constructor(private http: Http,
        private Authorizeheader: Authorizeheader) {
        this.timeZone = (new Date()).getTimezoneOffset()

    }

    UserRegister(data) {
        return this.http.post(environment.baseUrl + 'User/UserRegister', data, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    /**get marathon title in miyabi admin */
    getMarathonTitle(id) {
        return this.http.get(environment.baseUrl + 'Marathon/GetMarathon' + '?id=' + id + '&timeZoneOffSet=' + this.timeZone, { headers: this.Authorizeheader.createHeader() }).toPromise()

    }
    /**update marathon rule post again in miyabi marathon */
    updateMarathonRule(data) {
        return this.http.post(environment.baseUrl + 'Marathon/UpdateMarathonRules', data, { headers: this.Authorizeheader.createHeader() }).toPromise()

    }

    /** get marathon exercise detail*/
    getUserMarathonDetail(id) {
        debugger
        if (this.userMarathonDetailId == '' || this.userMarathonDetailId != id) {
            this.userMarathonDetailId = id;
            this.userMarathonDetailData = this.http.get(environment.baseUrl + 'UserMarathon/StartMarathon' + '?marathonId=' + id + '&timeZoneOffSet=' + this.timeZone, { headers: this.Authorizeheader.createHeader() }).toPromise()
            this.userMarathonDetailData.then(response => {
                if (response.json().productType == 'Marathon') {
                    localStorage.removeItem('accessComment');
                    localStorage.removeItem('accessContest');
                    this.getCurrentMarathonPlan(id).then(response => {
                        const dataPlan = response.json();
                        localStorage.setItem('accessComment', dataPlan['accessComment']);
                        localStorage.setItem('accessContest', dataPlan['accessContest']);
                        localStorage.setItem('geAceess', dataPlan['geAceess']);
                    });
                }
            })
            return this.userMarathonDetailData;
        } else {
            return this.userMarathonDetailData;
        }


    }
    // 
    GetUserTaggedWithYoungBody(marathonId){
        return this.getUserMarathonDetail(marathonId);
    };
    // get uar to base 64
    // getImageBaseString(url){
    //     return this.http.get(environment.baseUrl + 'GeneralSetting/GetImageBaseString?path=' +url , { headers: this.Authorizeheader.createHeader() }).toPromise();
    // }
    /**get day exercise of particular user in miyabi marathon */
    getUserDayExercise(dayId, marathonId) {
        return this.http.get(environment.baseUrl + 'UserMarathon/GetDayExercise' + '?marathonId=' + marathonId + '&dayId=' + dayId + '&timeZoneOffSet=' + this.timeZone, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    getCurrentMarathonPlan(marathonId) {
        return this.http.get(environment.baseUrl + 'UserMarathon/GetCurrentMarathonPlan?marathonId=' + marathonId, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    startGreatExtension(marathonId) {
        return this.http.get(environment.baseUrl + 'UserMarathon/StartGreatExtension?marathonId=' + marathonId, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    /**get marathon day using id in miyabi marathon */
    getMarathonDayById(id) {
        return this.http.get(environment.baseUrl + 'Marathon/GetExercise' + '?id=' + id + '&timeZoneOffSet=' + this.timeZone, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    /**get comments lists in miyabi marathon */
    getMarathoncomment(id, MarathonId, isExtension) {
        debugger
        if (this.MarathoncommentData == null) {
            this.MarathoncommentData = {
                id: '',
                marathonId: '',
                isExtension: '',
                data: ''
            }
        }
        if (this.MarathoncommentData.id == id && this.MarathoncommentData.marathonId == MarathonId && this.MarathoncommentData.isExtension == isExtension && this.MarathoncommentData.data != '') {
            return this.MarathoncommentData.data;
        } else {
            let data = this.http.get(environment.baseUrl + 'UserMarathon/GetComments' + '?exerciseId=' + id + '&marathonId=' + MarathonId + '&isExtension=' + isExtension + '&timeZoneOffSet=' + this.timeZone, { headers: this.Authorizeheader.createHeader() }).toPromise()
            this.MarathoncommentData = {
                id: id,
                marathonId: MarathonId,
                isExtension: isExtension,
                data: data
            }
            return this.MarathoncommentData.data;
        }

    }
    // get faq like
    getFaqLike(data) {
        return this.http.get(environment.baseUrl + 'Marathon/LikeFAQ?exerciseFAQId=' + data.exerciseFAQId + '&like=' + data.like, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    // get comment like

    getCommentLike(data) {
        return this.http.get(environment.baseUrl + 'Marathon/LikeComment?commentId=' + data.commentId + '&like=' + data.like, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    /**post comment of user in miyabi admin */
    commentMessage(data) {
        return this.http.post(environment.baseUrl + 'UserMarathon/CreateComment', data, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    /**post comment status update in miyabi admin */
    changeMessageStatus(data) {
        return this.http.post(environment.baseUrl + 'Marathon/UpdateCommentStatus', data, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    getSuggestion(comment) {
        return this.http.get('http://198.12.156.104:8983/solr/miyabi_core/select?q=Question:' + comment + '&rows=10').toPromise()
    }
    suggestionQA(comment) {
        return this.http.post('https://miyabi-search.search.windows.net/indexes/azuresql-index/docs/search?api-version=2016-09-01', { "count": true, "skip": 0, "top": 50, "searchMode": "any", "queryType": "simple", "search": comment }, { headers: this.Authorizeheader.createHeaderAzure() }).toPromise()
    }

    particpentStatus(data) {
        return this.http.get(environment.baseUrl + 'Contest/TakePartinContest?marathonId=' + data.marathonId + '&isContestParticipated=' + data.isContestParticipated, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    acceptContestRules(data) {
        return this.http.get(environment.baseUrl + 'Contest/AcceptContestRules?marathonId=' + data.marathonId + '&contestRulesAccepted=' + data.contestRulesAccepted, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    acceptCourseRules(data) {
        return this.http.get(environment.baseUrl + 'UserMarathon/AcceptCourseRules?courseId=' + data.courseId + '&status=' + data.status, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    // download user collage image
    DownloadUserImage(id) {
        return this.http.get(environment.baseUrl + 'Contest/DownloadcollageImageForUser?marathonId=' + id + '&timeZoneOffSet=' + this.timeZone, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    // get marathon description
    getDescription(id) {
        return this.http.get(environment.baseUrl + 'Contest/GetContestDescription?marathonId=' + id, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    /** get repliy of comment */
    getChildCmnt(event, isExtension) {

        return this.http.get(environment.baseUrl + 'UserMarathon/GetChildComments' + '?commentId=' + event.commentId + '&exerciseId=' + event.exerciseId + '&marathonId=' + event.marathonId + '&pageSize=' + event.pageSize + '&pageIndex=' + event.pageIndex + '&timeZoneOffSet=' + this.timeZone + '&isExtension=' + isExtension, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    /** get user detail from WP / get redirect user detail  */
    getUserByToken(data) {
        return this.http.post(environment.baseUrl + 'Token/wpauth', data, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    allFinalistList(data) {
        return this.http.get(environment.baseUrl + 'Contest/AllFinalistList' + '?marathonId=' + data, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    Vote(data) {
        return this.http.post(environment.baseUrl + 'Contest/Vote', data, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    /** get exercise status for notification */
    exerciseStatus(data) {
        return this.http.post(environment.baseUrl + 'UserMarathon/SetUserExerciseStatus', data, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    setUserCourseExerciseStatus(data) {
        return this.http.post(environment.baseUrl + 'UserMarathon/SetUserCourseExerciseStatus', data, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    setUserGXCourseExerciseStatus(data) {
        return this.http.post(environment.baseUrl + 'UserMarathon/SetUserGXCourseExerciseStatus', data, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    uploadUserMaskImages(data) {
        data.timeZoneOffSet = this.timeZone;
        return this.http.post(environment.baseUrl + 'Contest/UploadUserMaskImages', data, { headers: this.Authorizeheader.createHeaderForMarathon() }).toPromise()
    }
    confirmContestMaskImages(data) {
        data.timeZoneOffSet = this.timeZone;
        return this.http.post(environment.baseUrl + 'Contest/ConfirmContestMaskImages', data, { headers: this.Authorizeheader.createHeaderForMarathon() }).toPromise()
    }

    GetContest(id) {
        return this.http.get(environment.baseUrl + 'Contest/GetContest?marathonId=' + id, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    UploadContestImages(data) {
        return this.http.post(environment.baseUrl + 'Contest/UploadContestImages', data, { headers: this.Authorizeheader.createHeaderForMarathon() }).toPromise()
    }

    GetUserContestImages(contestId, marathonId) {
        return this.http.get(environment.baseUrl + 'Contest/GetUserContestImages?contestId=' + contestId + '&MarathonId=' + marathonId + '&timeZoneOffSet=' + this.timeZone, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    GetUserProfileDetail() {
        return this.http.get(environment.baseUrl + 'User/GetUserProfileDetail', { headers: this.Authorizeheader.createHeader() })
    }

    GetMarathons() {
        return this.http.get(environment.baseUrl + 'UserMarathon/GetMarathons' + '?timeZoneOffSet=' + this.timeZone).toPromise()
    }

    OrderAsGift(orderNumber, isGift) {
        return this.http.get(environment.baseUrl + 'order/OrderAsGift' + '?orderNumber=' + orderNumber + '&isGift=' + isGift, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    removeCard(cardRecordId) {
        return this.http.get(environment.baseUrl + 'Order/RemoveCard' + '?cardRecordId=' + cardRecordId, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    CheckDiscount(orderNumber, couponCode) {
        return this.http.get(environment.baseUrl + 'order/CheckDiscount' + '?orderNumber=' + orderNumber + '&couponCode=' + couponCode + '&timeZoneOffSet=' + this.timeZone, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    PurchaseMarathon(orderNumber, couponCode) {
        return this.http.get(environment.baseUrl + 'order/PurchaseMarathon' + '?orderNumber=' + orderNumber + '&couponCode=' + couponCode + '&timeZoneOffSet=' + this.timeZone, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    PaymentDetail(id) {
        return this.http.get(environment.baseUrl + 'Marathon/ExtensionDescription' + '?marathonid=' + id, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    GetSubscriptionDetails(id) {
        return this.http.get(environment.baseUrl + 'Order/GetSubscriptionDetails' + '?marathonId=' + id, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    unSubscribePaypalSubscription(id) {
        return this.http.get(environment.baseUrl + 'Order/CancelSubscription' + '?marathonid=' + id, { headers: this.Authorizeheader.createHeader() }).toPromise()
        // return this.http.get(environment.baseUrl + 'Course/CancelCourseSubscription' + '?marathonid=' + id, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    // unSubscribePaypalSubscription(id) {
    //     return this.http.get(environment.baseUrl + 'UserMarathon/UnSubscribePaypalSubscription' + '?marathonid=' + id, { headers: this.Authorizeheader.createHeader() }).toPromise()
    // }
    GetMarathonslist() {
        return this.http.get(environment.baseUrl + 'order/GetUserOrders' + '?timeZoneOffSet=' + this.timeZone, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    getNotification() {
        return this.http.get(environment.baseUrl + 'UserMarathon/GetNotification' + '?timeZoneOffSet=' + this.timeZone, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    GetCoupoun(orderNumber) {
        return this.http.get(environment.baseUrl + 'order/GetCoupoun' + '?orderNumber=' + orderNumber, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    UserResetpassword(data) {
        return this.http.post(environment.baseUrl + 'User/UserResetpassword?Email=' + data, null, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    CheckForMarathonPurchased(id) {
        if (id == null) {
            return this.http.get(environment.baseUrl + 'Order/CheckForMarathonPurchased' + '?timeZoneOffSet=' + this.timeZone + '&marathonId=', { headers: this.Authorizeheader.createHeader() }).toPromise()
        } else {
            return this.http.get(environment.baseUrl + 'Order/CheckForMarathonPurchased' + '?timeZoneOffSet=' + this.timeZone + '&marathonId=' + id, { headers: this.Authorizeheader.createHeader() }).toPromise()
        }
    }

    cloudPaymentWeb(orderId, result) {
        return this.http.get(environment.baseUrl + 'Order/CloudPaymentWeb' + '?orderId=' + orderId + '&result=' + result, { headers: this.Authorizeheader.createHeader() }).toPromise()

    }
    subscribeWithCloud(orderId) {
        return this.http.get(environment.baseUrl + 'Order/SubscribeWithCloud' + '?orderId=' + orderId, { headers: this.Authorizeheader.createHeader() }).toPromise()

    }
    addNewCard(orderId) {
        return this.http.get(environment.baseUrl + 'Order/AddNewCard' + '?orderId=' + orderId + '&timeZoneOffSet=' + this.timeZone, { headers: this.Authorizeheader.createHeader() }).toPromise()

    }
    getActiveOrder() {
        return this.http.get(environment.baseUrl + 'Order/GetActiveOrder' + '?timeZoneOffSet=' + this.timeZone + '&marathonId=', { headers: this.Authorizeheader.createHeader() }).toPromise()

    }

    CheckSelfMarathonPurchased(id) {
        return this.http.get(environment.baseUrl + 'Order/CheckSelfMarathonPurchased' + '?marathanId=' + id, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    getMarathonPlans(id) {
        return this.http.get(environment.baseUrl + 'Order/GetMarathonPlans' + '?marathonId=' + id, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    getSubscriptionCardDetails() {
        return this.http.get(environment.baseUrl + 'Order/GetSubscriptionCardDetails', { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    createOrderForTransaction(modeldata) {
        if (modeldata.planId) {
            return this.http.get(environment.baseUrl + 'Order/CreateOrder' + '?marathonId=' + modeldata.marathonId + '&wpOrderId=' + modeldata.wpOrderId + '&isFromWordpress=' + modeldata.isFromWordpress + '&planId=' + modeldata.planId, { headers: this.Authorizeheader.createHeader() }).toPromise()
        } else {
            return this.http.get(environment.baseUrl + 'Order/CreateOrder' + '?marathonId=' + modeldata.marathonId + '&wpOrderId=' + modeldata.wpOrderId + '&isFromWordpress=' + modeldata.isFromWordpress, { headers: this.Authorizeheader.createHeader() }).toPromise()
        }
    }

    createTransactionDetail(data) {
        return this.http.post(environment.baseUrl + 'Order/SaveTransactionDetail', data, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    subscribeToCourse(data) {
        return this.http.post(environment.baseUrl + 'Order/SubscribeToCourse', data, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    savedCardCloudSubscription(data) {
        return this.http.get(environment.baseUrl + 'Order/SavedCardCloudSubscription?orderId=' + data.orderId + '&TransactionId=' + data.transactionId, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    SaveAdvancedTransactionForRobokasa(data) {
        return this.http.get(environment.baseUrl + 'Order/SaveAdvancedTransactionForRobokasa' + '?orderId=' + data.orderId + '&signature=' + data.signature + '&paidVia=' + data.PaidVia + '&paymentGetway=' + data.PaymentGetway, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    messages(data) {
        this.messagesdata = data
    }
    messagesdatasend() {
        return this.messagesdata
    }
    getRobokassaOrderStatus(marchantId, orderId, signatur) {
        return this.http.get(environment.baseUrl + 'Order/GetRobokassaOrderStatus?marchantId=' + marchantId + '&orderId=' + orderId + '&signature=' + signatur + '&paidVia=web' + '&paymentGetway=robokassa', { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    inactiveOrder(id) {
        return this.http.get(environment.baseUrl + 'Order/RetryPayment?orderNumber=' + id, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    GetGeneralSetting() {
        return this.http.get(environment.baseUrl + 'GeneralSetting/GetGeneralSetting', { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    GetUserNotificationSetting() {
        return this.http.get(environment.baseUrl + 'User/GetUserNotificationSetting', { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

    updateUserNotificationSettings(data) {
        return this.http.post(environment.baseUrl + 'User/UpdateUserNotificationSettings', data, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }
    stripePayment(data) {
        data['timeZone'] = this.timeZone;
        return this.http.post(environment.baseUrl + '/Order/OrderPayment', data, { headers: this.Authorizeheader.createHeader() }).toPromise()
    }

}